import React, {useState} from 'react'
import {Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,} from 'reactstrap';
import classnames from 'classnames';
import ClientManager from './ClientManager/ClientManager';
import ProjectManager from './ProjectManager/ProjectManager';
import Setting from './Setting';
import AssignProject from './AssignProject';
import {Route, Switch, useHistory} from 'react-router';

export default function ClientsTab(props) {
  const [activeTab, setActiveTab] = useState('1');
  const history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) {
	    history.push(`/homepage/newclient/${props.client._id}`)
      setActiveTab(tab)
    }
  };

  return (
    <div>
      <Col>
        <Card>
          <CardBody className="p-0">
            <div className="tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} >
                      PROJECT MANAGERS
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} >
                      CLIENT MANAGERS
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} >
                      SETTINGS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {activeTab === '1' && <Switch>
	                    <Route exact path="/homepage/newclient/:id" component={ProjectManager}/>
	                    <Route path="/homepage/newclient/:id/project-manage" exact component={AssignProject}/>
                    </Switch>}
                  </TabPane>
                  <TabPane tabId="2">
                    {activeTab === '2' && <ClientManager userId={props.client._id} />}
                  </TabPane>
                  <TabPane tabId="3">
                    <Setting client={props.client} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
