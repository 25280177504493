import {createActions, createReducer} from "reduxsauce";
import {getAssumptions, getEnumeration, updateAssumptions} from "../../api/admin";

const getEnums = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingEnums());
        getEnumeration(data).then(res => {
            dispatch(Creators.getEnumsSuccess(res));
        }).catch((error) => {
            dispatch(Creators.getEnumsError(error));
        });
    };
};

const getAssumption = () => {
    return (dispatch) => {
        dispatch(Creators.getLoadingAssumption());
        getAssumptions().then(res => {
            dispatch(Creators.getAssumptionSuccess(res));
        }).catch((error) => {
            dispatch(Creators.getAssumptionError(error));
        });
    };
};

const updateAssumption = (id, data) => {
    return (dispatch) => {
        dispatch(Creators.updateLoadingAssumption());
        updateAssumptions(id, data).then(res => {
            dispatch(Creators.updateAssumptionSuccess(id, res));
        }).catch((error) => {
            dispatch(Creators.updateAssumptionError(error));
        });
    };
};

export const { Types, Creators } = createActions({
    getEnums,
    getEnumsSuccess: ['enums'],
    getEnumsError: ['error'],
    updateAssumption,
    updateAssumptionSuccess: ['assumption'],
    updateAssumptionError: ['error'],
    getAssumption,
    getAssumptionSuccess: ['assumption'],
    getAssumptionError: ['error'],
    getLoadingAssumption: [],
    updateLoadingAssumption: [],
    isLoadingEnums: [],
});

const initialState = {
    enums: null,
    assumptions: [],
    isError: false,
    isLoading: false,
    isLoadingAssumption: false,
    getAssumptionLoading: false,
    updateAssumptionLoading: false,
    assumptionError: false,
};

const getEnumsSuccess = (state = initialState, action) => {
    return {
        ...state,
        enums: action.enums,
        isLoading: false,
        isLoadingAssumption: false,
        isError: '',
    };
};

const getEnumsError = (state = initialState, action) => {
	console.log(action);
    return {
        ...state,
        enums: null,
        isLoading: false,
        getAssumptionLoading: false,
        isError: action.error,
    };
};

const getAssumptionSuccess = (state = initialState, action) => {
    return {
        ...state,
        assumptions: action.assumption,
        getAssumptionLoading: false,
        assumptionError: '',
    };
};

const getAssumptionError = (state = initialState, action) => {
    return {
        ...state,
        getAssumptionLoading: false,
        assumptionError: action.error,
    };
};

const updateAssumptionSuccess = (state = initialState, action) => {
    const client = [...state.client];
    const updatedClient = action.data;
    const index = state.client.findIndex(c => c._id === action.id)
    client[index] = updatedClient;
    return {
        ...state,
        client: client,
        updateAssumptionLoading: false,
        assumptionError: '',
    };
};

const updateAssumptionError = (state = initialState, action) => {
    return {
        ...state,
        updateAssumptionLoading: false,
        assumptionError: action.error,
    };
};

const isLoadingEnums = (state = initialState, action) => {
    return {
        ...state,
        isLoading: true,
        isError: '',
    };
};

const getLoadingAssumption = (state = initialState, action) => {
    return {
        ...state,
        getAssumptionLoading: true,
        updateAssumptionLoading: false,
        assumptionError: '',
    };
};

const updateLoadingAssumption = (state = initialState, action) => {
    return {
        ...state,
        getAssumptionLoading: false,
        updateAssumptionLoading: true,
        assumptionError: '',
    };
};

export default createReducer(initialState, {
    [Types.GET_ENUMS]: getEnums,
    [Types.GET_ENUMS_SUCCESS]: getEnumsSuccess,
    [Types.GET_ENUMS_ERROR]: getEnumsError,
    [Types.IS_LOADING_ENUMS]: isLoadingEnums,
    [Types.GET_ASSUMPTION]: getAssumption,
    [Types.GET_ASSUMPTION_SUCCESS]: getAssumptionSuccess,
    [Types.GET_ASSUMPTION_ERROR]: getAssumptionError,
    [Types.UPDATE_ASSUMPTION]: updateAssumption,
    [Types.UPDATE_ASSUMPTION_SUCCESS]: updateAssumptionSuccess,
    [Types.UPDATE_ASSUMPTION_ERROR]: updateAssumptionError,
    [Types.GET_LOADING_ASSUMPTION]: getLoadingAssumption,
    [Types.UPDATE_LOADING_ASSUMPTION]: updateLoadingAssumption,
});
