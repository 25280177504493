import React, {useEffect, useState} from "react";
import {
	Badge,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	UncontrolledDropdown,
} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import GreaterThanIcon from "mdi-react/GreaterThanIcon";
import LessThanIcon from "mdi-react/LessThanIcon";
import AddCircleOutlineIcon from "mdi-react/AddCircleIcon";
import AddVariable from "./AddVariable";
import CloseIcon from "mdi-react/CloseCircleIcon";
import {ClipLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {Creators as validationDispatcher} from "../../../store/ducks/validations";

const randomBadgeColor = (i) =>
	[
		"validation-badge-success",
		"validation-badge-primary",
		"validation-badge-danger",
		"validation-badge-warning",
	][i % 4];

export default function Validations() {
	const {
		validations,
		isLoading: loading,
		isAddLoading,
		isDeleteLoading,
		isUpdateLoading,
	} = useSelector((state) => state.validations);
	const [validationData, setValidationData] = useState([]);
	const [categoryInput, setCategoryInput] = useState([]);
	const [warningsToDelete, setWarningsToDelete] = useState({});
	const [modalToggler, setModalToggler] = useState(false);
	const [isSelectedIdForDelete, setIsSelectedIdForDelete] = useState(null);
	const [isSelectedIdForUpdate, setIsSelectedIdForUpdate] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(validationDispatcher.getValidationsData());
	}, []);

	useEffect(() => {
		setValidationData(validations);
	}, [validations]);

	const toggleWarningChangeHandler = (index) => {
		const validData = Object.assign([], validationData);
		let warning = validData[index].warning;
		if (warning) {
			if (warning._id)
				setWarningsToDelete(p => ({...p, [validData[index]._id]: warning}));
			warning = null;
		} else {
			warning = warningsToDelete[validData[index]._id] || {
				...validationData[index],
				_id: null,
				severity: 'warning',
				validValues: {
					lessThan: {'<=': [{'var': validationData[index].inputName}, 1]},
					greaterThan: {'>=': [{'var': validationData[index].inputName}, 0]}
				}
			}
		}
		validData[index] = {...validationData[index], severity: 'error', warning};
		setValidationData(validData);
	};

	const handleChangeType = ({currentTarget: {textContent}}, index) => {
		const validData = Object.assign([], validationData);
		const exist = validations.find(p => p.type === textContent && p._id === validData[index]._id);
		if (exist) {
			validData[index] = exist;
			setWarningsToDelete(p => ({...p, [validData[index]._id]: null}));
			setValidationData(validData);
			return;
		}
		if (textContent !== validData[index].type) {
			const validValues =
				textContent === "Category" ? [] : textContent === "Numeric"
					? {
						lessThan: {'<=': [{'var': validationData[index].inputName}, 1]},
						greaterThan: {'>=': [{'var': validationData[index].inputName}, 0]}
					} : {};
			if (validData[index].warning && validData[index].warning._id) {
				setWarningsToDelete(p => ({...p, [validData[index]._id]: validData[index].warning}));
			}
			validData[index] = {
				...validationData[index],
				warning: textContent === "Category" ? {
					...validationData[index],
					_id: null,
					validValues
				} : null,
				type: textContent,
				validValues
			};
			setValidationData(validData);
		}
	};

	const handleCategoryInputChange = ({target: {value}}, index) => {
		categoryInput[index] = value;
		setCategoryInput([...categoryInput]);
	};
	const handleNumericInputChange = ({target: {name, value}}, warning, index) => {
		const validData = Object.assign([], validationData);
		let validValues = {...validationData[index].validValues};
		if (warning)
			validValues = validationData[index].warning.validValues;
		if (name === '<=')
			validValues.lessThan = {['<=']: [{'var': validationData[index].inputName}, +value]};
		else
			validValues.greaterThan = {'>=': [{'var': validationData[index].inputName}, +value]};
		if (warning)
			validData[index] = {...validData[index], warning: {...validData[index].warning, validValues}};
		else
			validData[index] = {...validData[index], validValues};
		setValidationData(validData);
	};
	const handleCategoryAddValue = (index) => {
		if (categoryInput[index]) {
			const validData = Object.assign([], validationData);
			let warning = validationData[index].warning;
			if (!warning) warning = {...validationData[index], _id: null};
			validData[index] = {
				...validationData[index],
				warning: {
					...warning, validValues: [
						...(validationData[index].warning?.validValues || []),
						categoryInput[index],
					],
				},
			};
			setValidationData(validData);
			categoryInput[index] = '';
			setCategoryInput(categoryInput);
		}
	};

	const handleRemoveOS = (id, OSIndex) => {
		const validData = Object.assign([], validationData);
		const index = validationData.findIndex((p) => p._id === id);
		validData[index] = {
			...validData[index],
			warning: {
				...validData[index].warning,
				validValues: [...validData[index].warning.validValues]
			},
		};
		validData[index].warning.validValues.splice(OSIndex, 1);
		setValidationData(validData);
	};

	const validationDataRemoveHandler = (id) => {
		console.log(id);
		setIsSelectedIdForDelete(id);
		dispatch(validationDispatcher.deleteValidationsData(id));
	};

	const updateValidation = (data) => {
		if (warningsToDelete[data._id]) {
			dispatch(validationDispatcher.deleteValidationsData(warningsToDelete[data._id]._id));
			setWarningsToDelete(p => ({...p, [data._id]: null}));
		}
		dispatch(validationDispatcher.updateValidationsData(data));
		setIsSelectedIdForUpdate(data._id);
	};

	return (
		<Col md={12} className="">
			<AddVariable
				loading={isAddLoading}
				title="Add Variable"
				isOpen={modalToggler}
				toggle={() => setModalToggler((p) => !p)}
			/>
			<Card>
				<CardBody>
					{loading ? (
						<div className="position-relative d-flex justify-content-center align-items-center">
							<ClipLoader size={30} color={"#4ce1b6"}/>
						</div>
					) : (
						validationData?.map((p, i) => (
							<div key={p._id}>
								<div className="d-flex justify-content-end position-relative">
									<Button
										disabled={
											JSON.stringify(p) === JSON.stringify(validations.find((f) => f._id === p._id)) || (isUpdateLoading && isSelectedIdForUpdate === p._id)
										}
										color="primary"
										className="btn btn-primary p-2 mb-0 validation-update-btn "
										onClick={() => updateValidation(p)}>
										{isUpdateLoading && isSelectedIdForUpdate === p._id ? (
											<ClipLoader size={20}/>
										) : (
											"Update"
										)}
									</Button>
									<Button
										disabled={isDeleteLoading && isSelectedIdForDelete === p._id}
										color="primary"
										className="btn btn-primary p-2 mb-0 validation-remove-btn "
										onClick={() => validationDataRemoveHandler(p._id)}>
										{isDeleteLoading && isSelectedIdForDelete === p._id ? (<ClipLoader size={20}/>) : "Remove"}
									</Button>
								</div>

								<div className="row mt-3 validation-row">
									<div className="col-12 col-md-2 d-flex align-items-center">
										<h5 className=" validation-labels">Input Name</h5>
									</div>
									<div className="col-12 col-md-10">
										<h5 className="validation-input-label">{p.inputName}</h5>
									</div>
								</div>

								<div className="row mt-3">
									<div className="col-12 col-md-2 d-flex align-items-center">
										<h5 className=" validation-labels">Input Type</h5>
									</div>
									<div className="col-12 col-sm-6 col-md-4 col-lg-3">
										<UncontrolledDropdown>
											<ButtonGroup dir="ltr" className="w-100">
												<DropdownToggle outline className="w-100 text-left rounded-0 validation-dropdown-style">
													{p.type}
												</DropdownToggle>
												<DropdownToggle outline className="validation-icon icon--right rounded-0">
													<ChevronDownIcon/>
												</DropdownToggle>
											</ButtonGroup>
											<DropdownMenu right className="dropdown__menu">
												<DropdownItem onClick={(e) => handleChangeType(e, i)}>
													Numeric
												</DropdownItem>
												<DropdownItem onClick={(e) => handleChangeType(e, i)}>
													Category
												</DropdownItem>
												<DropdownItem onClick={(e) => handleChangeType(e, i)}>
													FreeText
												</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
								</div>

								{/*<div className="row mt-3">*/}
								{/*	<div className="col-12 col-md-2 d-flex align-items-center">*/}
								{/*		<h5 className=" validation-labels">Error Text</h5>*/}
								{/*	</div>*/}
								{/*	<div className="col-10 col-sm-6 col-md-4 col-lg-3">*/}
								{/*		<div className="form__form-group mb-0">*/}
								{/*			<div className="form__form-group-field client-project-manager-search-input">*/}
								{/*				<Input*/}
								{/*					type="textarea"*/}
								{/*					name="error"*/}
								{/*					value={p.errorText}*/}
								{/*					onChange={(e) => handleChangeValidValues(e, i, "validValues", "no-change")}/>*/}
								{/*			</div>*/}
								{/*		</div>*/}
								{/*	</div>*/}
								{/*</div>*/}
								{p.type === "Numeric" && (
									<>
										<div className="row mt-3">
											<div className="col-12 col-md-2 d-flex align-items-center">
												<h5 className=" validation-labels">Valid Values</h5>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-sm-0">
												<div className="form__form-group mb-0">
													<div className="form__form-group-field client-project-manager-search-input">
														<Input
															type="number"
															name=">="
															value={p.validValues?.greaterThan[">="][1]}
															onChange={(e) => handleNumericInputChange(e, false, i)}/>
														<div className="form__form-group-icon clients-validation-icon h-auto">
															<GreaterThanIcon/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-6 col-md-4 col-lg-3">
												<div className="form__form-group mb-0">
													<div className="form__form-group-field client-project-manager-search-input">
														<Input
															type="number"
															name="<="
															value={p.validValues?.lessThan["<="][1]}
															onChange={(e) => handleNumericInputChange(e, false, i)}/>
														<div className="form__form-group-icon clients-validation-icon h-auto">
															<LessThanIcon/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{p.type === "Category" && (
									<div className="row mt-3">
										<div className="col-12 col-md-2 d-flex align-items-center">
											<h5 className=" validation-labels">Valid Values</h5>
										</div>
										<div className="col-10 col-sm-6 col-md-4 col-lg-3">
											<div className="form__form-group mb-0">
												<div className="form__form-group-field client-project-manager-search-input">
													<Input
														type="text"
														name="value"
														value={categoryInput[i]}
														onChange={(e) => handleCategoryInputChange(e, i)}/>
												</div>
											</div>
										</div>

										<div className="col-2 col-sm-6 col-md-4 col-lg-3 pl-0">
											<AddCircleOutlineIcon onClick={() => handleCategoryAddValue(i)}
											                      className="add-circle-icon pointer"/>
										</div>
										<div className="offset-12 offset-md-2 col-12 col-md-10 mt-3 w-auto ">
											{p.warning?.validValues?.map((x, badgeIndex) => (
												<Badge key={x + badgeIndex} className={`my-1 ${randomBadgeColor(badgeIndex)}`}>
													{x}
													<CloseIcon onClick={() => handleRemoveOS(p._id, badgeIndex)} className="ml-2"/>
												</Badge>
											))}
										</div>
									</div>
								)}

								{p.type === "Numeric" && (
									<div className="row mt-3">
										<div className="col-12 col-md-2 d-flex align-items-center">
											<h5 className=" validation-labels">Warning Values</h5>
										</div>
										<div className="col-12 col-md-6">
											<label className="toggle-btn customizer__toggle" htmlFor={`collapse_toggle${i}`}>
												<input
													className="toggle-btn__input"
													type="checkbox"
													name={`collapse_toggle${i}`}
													id={`collapse_toggle${i}`}
													checked={!!p.warning}
													onChange={() => toggleWarningChangeHandler(i)}/>
												<span className="toggle-btn__input-label m-0"/>
											</label>
										</div>
										{p.warning && (
											<div className="offset-12  col-12 mt-3 w-auto">
												<div className="row">
													<div className="offset-md-2 col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-sm-0">
														<div className="form__form-group mb-0">
															<div className="form__form-group-field client-project-manager-search-input">
																<Input
																	type="text"
																	name=">="
																	value={p.warning.validValues?.greaterThan[">="][1]}
																	onChange={(e) => handleNumericInputChange(e, true, i)}/>
																<div className="form__form-group-icon clients-validation-icon h-auto">
																	<GreaterThanIcon/>
																</div>
															</div>
														</div>
													</div>
													<div className="col-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form__form-group mb-0">
															<div className="form__form-group-field client-project-manager-search-input">
																<Input
																	type="text"
																	name="<="
																	value={p.warning.validValues?.lessThan["<="][1]}
																	onChange={(e) => handleNumericInputChange(e, true, i)}/>
																<div className="form__form-group-icon clients-validation-icon h-auto">
																	<LessThanIcon/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}


										{/*{p.warningValue?.enable && p.severity === "warning" && (*/}
										{/*	<div className="col-12">*/}
										{/*		<div className="row mt-3">*/}
										{/*			<div className="col-12 col-md-2 d-flex align-items-center">*/}
										{/*				<h5 className=" validation-labels">warning</h5>*/}
										{/*			</div>*/}
										{/*			<div className="col-10 col-sm-6 col-md-4 col-lg-3">*/}
										{/*				<div className="form__form-group mb-0">*/}
										{/*					<div className="form__form-group-field client-project-manager-search-input">*/}
										{/*						<Input*/}
										{/*							type="text"*/}
										{/*							name="value"*/}
										{/*							value={p.warningValue?.warning}*/}
										{/*							onChange={(e) => handleChangeValidValues(e, i, "validValues", "no-change")}/>*/}
										{/*					</div>*/}
										{/*				</div>*/}
										{/*			</div>*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*)}*/}
									</div>
								)}
								<hr/>
							</div>
						))
					)}
					{!loading && (
						<div className="offset-12 offset-md-2 col-12 w-auto">
							<Button
								color="primary"
								className="btn btn-primary p-2 mb-0 "
								onClick={() => setModalToggler((p) => !p)}>
								Add Variable
							</Button>
						</div>
					)}
				</CardBody>
			</Card>
		</Col>
	);
}
