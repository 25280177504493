import React, { useState } from 'react'
import StorageSuggestionsCard from "./StorageSuggestionsCard";
export default function StorageSuggestions({ suggestion, ...props }) {
    const shared = props.shared
    const unavailable = suggestion.filter(p => p.productFamily === "Unavailable");
    const storageSuggestions = suggestion.filter(p => p.productFamily === "Storage")
    const [selectedStorage, setSelectedStorage] = useState(storageSuggestions?.find(p => p.selected) || storageSuggestions[0])
    const snapshotSuggestions = suggestion.filter(p => p.productFamily === "Storage Snapshot")
    const [selectedSnapshot, setSelectedSnapshot] = useState(snapshotSuggestions?.find(p => p.selected) || snapshotSuggestions[0])
    const lastIndexStorage = storageSuggestions?.length - 1;
    const highestCost = storageSuggestions[lastIndexStorage]?.costs[storageSuggestions[lastIndexStorage]?.costs.length - 1]?.totalCost1Year;
    const lastIndexSnapshot = snapshotSuggestions?.length - 1;
    const highestCostSnapshot = snapshotSuggestions[lastIndexSnapshot]?.costs[snapshotSuggestions[lastIndexSnapshot]?.costs.length - 1]?.totalCost1Year;

    const storageSelectHandler = (e) => {
        e.selected = true;
        selectedStorage.selected = undefined;
        props.onSuggestionChange(e);
        setSelectedStorage(e)
    };
    const snapshotSelectHandler = (e) => {
        e.selected = true;
        setSelectedSnapshot.selected = undefined;
        props.onSuggestionChange(e);
        setSelectedStorage(e)
    };

    return (
        <>
            {
                unavailable?.map(p => (
                    <StorageSuggestionsCard shared={shared} key={p._id} server={p} />
                ))
            }
            {
                storageSuggestions && storageSuggestions.map(p => (
                    <StorageSuggestionsCard shared={shared} key={p._id} highestCost={highestCost} server={p} serverUpdateHandler={() => props.onSuggestionChange(p)} serverSelectHandler={storageSelectHandler} selectServer={selectedStorage} />
                ))
            }
            {
                snapshotSuggestions && snapshotSuggestions.map(p => (
                    <StorageSuggestionsCard shared={shared} key={p._id} highestCost={highestCostSnapshot} server={p} serverUpdateHandler={() => props.onSuggestionChange(p)} serverSelectHandler={snapshotSelectHandler} selectServer={selectedSnapshot} />
                ))
            }
        </>
    )
}
