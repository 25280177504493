import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from 'reactstrap';
import {ClipLoader} from "react-spinners";
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {Creators as enumsDuck} from "../../../../../store/ducks/enums";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PercentIcon from 'mdi-react/PercentOutlineIcon';
import AbsoluteIcon from 'mdi-react/PinIcon';
import PeakIcon from 'mdi-react/PeakIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import Range from '../../../../../shared/components/range_slider/Range';
import Info from 'mdi-react/InfoCircleOutlineIcon'

const options = [{value: 'peakCPU', label: 'Peak CPU'}, {value: 'peakMemory', label: 'Peak Memory'},];
const searchCriteriaOptions = [{value: 'percentage', label: 'Percentage', icon: PercentIcon}, {
	value: 'absolute',
	label: 'Absolute',
	icon: AbsoluteIcon
}, {value: 'peak', label: 'Peak', icon: PeakIcon}];
const operaterOptions = [
	{value: '$in', label: 'in'},
	{value: '$nin', label: 'not in'},
	{value: '$gte', label: '>='},
	{value: '$lte', label: '<='},
	{value: '$gt', label: '>'},
	{value: '$lt', label: '<'},
];

const CustomIcon = ({Icon, ...props}) => <Icon/>;

export default function Assumptions({handleChange: handleChangeData, updatedAssumption, isServerDetail, ...props}) {
	const [assumptionsData, setAssumptionsData] = useState(props.assumptionsData);
	const {enums, isLoading: enumsLoading} = useSelector(state => state.enums);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!enums) {
			dispatch(enumsDuck.getEnums({names: []}));
		}
	}, []);

	useEffect(() => {
		setAssumptionsData(props.assumptionsData);
	}, [props.assumptionsData]);

	const handleRemoveRecommendation = (id, optionId) => {
		const allData = [...assumptionsData];
		const index = allData.findIndex(i => i._id === id);
		const findAssumption = allData[index];
		findAssumption.recommendationCriteria.splice(optionId, 1);
		handleChangeData(allData);
	}

	const handleChange = (selectedValue, event, id, optionId, type = false) => {
		const allData = [...assumptionsData];
		const index = allData.findIndex(i => i._id === id);
		const findAssumption = allData[index];
		if (event === "addRecommendation") {
			findAssumption.recommendationCriteria.push({
				criteria: "$gte",
				enumerations: "volumeApiName",
				inputName: "peakDiskIO",
				recommended: "sc1",
				value: [0, 0]
			})
		} else if (type === "apply") {
			findAssumption.rightSizing = {
				...findAssumption.rightSizing,
				[event.name]: event.name === "applyRightsizing" ? selectedValue.checked : selectedValue.value,
			}
		} else if (type === "applyOptional") {
			if (optionId >= 0) {
				findAssumption.pricingAttributes[optionId].value = selectedValue?.value || (selectedValue?.map(v => v.value) || [])
			} else {
				findAssumption[event.name] = selectedValue.checked;
			}
		} else if (type === "recommendation") {
			if (optionId >= 0) {
				if (event.name === "value")
					findAssumption.recommendationCriteria[optionId][event.name] = selectedValue;
				else
					findAssumption.recommendationCriteria[optionId][event.name] = selectedValue?.value;
			}
		} else {
			const optionIndex = findAssumption.searchThreshold.findIndex(i => i._id === optionId);
			findAssumption.searchThreshold[optionIndex] = {
				...findAssumption.searchThreshold[optionIndex],
				[event.name]: selectedValue?.value || (type ? (selectedValue?.map(v => v.value) || []) : '')
			}
		}
		handleChangeData(allData);
		if (updatedAssumption)
			updatedAssumption(findAssumption);
	};

	return (enumsLoading ? <div className="d-flex justify-content-center align-items-center mt-5">
				<ClipLoader size={50} color={"#4ce1b6"} loading={true}/>
			</div> :
			<div className="ml-2">
				{assumptionsData?.filter(p => p.attributeType === "clientData").map(p => {
					return (
						<>
							<div className="row mt-3 validation-row">
								<div className="col-12  d-flex align-items-center">
									<h5 className=" assumption-labels">{p.inputName}</h5>
								</div>
							</div>
							{p.inputType === 'numeric' &&
							<div className="offset-12 offset-md-2 col-12 w-auto">
								<div className="row">
									<div className="col-12">
										<h5 className="assumption-title my-2">
											Search criteria
											<Info style={{height: "20px"}} className="ml-2" color="#70bbfd" id="TooltipExample"/>
										</h5>
									</div>
								</div>
								{p.searchThreshold.map(s => {
									return (
										<div className="row mt-3">
											<div className="col-12 col-sm-6 col-md-6 col-lg-4">
												<UncontrolledDropdown>
													<ButtonGroup dir="ltr" className="w-100">
														<Button outline
														        className="w-100 text-left rounded-0 validation-dropdown-style">{operaterOptions.find(op => op.value === s.criteria).label}</Button>
														<DropdownToggle outline className="validation-icon icon--right rounded-0">
															<ChevronDownIcon/>
														</DropdownToggle>
													</ButtonGroup>
													<DropdownMenu right className="dropdown__menu">
														{operaterOptions.map(o => (
															<DropdownItem name="criteria"
															              onClick={(e) => handleChange(o, {name: e.target.name}, p._id, s._id)}>{o.label}</DropdownItem>
														))}
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
											<div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 mb-sm-0">
												<UncontrolledDropdown>
													<ButtonGroup dir="ltr" className="w-100">
														<Input type="text" name="value"
														       className="w-100 text-left rounded-0 validation-dropdown-style" value={s.value}
														       onChange={(e) => handleChange(e.target, e.target, p._id, s._id)}/>
														<DropdownToggle outline className="validation-icon icon--right rounded-0" htmlFor="type">
															<CustomIcon Icon={searchCriteriaOptions.find(o => o.value === s.type).icon}/>
														</DropdownToggle>
													</ButtonGroup>
													<DropdownMenu right className="dropdown__menu">
														{searchCriteriaOptions.map(c => (
															<DropdownItem name="type"
															              onClick={(e) => handleChange(c, {name: e.target.name}, p._id, s._id)}>{c.label}</DropdownItem>
														))}
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
										</div>
									)
								})}

								<div className="row">
									<div className="col-12">
										<h5 className="assumption-title my-2">
											Right sizing
											<Info style={{height: "20px"}} className="ml-2" color="#70bbfd" id="TooltipExample"/>
										</h5>
									</div>
									<div className="col-12 col-md-6 ">
										<label className="toggle-btn customizer__toggle">
											<input
												className="toggle-btn__input"
												type="checkbox" name="applyRightsizing"
												// id="collapse_toggle"
												checked={p.rightSizing.applyRightsizing}
												onChange={(e) => handleChange(e.target, e.target, p._id, p.rightSizing?._id, "apply")}
											/>
											<span className="assumption-apply mr-2">Apply rightsizing</span>
											<span className="toggle-btn__input-label m-0"/>
										</label>
									</div>
								</div>
								{p.rightSizing.applyRightsizing &&
								<div className="row mt-3">
									<div className="col-12 col-sm-6 col-md-6 col-lg-4">
										<UncontrolledDropdown>
											<ButtonGroup dir="ltr" className="w-100">
												<Button outline
												        className="w-100 text-left rounded-0 validation-dropdown-style">{options.find(op => op.value === p.rightSizing.column)?.label}</Button>
												<DropdownToggle outline className="validation-icon icon--right rounded-0">
													<ChevronDownIcon/>
												</DropdownToggle>
											</ButtonGroup>
											<DropdownMenu right className="dropdown__menu">
												{options.map(o => (
													<DropdownItem name="column"
													              onClick={(e) => handleChange(o, {name: e.target.name}, p._id, p.rightSizing._id, "apply")}>{o.label}</DropdownItem>
												))}
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
									<div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 mb-sm-0">
										<UncontrolledDropdown>
											<ButtonGroup dir="ltr" className="w-100">
												<Input type="text" className="w-100 text-left rounded-0 validation-dropdown-style" name="value"
												       placeholder="value" value={p.rightSizing.value}
												       onChange={(e) => handleChange(e.target, e.target, p._id, p.rightSizing._id, "apply")}/>
												<DropdownToggle outline className="validation-icon icon--right rounded-0" htmlFor="type">
													<CustomIcon Icon={searchCriteriaOptions.find(o => o.value === p.rightSizing.type).icon}/>
												</DropdownToggle>
											</ButtonGroup>
											<DropdownMenu right className="dropdown__menu">
												{searchCriteriaOptions.map(c => (
													<DropdownItem name="type"
													              onClick={(e) => handleChange(c, {name: e.target.name}, p._id, p.rightSizing._id, "apply")}>{c.label}</DropdownItem>
												))}
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
								</div>
								}
							</div>
							}
							{p.inputType === 'category' &&
							<div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
								<div className="row mt-3">
									<div className="col-12 mb-4">
										<h5 className="assumption-title my-2">
											Search criteria
											<Info style={{height: "20px"}} className="ml-2" color="#70bbfd" id="TooltipExample"/>
										</h5>
									</div>
								</div>
								{p.searchThreshold.map(s => {
									const searchOptions = p.enumerations.map(e => ({value: e, label: e}));
									const enumOptions = enums.find(e => e.name === p.inputName);
									const valueOption = enumOptions.values.map(e => ({value: e, label: e}));
									return (
										<div className="row mb-3">
											<div className="col-12 col-sm-5 col-md-5 col-lg-4">
												<UncontrolledDropdown>
													<ButtonGroup dir="ltr" className="w-100">
														<Button outline
														        className="w-100 text-left rounded-0 validation-dropdown-style">{searchOptions.find(o => o.value === s.inputValue).label}</Button>
														<DropdownToggle outline className="validation-icon icon--right rounded-0">
															<ChevronDownIcon/>
														</DropdownToggle>
													</ButtonGroup>
													<DropdownMenu right className="dropdown__menu">
														{searchOptions.map(c => (
															<DropdownItem name="inputValue"
															              onClick={(e) => handleChange(c, {name: e.target.name}, p._id, s._id)}>{c.label}</DropdownItem>
														))}
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
											<div
												className="col-12 col-sm-2 col-md-2 col-lg-1 d-flex justify-content-center align-items-center ">
												<h5 className="assumption-os-title my-2">maps to</h5>
											</div>
											<div className="col-12 col-sm-5 col-md-5 col-lg-4">
												<Select
													isMulti
													name="value"
													value={valueOption.filter(v => s.value.includes(v.value))}
													placeholder="value"
													onChange={(value, e) => handleChange(value, e, p._id, s._id, "isMulti")}
													options={valueOption}
													clearable={false}
													closeOnSelect={false}
													removeSelected={false}
													className="react-select "
													classNamePrefix="react-select"
												/>
											</div>
										</div>
									)
								})}
							</div>
							}
							<hr/>
						</>
					)
				})}

				{
					assumptionsData?.filter(p => p.attributeType === "optional").map(p => {
						return (
							<>
								<div className="row mt-3 validation-row">
									<div className="col-12  d-flex align-items-center">
										<h5 className=" assumption-labels">{p.name}</h5>
									</div>
								</div>
								<div className="offset-12 offset-md-2 col-12  w-auto">
									<div className="row">
										<div className="col-12 col-md-6 ">
											<label className="toggle-btn customizer__toggle">
												<input
													className="toggle-btn__input"
													type="checkbox" name={'enabled'} checked={p.enabled}
													onChange={(e) => handleChange(e.target, e.target, p._id, undefined, "applyOptional")}
												/>
												<span className="assumption-apply mr-2">	{p.label}</span>
												<span className="toggle-btn__input-label m-0"/>
											</label>
										</div>
									</div>
									<div className="row mt-3">
										{p.enabled &&
										<>
											<div className="col-12 mb-3 col-sm-12 col-md-8 col-lg-8 col-xl-8 ">
												<div className="d-flex flex-wrap">
													{p.pricingAttributes.map((pricingAttr, index) => {
														const pricingOptions = pricingAttr.enumerations.map((ops) => ({
															value: ops,
															label: ops
														}));
														return (
															<div className="flex-grow-1 height-40 m-1 min-width-100">
																<Select
																	isMulti={p.name === "snapshots"}
																	name="optional"
																	value={
																		p.name === "snapshots" ?
																			pricingOptions.filter(o => pricingAttr.value.includes(o.value))
																			: pricingOptions.find(o => o.value === pricingAttr.value)
																	}
																	placeholder="Select ..."
																	onChange={(value, e) => handleChange(value, e, p._id, index, "applyOptional")}
																	options={pricingOptions}
																	clearable={false}
																	closeOnSelect={false}
																	removeSelected={false}
																	className="react-select "
																	classNamePrefix="react-select"
																/>
															</div>
														)
													})}
												</div>
											</div>
										</>
										}
									</div>
								</div>
								{p.name === "storage" && p.enabled && p.recommendationCriteria && (
									<>
										<div className="offset-12 offset-md-2 col-12  w-auto">
											<div className="row my-3">
												<div className="col-12 row m-0 w-100">
													<div className="my-3">
														<h5 className="assumption-title">
															Recommendation criteria
															<Info style={{height: "20px"}} className="ml-2" color="#70bbfd" id="TooltipExample"/>
														</h5>
													</div>
													<div className="ml-auto">
														<Button className="btn assumption-storage-add-btn py-2"
														        onClick={() => handleChange('', 'addRecommendation', p._id)}>Add</Button>
													</div>
												</div>
												<div className="col-12">
													<div className="card assumption-card">
														<div className="card-body">
															{p.recommendationCriteria.map((s, i) => {
																const enumOptions = enums.find(e => e.name === s.enumerations);
																const valueOption = enumOptions.values.map(e => ({value: e, label: e}));
																return (
																	<>
																		<div className="row m-0">
																			<div className="d-flex align-items-center">
																				<h5 className="assumption-title mt-2 mr-2 ">PeakDiskI0</h5>
																			</div>
																			<div className="">
																				<UncontrolledDropdown>
																					<ButtonGroup dir="ltr" className="w-100">
																						<Button outline
																						        className="text-left rounded-0 assumption-dropdown-style mb-0">{valueOption.find(v => s.recommended === v.value).label}</Button>
																						<DropdownToggle outline
																						                className="assumptions-icon icon--right rounded-0 mb-0">
																							<ChevronDownIcon/>
																						</DropdownToggle>
																					</ButtonGroup>
																					<DropdownMenu right className="dropdown__menu">
																						{valueOption.map(x => (
																							<DropdownItem name="recommended"
																							              onClick={(e) => handleChange(x, {name: e.target.name}, p._id, i, 'recommendation')}>{x.label}</DropdownItem>
																						))}
																					</DropdownMenu>
																				</UncontrolledDropdown>
																			</div>
																			<div className="ml-auto assumption-slider-close-icon"
																			     onClick={() => handleRemoveRecommendation(p._id, i)}>
																				<CloseIcon/>
																			</div>
																		</div>
																		<div className="my-4" dir="ltr">
																			<Range min={0} max={1000} value={[s.value[0], s.value[1]]}
																			       onChange={(e) => handleChange(e, {name: "value"}, p._id, i, 'recommendation')}
																			       tipFormatter={value => `$${value}`} className="slider-success"/>
																		</div>
																	</>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
								<hr/>
							</>
						)
					})
				}

				{
					!!assumptionsData?.filter(p => p.attributeType === "preference").length &&
					<>
						<div className="row mt-3 validation-row">
							<div className="col-12  d-flex align-items-center">
								<h5 className=" assumption-labels">Preferences</h5>
							</div>
						</div>
						<div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
							{assumptionsData?.filter(p => p.attributeType === "preference").map((p) => {
								return <>
									{
										p.searchThreshold.map(s => {
											const enumOptions = enums.find(e => e.name === p.searchName.slice(11));
											const valueOption = enumOptions.values.map(e => ({value: e, label: e}));
											return (
												<div className="row my-2">
													<div className="col-12">
														<h5 className="assumption-title "> {p.searchName.slice(11)}</h5>
													</div>
													<div className="col-12 col-sm-6 col-md-6 col-lg-2">
														<UncontrolledDropdown>
															<ButtonGroup dir="ltr" className="w-100">
																<Button outline
																        className="w-100 text-left rounded-0 validation-dropdown-style">{operaterOptions.find(op => op.value === s.criteria).label}</Button>
																<DropdownToggle outline className="validation-icon icon--right rounded-0">
																	<ChevronDownIcon/>
																</DropdownToggle>
															</ButtonGroup>
															<DropdownMenu right className="dropdown__menu">
																{operaterOptions.map(o => (
																	<DropdownItem name="criteria"
																	              onClick={(e) => handleChange(o, {name: e.target.name}, p._id, s._id)}>{o.label}</DropdownItem>
																))}
															</DropdownMenu>
														</UncontrolledDropdown>
													</div>
													<div className="col-12 col-md-12 col-lg-4">
														<Select
															isMulti
															name="value"
															value={valueOption.filter(v => s.value.includes(v.value))}
															placeholder="value"
															onChange={(value, e) => handleChange(value, e, p._id, s._id, "isMulti")}
															options={valueOption}
															clearable={false}
															closeOnSelect={false}
															removeSelected={false}
															className="react-select "
															classNamePrefix="react-select"
														/>
													</div>
												</div>
											)
										})
									}
								</>
							})}
						</div>
					</>
				}
			</div>
	)
}
