import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useAsyncDebounce} from 'react-table';
import {DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import AccountSearchIcon from 'mdi-react/SearchIcon';

const options = [{value: undefined, label: 'All'}, {value: 'in-progress', label: 'In Progress'}, {
	value: 'complete',
	label: 'Completed'
}]

const ReactTableFilter = ({
	                          globalFilter,
	                          setGlobalFilter,
	                          placeholder = 'Invoice template',
	                          rows,
	                          dataLength, ...props
                          }) => {
	const [value, setValue] = useState(globalFilter);
	const [status, setStatus] = useState('All');
	const onChange = useAsyncDebounce((item) => {
		setGlobalFilter(item || undefined);
	}, 200);

	console.log(props)

	return (
		<div className="table__search">
			<div className="client-table-search m-0 w-100">
				<div>
					<UncontrolledDropdown>
						<DropdownToggle className="client-dropdown-icon icon icon--right" outline>
							<p className='d-flex align-items-center'>{status}<ChevronDownIcon className="ml-auto mt-0 mb-0"/></p>
						</DropdownToggle>
						<DropdownMenu className="dropdown__menu">
							{options.map(p => (
								<DropdownItem key={p.value} onClick={() => {
									setStatus(p.label)
									setGlobalFilter(p.value)
								}}>{p.label}</DropdownItem>
							))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<div className="ml-sm-auto">
					<div className="form__form-group">
						<div className="form__form-group-field client-project-manager-search-input">
							<Input
								value={value}
								onChange={(e) => {
									setValue(e.target.value);
									onChange(e.target.value);
								}}
								placeholder={placeholder}/>
							<div className="form__form-group-icon clients-search-icon h-auto">
								<AccountSearchIcon/>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

ReactTableFilter.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	dataLength: PropTypes.number.isRequired,
};

ReactTableFilter.defaultProps = {
	globalFilter: '',
	placeholder: 'Search...',
};

export default ReactTableFilter;
