import React, {useState} from 'react';
import {Button, FormFeedback, Input} from 'reactstrap';
import KeyIcon from 'mdi-react/KeyIcon';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {updateLogin} from '../../../../api/admin';
import {useDispatch} from 'react-redux';
import {Creators as profileDuck} from '../../../../store/ducks/profile';
import {useHistory} from 'react-router';
import {Auth} from "aws-amplify";
import {ClipLoader} from "react-spinners";

const SignupSchema = Yup.object().shape({
	code: Yup.string()
		.required('Code is Required')
});

export default function OtpVerificationForm({user}) {
	const [isLoadingOtp, setIsLoadingOtp] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const submitHandler = async (values) => {
		setIsLoadingOtp(true);
		try {
			const loggedUser = await Auth.confirmSignIn(user, values.code, 'SMS_MFA');
			// if (user.preferredMFA === 'SMS')
			// 	await Auth.setPreferredMFA(user, 'NOMFA');

			updateLogin().then(res => {
				console.log(res);
			}).catch(er => {
				console.log(er);
			});
			localStorage.setItem('user', JSON.stringify(loggedUser));
			dispatch(profileDuck.setUser(loggedUser));
			setIsLoadingOtp(false);
			history.push('/homepage');
		} catch (e) {
			setIsLoadingOtp(false);
			toast(e.message, {type: 'error'})
		}
	};

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={{code: ''}}
				validationSchema={SignupSchema}
				onSubmit={(values) => {
					submitHandler(values)
				}
				}>
				{({values, errors, touched, handleChange, handleSubmit, dirty, handleBlur,}) => (
					<form className="form">
						<div className="form__form-group">
							<span className="form__form-group-label">Enter OTP</span>
							<div className="form__form-group-field flex-wrap">
								<div className="form__form-group-icon">
									<KeyIcon/>
								</div>
								<Input type="code"
								       invalid={errors.code && touched.code}
								       onChange={handleChange('code')}
								       onBlur={handleBlur('code')}
								       value={values.code}
								       type="code"
								       style={{width: 'auto'}}
								       placeholder="enter otp here"
								       className="input-without-border-radius flex-grow-1"/>
								<FormFeedback>
									{errors.code && touched.code && errors.code}
								</FormFeedback>
							</div>
						</div>
						<div className="row m-0 w-100">
							<div className="col">
								<Button color="primary" className="btn btn-block btn-primary account__btn account__btn--small"
								        onClick={handleSubmit}>
									{isLoadingOtp ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true}/> : 'Submit'}
								</Button>
							</div>
							{/*<div className="col">*/}
							{/*	<Button color="primary" className="btn btn-block btn-primary account__btn account__btn--small"*/}
							{/*	        onClick={handleSubmit}>*/}
							{/*		Resend OTP*/}
							{/*	</Button>*/}
							{/*</div>*/}
						</div>
					</form>
				)}
			</Formik>
		</div>
	)
}
