import React, {useState} from 'react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Close from 'mdi-react/CloseIcon'
import Assumptions from "../../ReviewAssumptions/Assumptions/Assumptions";
import {Button} from "reactstrap";
import {ClipLoader} from "react-spinners";
import {getAssumptions} from "../../../../../api/admin";

export default function ModifyAssumptionPanel({ isServerDetail, assumptionsData, recalculateHandler, closeHandler, isOpen }) {
	const [assumptions, setAssumptions] = useState(assumptionsData);

	const resetAssumptionHandler = () => {
		setAssumptions(prev => ({ ...prev, isLoading: true }));
		getAssumptions().then((response) => {
			setAssumptions(prev => ({ ...prev, data: response, isLoading: false }))
		}).catch((err) => { setAssumptions({ isLoading: false }); })
	};

	const handleChange = (data) => {
		setAssumptions(prev => ({ ...prev, data: data }));
	};
	console.log(isOpen);
	return (
		<div>
			<SlidingPane
				className="ltr-support theme-light"
				overlayClassName="mb-4"
				isOpen={isOpen}
				closeIcon={<Close style={{ height: "20px", width: "20px" }} />}
				title="Modify Assumption"
				onRequestClose={closeHandler} >
				<Assumptions isServerDetail={isServerDetail} handleChange={handleChange} assumptionsData={assumptions?.data} />
				<div className="d-flex justify-content-center">
					<Button color="secondary" className="btn-sm" onClick={closeHandler}>Back</Button>
					<Button className="btn-sm btn-primary " color="primary" onClick={resetAssumptionHandler}>
						{assumptions?.isLoading ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true} /> : 'Reset'}
					</Button>
					<Button className="btn-sm btn-primary " color="primary" onClick={() => recalculateHandler(assumptions.data)}>Recalculate </Button>
				</div>
			</SlidingPane>
		</div>
	)
}
