import React, {useEffect, useRef, useState} from "react";
import {
	Button,
	Card,
	CardBody,
	Collapse,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	Table,
} from "reactstrap";
import classnames from 'classnames';
import Down from 'mdi-react/ChevronDownIcon';
import Up from 'mdi-react/ChevronUpIcon';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {downloadFile, getUploadUrl, insertClientData, validateClientData} from "../../../../api/admin";
import {useHistory, withRouter} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import AlertCircle from 'mdi-react/AlertCircleIcon'
import AlertOutLine from 'mdi-react/AlertOutlineIcon'
import Check from 'mdi-react/CheckCircleIcon'
import {getExtension} from "../../../../utils";

const AddProject = (props) => {
	let myref = useRef();
	const client = props.match?.params.id;
	const backClientData = props.location?.state?.backClient;
	const [data, setData] = useState({file: ""});
	const [clientData, setClientData] = useState(props.location?.state?.clientData || {data: [], loading: true});
	const [{browseLoading, showName}, setBrowseLoading] = useState({browseLoading: false, showName: false});
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [formData, setFormData] = useState({label: "", description: ""});
	const [isOpen, setIsOpen] = useState({index: null, isOpen: false});
	const [isInvalidFileData, setIsInvalidFileData] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const formHandler = e => {
		setFormData({...formData, [e.target.name]: e.target.value})
	}

	const toggle = index => {
		setIsOpen(prev => ({...prev, index, isOpen: !isOpen.isOpen}))
	};

	const onFileSelected = (e) => {
		const files = e.target.files;
		for (let file of files) {
			if (['xlsx', 'xls'].includes(getExtension(file.name))) {
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setData({file});
					setBrowseLoading(prev => ({...prev, browseLoading: true}));
					getUploadUrl({name: file.name, type: file.type, ACL: 'authenticated-read'})
						.then((res) => {
							uploadUrl(res.uploadURL, file);
						})
						.catch((er) => {
							console.log(er);
						});

				});
				reader.readAsDataURL(file);
			} else {
				toast.error("Please Choose Valid File Format", {position: toast.POSITION.TOP_RIGHT})
			}
		}
		e.target.value = "";
	};

	const uploadUrl = async (url, file) => {
		await fetch(url, {
			method: "PUT",
			body: new Blob([file], {type: file.type}),
		}).then(() => {
			return validateClientData({fileName: file.name}, client);
		}).then((res) => {
			setClientData({data: res.data, loading: false});
			setBrowseLoading(prev => ({...prev, browseLoading: false, showName: true}));
		}).catch((er) => setBrowseLoading(prev => ({...prev, browseLoading: false, showName: true})));
	};

	const handleBrowse = function (e) {
		e.preventDefault();
		myref.click();
	};
	const handleContinue = () => {
		setLoading(true);
		insertClientData({fileName: data.file.name, ...formData}, client).then((response) => {
			history.push(`/homepage/client/${client}/project/review-assumptions`, {
				client,
				clientData: response,
				welcomeData: backClientData
			});
			setLoading(false)
		}).catch((err) => {
			setLoading(false)
		})
	};

	const previousPageHandler = () => {
		history.goBack()
	};

	useEffect(() => {
		if (clientData.data.length) {
			const isInvalid = clientData.data.find(current => {
				const data = Object.values(current).filter(p => Array.isArray(p)).flat();
				const invalid = data.filter(p => !p.valid.toString().toLowerCase().includes('ok') && p.severity === "error").length
				return invalid > 0
			});
			setIsInvalidFileData(!!isInvalid)
		}
	}, [clientData.data])

	const exportToggle = () => setDropdownOpen(prevState => !prevState);

	const getMessage = (data) => {
		let message = data.find(p =>
			(p.severity === "error" && !p.valid.toString().toLowerCase().includes('ok'))
			|| (p.severity === "warning" && !p.valid.toString().toLowerCase().includes('ok')));
		if (!message)
			message = data.find(p => (p.valid.toString().toLowerCase().includes('ok')));
		console.log(message);
		return message;
	};

	const downloadTemplate = async (file) => {
		const url = await downloadFile(file);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.download = file;
		a.href = url;
		a.click();
	};

	return (
		<div>
			<Form className="mt-5 px-3">
				<div className="row">
					<div className="col-10 col-sm-6">
						<FormGroup>
							<Label for="exampleEmail">Label</Label>
							<Input type="text" name="label" placeholder="Enter Label" onChange={formHandler}/>
						</FormGroup>
					</div>
					<div className="offset-6"></div>
					<div className="col-10 col-sm-6">
						<FormGroup>
							<Label for="exampleEmail">Description</Label>
							<Input type="textarea" name="description" onChange={formHandler}/>
						</FormGroup>
					</div>
					<div className="offset-6"></div>
					<div className="col-10 col-sm-6">
						<Label for="exampleEmail">Choose Client Data File</Label>
						<div className="d-flex">
							<FormGroup>
								<input type="file" name="file" accept=".xls, .xlsx" hidden id="exampleFile" ref={(r) => {
									myref = r;
								}} onChange={onFileSelected}/>
								<button className="btn btn-primary" onClick={handleBrowse} disabled={browseLoading}>
									{browseLoading ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true}/> : 'Browse'}
								</button>
								{!browseLoading && showName && <span>{data.file.name}</span>}
							</FormGroup>
							<Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
								<DropdownToggle caret color="primary" className="btn-primary ml-3">
									Download Template
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => downloadTemplate('clientData-template.xlsx')}><a
									> xlsx </a> </DropdownItem>
									<DropdownItem onClick={() => downloadTemplate('clientData-template.csv')}><a
									>csv</a> </DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</div>
			</Form>
			<Table responsive>
				<thead>
				<tr>
					<th>#</th>
					<th>Name</th>
					<th>Validation Ok</th>
					<th>Warnings</th>
					<th>Validation Errors</th>
					<th></th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				{clientData.data &&
				<>
					{clientData.data.map((current, index) => {
						const data = Object.values(current).filter(p => Array.isArray(p)).map(getMessage).flat();
						const invalid = data.filter(p => p.severity === "error" && !p.valid.toString().toLowerCase().includes('ok')).length;
						const warning = data.filter(p => p.severity === "warning" && !p.valid.toString().toLowerCase().includes('ok')).length;
						const valid = data.filter(p => p.valid.toString().toLowerCase().includes('ok')).length;
						return (
							<>
								<tr onClick={() => toggle(index)}
								    className={classnames('validation-rows', invalid > 0 ? 'background-danger' : warning > 0 ? 'background-warning' : 'background-success')}>
									<td>{index + 1}</td>
									<td>{current.serverName}</td>
									<td>{valid}</td>
									<td>{warning}</td>
									<td>{invalid}</td>
									<td colSpan="2" style={{textAlign: 'end'}}>{isOpen.isOpen && isOpen.index === index ? <Up/> :
										<Down/>}</td>
								</tr>
								<tr>
									<td colspan="24">
										<Collapse isOpen={isOpen.isOpen && isOpen.index === index}>
											<Card>
												<CardBody>
													{Object.values(current).filter(p => Array.isArray(p)).map(c => {
														const message = getMessage(c);
														return (<>
															<table style={{width: "100%"}}>
																<tbody>
																<tr>
																	<td style={{width: "30%"}}>{c.length ? c[0].name : ''}</td>
																	<td style={{width: "30%"}}>{c.length ? c[0].value : ''}</td>
																	<td style={{width: "30%"}}>
																		<ListGroup>
																			{<ListGroupItem
																				color={message.valid.toString().toLowerCase().includes('ok') ?
																					'success' : message.severity === 'error' ? 'danger' : 'warning'}>{
																				message.valid.toString().toLowerCase().includes('ok') ? <Check/> :
																					message.severity === 'error' ?
																						<AlertCircle/> :
																						<AlertOutLine/>} {message.valid}</ListGroupItem>}
																		</ListGroup>
																	</td>
																</tr>
																</tbody>
															</table>
														</>)
													})
													}
												</CardBody>
											</Card>
										</Collapse>
									</td>
								</tr>
							</>
						)
					})
					}
				</>
				}
				</tbody>
			</Table>
			{clientData.data.length <= 0 && !clientData.loading &&
			<div className="d-flex justify-content-center">
				<h1 className="mt-4 noresult-found-title">Sorry, No data found :(</h1>
			</div>
			}
			<div className="d-flex justify-content-end mt-5">
				<Button color="secondary" onClick={previousPageHandler}>
					Back
				</Button>
				<Button color="primary" onClick={handleContinue}
				        disabled={isInvalidFileData || (!formData.label ? !formData.label : !clientData.data.length)}>
					{loading ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true}/> : 'Continue'}
				</Button>
			</div>
		</div>
	);
};

export default withRouter(AddProject);
