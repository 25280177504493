import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import Logout from "mdi-react/LogoutIcon";
import { Collapse } from "reactstrap";
import { Auth } from "aws-amplify";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
	constructor() {
		super();
		this.state = {
			collapse: false,
			name: "",
			profile: "",
		};
	}

	componentDidMount = () => {
		Auth.currentUserInfo().then((user) => {
			if (user)
				this.setState({
					user,
					name: user?.attributes["custom:name"],
					profile: user?.attributes["custom:image"],
				});
		});
	};

	toggle = () => {
		this.setState((prevState) => ({ collapse: !prevState.collapse }));
	};

	logoutHandler = () => {
		localStorage.clear();
		this.props.TopbarProfileProps.history.push("/login");
	};

	render() {
		const { collapse, user, name, profile } = this.state;
		if (!user) return null;
		return (
			<div className="topbar__profile">
				<button type="button" className="topbar__avatar" onClick={this.toggle}>
					<img
						className="topbar__avatar-img"
						src={profile ? profile : Ava}
						alt="avatar"
					/>
					<p className="topbar__avatar-name">{name ? name : ""}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && (
					<button
						type="button"
						className="topbar__back"
						onClick={this.toggle}
					/>
				)}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						<span className="topbar__link" onClick={this.logoutHandler}>
							<span className="topbar__link-icon lnr lnr logout-icon">
								<Logout />
							</span>
							<span className="topbar__link-title">Logout</span>
						</span>
					</div>
				</Collapse>
			</div>
		);
	}
}
