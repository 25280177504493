import React, { useState } from 'react'
import { Button } from 'reactstrap';
import WeeklyState from '../../WeeklyState';
import Storage from 'mdi-react/StorageIcon'
import ServerSuggestionsPricing from './ServerSuggestionsPricing';

export default function StorageSuggestionsCard({ highestCost, server, serverSelectHandler, serverUpdateHandler, selectServer, ...props }) {
    const shared = props.shared;
    const { costs, _id, productFamily } = server;
    const reservationTerms = [...new Set(costs.map(p => p.termAttributes.LeaseContractLength))];
    const paymentOptions = [...new Set(costs.map(p => p.termAttributes.PurchaseOption))];
    const pricingModels = [...new Set(costs.map(p => p.termAttributes.OfferingClass))];
    const [selectedCost, setSelectedCost] = useState(costs.find(p => p.selected) || costs[0]);
    const storageCost = selectedCost?.totalCost1Year;

    const selectCostHandler = (costData) => {
        costData.selected = true;
        selectedCost.selected = undefined;
        setSelectedCost(costData);
        serverUpdateHandler(selectServer);
    };

    return (
        <div className="card shadow mt-3">
            <div className="card-body p-3">
                {productFamily === 'Unavailable' ?
                    <span className="server-not-available">No server detail available  for this server</span> :
                    <>
                        <div className="row m-0">
                            <div className="col-12 col-lg-12 col-xl-6">
                                <h3 className="storage-title d-flex align-items-center"><Storage />{server.productFamily}</h3>
                                <div className="row">
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Service name</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.servicename}</span>
                                    </div>
                                    <div className="sm-hr col-12">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Volume Api Name</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.volumeApiName}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Volume Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.volumeType}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Usage Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.usagetype}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Storage Media</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.storageMedia}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Service Code</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.servicecode}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Numeric vCPU</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.numericVCPU || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Volume Size</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxVolumeSize || '-'}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Throughput volume</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxThroughputvolume || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Iops Volume</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxIopsvolume || '-'}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Iops Burst Performance</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxIopsBurstPerformance || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Location Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.locationType || '-'}</span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-12 col-xl-6 d-flex align-items-center justify-content-center my-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    {selectedCost && highestCost && <WeeklyState label={`$${storageCost.toFixed(2)}`}
                                        value={(storageCost * 100) / highestCost} />}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button color={_id === selectServer?._id ? "success" : "primary"}
                                            className="tn btn-primary  account__btn--small"
                                            onClick={() => serverSelectHandler(server)} disabled={shared}>{_id === selectServer?._id ? "Current" : "Select"}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <ServerSuggestionsPricing shared={shared} selectedCost={selectedCost} setSelectedCost={selectCostHandler} costs={costs}
                                reservationTerms={reservationTerms} pricingModels={pricingModels}
                                paymentOptions={paymentOptions} storageCost={storageCost} pricingType={productFamily === "Storage" ? "storage" : 'snapshot'} server={server.server} />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}