import React, {useState} from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import {formatCurrency} from "../../../utils";
import classnames from 'classnames';
import {ClipLoader} from "react-spinners";
import DynamiclyRefreshedDoughnut from '../Form/DynamiclyRefreshedDoughnut'
import ModifyAssumptionPanel from "../Form/ReviewSuggesions/ServerDeatil/ModifyAssumptionPanel";
import StepFourSummery from '../Form/ReviewSuggesions/StepFourSummery';
import StepFourDetail from '../Form/ReviewSuggesions/StepFourDetail';
import {useSelector} from "react-redux";
import Edit from "mdi-react/PencilIcon";
import ShareIcon from 'mdi-react/ShareIcon';
import History from 'mdi-react/HistoryIcon';

export default function ScenarioTab({
	editable, linkShareHandler, historyHandler, suggestionsData, handleSaveScenario, previousPageHandler, clientId,
	submitAssumptionHandler, assumptions, toggles, formData, loading, exportLoading, dropdownOpen, exportToggle, clientData, ...props }) {
	const [isPaneOpen, setIsPaneOpen] = useState(false);
	const { suggestions, isLoading } = useSelector(state => state.suggestions);
	const [activeTab, setActiveTab] = useState('1');
	console.log(clientId)
	const toggle = (tab) => {
		console.log(tab);
		if (activeTab !== tab) {
			setActiveTab(tab)
			console.log(tab);
		}
	};
	const handleRecalculate = (data) => {
		setIsPaneOpen(false);
		submitAssumptionHandler(data);
	};
	return (
		<>
			<div className="row m-0">
				<div className=" col-12 col-sm-12 col-md-12 col-lg-7">
				</div>
				<Container className="dashboard">
					<div className="row m-0">
						<div className="col-12 col-sm-6">
							<div className="row ">
								<div className="col-12">
									<Label className="review-suggestion-label " id="Popover1"
										onClick={() => editable && toggles('popoverLabel')}> {formData.label || "Enter Label"}
										{editable && <Edit color="#70bbfd" style={{ height: "15px" }} />}
									</Label>
								</div>
								<div className="col-12">
									<Label className="review-suggestion-label " id="Popover2"
										onClick={() => editable && toggles('popoverDescription')}> {formData.description ? formData.description : "Enter Description"}
										{editable && <Edit color="#70bbfd" style={{ height: "15px" }} />}
									</Label>
								</div>
							</div>
						</div>
						{editable && <div className="col-12 col-md-6 px-0">
							<div className="d-flex justify-content-end mt-4">
								<div className="row d-flex flex-lg-row flex-column justify-content-end">
									<div className="d-flex">
										<Button color="primary" size="sm"
										        disabled={!suggestions?._id}
										        className="d-flex align-items-center account__btn--small py-2 px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4 mx-1"
										        onClick={() => linkShareHandler()}>
											<ShareIcon className="review-suggestion-h-icon" />Share</Button>
										<Button color="primary" size="sm"
											className="d-flex align-items-center account__btn--small  py-2px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4 mx-1"
											onClick={() => historyHandler()}>
											<History className="review-suggestion-h-icon" />History</Button>
									</div>
									<div className="d-flex">
										<Button color="primary" className=" account__btn--small px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4 mx-1"
											onClick={() => handleSaveScenario()} disabled={!formData.label || loading || exportLoading}>
											{loading ?
												<ClipLoader sizeUnit={"px"} size={20} color={"#4ce1b6"} loading={true} /> : 'Save'}
										</Button>
										<Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
											<DropdownToggle caret color="primary"
												className=" account__btn--small px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4 mx-1"
												disabled={!formData.label || exportLoading || loading}>
												{exportLoading ?
													<ClipLoader sizeUnit={"px"} size={20} color={"#4ce1b6"} loading={true} /> : "Export"}
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem
													onClick={() => handleSaveScenario('xlsx')}>{suggestions?._id ? 'Export xlsx' : 'Save And Export xlsx'}</DropdownItem>
												<DropdownItem
													onClick={() => handleSaveScenario('csv')}>{suggestions?._id ? 'Export csv' : 'Save And Export csv'}</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</div>
								</div>
							</div>
						</div>}
					</div>
					<Row className="m-0">
						<Col className="annual-cost" md={12} xl={3} lg={6} xs={12} style={{ height: "fit-content" }}>
							<div>
								<h1>
									<h2 className="total-annual-cost">{formatCurrency(suggestionsData?.totalCost)}</h2>
									<h4>TOTAL ANNUAL COST</h4>
								</h1>
							</div>
						</Col>
						<Col className="annual-cost" md={12} xl={3} lg={6} xs={12} style={{ height: "fit-content" }}>
							<div>
								<h1>
									<h2 className="total-service">{Object.values(suggestionsData?.data).length}</h2>
									<h4>NUMBER OF SERVERS</h4>
								</h1>
							</div>
						</Col>
						<Col md={12} xl={6} lg={12} xs={12}
							className="my-4 my-sm-4 my-md-0 my-lg-0 my-xl-0 my-xxl-0 px-0 px-sm-0 px-md-2 px-lg-2 px-xl-2">
							<Card className="review-suggestion-card">
								<CardBody>
									<DynamiclyRefreshedDoughnut chartData={suggestionsData?.chartData} />
									{editable && <div className="d-flex justify-content-center mt-4">
										<Button color="primary"
											className="px-2 px-sm-2 px-md-3 px-lg-3 px-xl-3 tn btn-primary  account__btn--small"
											onClick={() => setIsPaneOpen(true)}>Modify Default Usage
											Assumptions</Button>
									</div>}
									{assumptions && <ModifyAssumptionPanel isOpen={isPaneOpen}
										assumptionsData={{ data: assumptions.default }}
										recalculateHandler={handleRecalculate}
										closeHandler={() => setIsPaneOpen(false)} />}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<div className=" col-12 col-sm-12 col-md-12 col-lg-5"></div>
			</div>
			<div className="tabs tabs--justify tabs--bordered-bottom">
				<div className="tabs__wrap">
					<Nav tabs>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => toggle("1")}>
								Summary
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => toggle("2")}>
								Detailed
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							{isLoading ? (
								<div className="d-flex justify-content-center align-items-center">
									<ClipLoader size={50} color={"#4ce1b6"} loading={true} />
								</div>
							) : (
								suggestionsData?.data && (
									<StepFourSummery
										suggestionsData={suggestionsData?.data}
										clientData={clientData}
										clientId={clientId}
										assumptionsData={assumptions}
										editable={editable}
										sharedScenario={props.sharedScenario}
									/>
								)
							)}
						</TabPane>
						<TabPane tabId="2">
							{isLoading ? (
								<div className="d-flex justify-content-center align-items-center">
									<ClipLoader size={50} color={"#4ce1b6"} loading={true} />
								</div>
							) : (
								suggestionsData?.data && (
									<StepFourDetail
										suggestionsData={suggestionsData?.data}
										clientData={clientData}
										clientId={clientId}
										assumptionsData={assumptions}
										sharedScenario={props.sharedScenario}
									/>
								)
							)}
						</TabPane>
					</TabContent>
					<div className="d-flex justify-content-end mt-5 mx-2 mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5 ">
						<Button color="secondary" onClick={previousPageHandler}>
							Back
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}
