const tenant = window.location.hostname === 'localhost' ? 'dev' : window.location.hostname.split('.')[0];
export default {
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "optima"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    // URL: "https://zu9jccsw04.execute-api.ap-southeast-2.amazonaws.com/dev",
    URL: `https://${tenant}.api.optima.com.au`,
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_DVpdy6fXB",
    APP_CLIENT_ID: "mg9q4eunpa53t0ckt6unlnvis",
    IDENTITY_POOL_ID: "ap-southeast-2:f0193b42-57ef-4d38-99e9-362aa5e6ede2"
  }
};
