import React, {useEffect, useState} from 'react'
import {Button, Input, Modal, ModalBody, ModalHeader, Tooltip} from 'reactstrap';
import {Creators as scenarioLinkDuck} from "../../../../store/ducks/ScenarioLink";
import RefreshIcon from 'mdi-react/AutorenewIcon';
import CopyIcon from 'mdi-react/ContentCopyIcon';
import {ClipLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

export default function ShareScenario({ clientId, dataId, scenarioId, shareModal, linkShareHandler, ...props }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState('1');
	const [clipBoardMessage, setClipBoardMessage] = useState();
	// const [shareModal, setShareModal] = useState(false);
	const { scenarioLink, isLoadingRefreshScenarioLink, isLoadingRefreshScenarioLinkCode, isLoadingScenarioLink } = useSelector(state => state.scenarioLink);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
		}
	};

	useEffect(() => {
		if (scenarioId) {
			dispatch(scenarioLinkDuck.getScenarioLink(scenarioId, dataId, clientId))
		}
	}, [])
	// const linkShareHandler = () => {
	//     setShareModal(!shareModal)
	// }

	const refreshLinkHandler = () => {
		console.log(scenarioId, scenarioLink._id);
		dispatch(scenarioLinkDuck.refreshScenarioLink(scenarioId, dataId, clientId))
	}
	const refreshCodeHandler = () => {
		dispatch(scenarioLinkDuck.refreshScenarioLinkCode(scenarioId, dataId, clientId))
	}

	// const copyClipboardHandler = () => {
	//     navigator.clipboard.writeText(`${window.location.origin}/links/scenarios/${scenarioLink._id}`);
	//     setTooltipOpen(true)
	//     setTimeout(() => {
	//         setTooltipOpen(false)
	//     }, 1000);
	// }

	function fallbackCopyTextToClipboard(text) {
		console.log(text);
		var textArea = document.createElement("textarea");
		textArea.value = text;
		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
			console.log('Fallback: Copying text command was ' + msg);
		} catch (err) {
			console.error('Fallback: Oops, unable to copy', err);
		}
		document.body.removeChild(textArea);
	}
	function copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			fallbackCopyTextToClipboard(text);
			return;
		}
		navigator.clipboard.writeText(text).then(function () {
			setClipBoardMessage('Copying to clipboard')
			setTooltipOpen(true)
			setTimeout(() => {
				setTooltipOpen(false)
			}, 1000);
		}, function (err) {
			setClipBoardMessage('Could not copy text:', err)
		});
	}

	return (
		<div className="link-share-modal">
			<Modal className="link-share-modal" isOpen={shareModal} toggle={() => linkShareHandler(null)}>
				<ModalHeader toggle={() => linkShareHandler(null)}>SHARE SCENARIO
					{/* <FormText color="muted">Before Saving describe the edits completed</FormText> */}
				</ModalHeader>
				<ModalBody>
					{isLoadingScenarioLink ?
						<ClipLoader sizeUnit={"px"} size={20} color={"#4ce1b6"} loading={true} /> :
						<>
							<div className="row flex-column flex-sm-row">
								<div className="col-12">
									<label htmlFor="">Link</label>
								</div>
								<div className="col-12 col-sm-7 px-0 px-sm-2">
									<Input type="text" value={`${window.location.origin}/links/scenarios/${scenarioLink?._id}`}
										readOnly />
								</div>
								<div className="col-12 col-sm-5 mt-sm-0 mt-2">
									<div className="row">
										<Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
											{clipBoardMessage}
										</Tooltip>
										<Button className="btn-primary px-3 py-2" id="TooltipExample"
											onClick={() => copyTextToClipboard(`${window.location.origin}/links/scenarios/${scenarioLink?._id}`)}><CopyIcon
												className="m-0" /></Button>
										<Button color="primary" className="px-3 d-flex align-items-center btn btn-primary py-2"
											onClick={refreshLinkHandler}>

											{isLoadingRefreshScenarioLink ?
												<ClipLoader sizeUnit={"px"} size={20} color={"#4ce1b6"} loading={true} /> :
												<div>
													<RefreshIcon className="review-suggestion-h-icon m-0" />
												</div>}
										</Button>
									</div>
								</div>
							</div>
							<div className="row flex-column flex-sm-row">
								<div className="col-12">
									<label htmlFor="">Code</label>
								</div>
								<div className="col-12 col-sm-7 px-0 px-sm-2">
									<Input type="text" value={scenarioLink?.code} readOnly />
								</div>
								<div className="col-12 col-sm-5 mt-sm-0 mt-2">
									<div className="row">
										<Button color="primary" className="px-3 d-flex align-items-center btn btn-primary py-2"
											onClick={refreshCodeHandler}>
											{isLoadingRefreshScenarioLinkCode ?
												<ClipLoader sizeUnit={"px"} size={20} color={"#4ce1b6"} loading={true} /> :
												<div>
													<RefreshIcon className="review-suggestion-h-icon m-0" />
												</div>}
										</Button>
									</div>
								</div>
							</div>
						</>}
				</ModalBody>
			</Modal>
		</div>
	)
}
