import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col} from "reactstrap";
import {ClipLoader} from "react-spinners";
import {useHistory, withRouter} from "react-router-dom";
import Assumptions from "./Assumptions/Assumptions";
import {getAssumptions} from "../../../../api/admin";
import {useDispatch, useSelector} from "react-redux";
import {Creators as suggestionsDuck} from "../../../../store/ducks/suggestions";
import {usePrevious} from "../../../../utils";

function ReviewAssumptions({ isServerDetail = false, closeHandler, ...props }) {
  console.log(props)
  const [assumptionsData, setAssumptionsData] = useState({
    data: [],
    isLoading: false,
    isError: false,
  });
  const { suggestions, isLoading, isError } = useSelector(
    (state) => state.suggestions
  );
  const clientId = props.match?.params.id;
  const clientData = props.location?.state.clientData;
  const history = useHistory();
  const dispatch = useDispatch();
  const previousLoading = usePrevious(isLoading);
  // const { isLoading: enumsLoading } = useSelector(state => state.enums);

  useEffect(() => {
    setAssumptionsData((prev) => ({ ...prev, isLoading: true }));
    getAssumptions()
      .then((response) => {
        setAssumptionsData((prev) => ({
          ...prev,
          data: response,
          isLoading: false,
          isError: false,
        }));
      })
      .catch((err) => {
        setAssumptionsData({ isLoading: false, isError: true });
      });
  }, []);

  useEffect(() => {
    if (!isLoading && previousLoading && !isError) {
      history.push(
	      `/homepage/client/${clientId}/project/review-suggestions/${suggestions._id || "new-scenario"
        }`,
        {
          assumptionsData: { default: assumptionsData.data },
          clientData,
          clientId,
        }
      );
    }
  }, [isLoading]);

  const handleChange = (data) => {
    setAssumptionsData((prev) => ({ ...prev, data: data }));
  };

  const handleContinue = () => {
    const serverIds = clientData.rows.map((p) => p._id);
    dispatch(
      suggestionsDuck.getSuggestions({
        serverIds,
        assumptions: assumptionsData.data,
      })
    );
  };

  const previousPageHandler = () => {
    history.goBack();
  };

  return (
    <div>
      <Col md={12} lg={12} className={isServerDetail ? "mt-0" : "mt-5"}>
        <Card>
          <CardBody className="review-assumption-container">
            <Assumptions
              isServerDetail={isServerDetail}
              handleChange={handleChange}
              assumptionsData={assumptionsData.data}
              loading={assumptionsData.isLoading}
            />

            <div className="d-flex justify-content-center">
              <div>
                <Button
                  color="secondary"
                  className="btn-sm"
                  onClick={closeHandler ? closeHandler : previousPageHandler}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  className="btn-sm"
                  onClick={handleContinue}
                  disabled={isLoading || assumptionsData.isLoading}
                >
                  {isLoading ? (
                    <ClipLoader
                      sizeUnit={"px"}
                      size={20}
                      color={"#4ce1b6"}
                      loading={true}
                    />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default withRouter(ReviewAssumptions);
