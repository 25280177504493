import React, {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, Table, TabPane,} from "reactstrap";
import Delete from "mdi-react/DeleteIcon";
import Edit from "mdi-react/EditIcon";
import DataTableModal from "./ClientAddModal";
import {ClipLoader} from "react-spinners";
import ConfirmationDialog from "./ConfirmDialog";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {Creators as clientDuck} from "../../../store/ducks/client";
import {Creators as projectReducer} from '../../../store/ducks/projects';
import {Creators as suggestionsDuck} from '../../../store/ducks/suggestions';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useUserRole} from "../../../utils";
import Avatar from "../../../assets/avatar.png";
import classnames from 'classnames';
import ClientData from "../Form/ClientData/ClientData";
import ConfirmDialog from '../HomePage/ConfirmDialog';
import {debounce} from "../../../utils/index";
import ProjectData from "../Form/ClientData/ProjectData";


export default function ClientList(props) {
	const {
		client,
		isError,
		isLoading,
		isDeleteLoading: deleteLoading,
		isDeleteError,
	} = useSelector((state) => state.clients);
	const [projectData, setProjectData] = useState(null);
	const [scenarioData, setScenarioData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const userRole = useUserRole();
	const [activeTab, setActiveTab] = useState(userRole === 3 ? '2' : '1');
	const [modal, setModal] = useState(false);
	const [clientToUpdate, setClientToUpdate] = useState({});
	const [clientDelete, setClientDelete] = useState("");
	const [indexRow, setIndex] = useState(null);
	const [searchName, setSearchName] = useState("");
	const [isCollapse, setIsCollapse] = useState({ collapse: false, index: null });
	const dispatch = useDispatch();
	const { projects, isLoading: projectLoading, isError: projectError, isDeleteLoading: projectDeleteLoading, isDeleteError: projectDeleteError } = useSelector(state => state.project)
	const history = useHistory();
	const { user } = useSelector((state) => state.user);
	const confirmationDialogBox = (p) => setClientDelete(p);
	const newClientHandler = (client) =>
		history.push(`/homepage/client/${client._id}/welcome`, client);
	const deleteClientHandler = (id) => dispatch(clientDuck.deleteClient(id));
	const isAdminOrTenantManager = userRole === 2 || userRole === 0;
	const isClientManager = userRole === 1;

	console.log(selectedProject?.selectedProject)
	useEffect(() => {
		dispatch(clientDuck.getClients());
		dispatch(projectReducer.getProjects());
	}, []);

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			// history.push(`/homepage/newclient/${props.client._id}`)
			setActiveTab(tab)
		}
	};

	const handleSetSelectedClint = (c) => {
		setSelectedClient(c);
		// history.push(`/homepage/newclient/${c._id}`);
	};

	const handleSetSelectedProject = (projectDetail, selectedProject) => {
		console.log(projectDetail, selectedProject)
		setSelectedProject({ projectDetail, selectedProject });
		// setSelectedProject({ projectDetail, selectedProject });
		// history.push(`/homepage/newclient/${c._id}`);
	};

	useEffect(() => {
		if (!deleteLoading && clientDelete && !isDeleteError) {
			setClientDelete(null);
			setSelectedClient(null)
		}
		if (!deleteLoading && clientDelete && isDeleteError)
			toast.error(isDeleteError.message, {
				position: toast.POSITION.TOP_RIGHT,
			});
	}, [deleteLoading]);

	const searchHandler = (e) => {
		const filter = e.target.value;
		const updatedList = client?.filter(
			(item) => item.name.toLowerCase().search(filter.toLowerCase()) !== -1
		);
		setSearchName(filter);
		// setData(updatedList);
	};

	const toggle = () => {
		setModal((prevState) => !prevState);
		setClientToUpdate(null);
	};

	const updateClientHandler = (e, p) => {
		e.stopPropagation();
		setModal(true);
		setClientToUpdate(p);
	};

	const addNewProjectHandler = () => {
		history.push(`/homepage/client/${selectedClient._id}/project/add`, {backClient: client});
	}
	const addNewSelectedProjectHandler = () => {
		history.push(`/homepage/client/${selectedProject.projectDetail._id}/project/add`, {backClient: client});
	}

	const confirmationDeleteProject = () => {
		setScenarioData(null);
		setProjectData(null);
	};

	const collapseHandler = (e, id) => {
		e.stopPropagation()
		let collapse = true;
		if (id === isCollapse.index) {
			collapse = false;
			id = null;
		}
		setIsCollapse({ collapse, index: id })
	}

	const deleteScenarioHandler = ({ id, dataId, clientId }) => {
		dispatch(projectReducer.deleteScenario({ id, dataId, clientId }));
	}
	const deleteProjectHandler = ({ _id, clientId }) => {
		dispatch(projectReducer.deleteProject({ _id, clientId }));
	}

	const viewScenarioHandler = (suggestions, clientData, clientId) => {
		dispatch(suggestionsDuck.getSuggestionDataSuccess(null));
		history.push(`/homepage/client/${clientId}/project/review-suggestions/${suggestions._id}`, {
			client: clientId,
			clientData
		});
	};

	const selectDeleteScenarioHandler = (id, dataId, clientId) => {
		setScenarioData({ id, dataId, clientId });
	}

	const onMouseEffect = debounce((index) => {
		setIndex(index);
	}, 200);

	return (
		<>
			<div>
				<ConfirmationDialog
					isOpen={!!clientDelete}
					isLoading={deleteLoading}
					deleteHandler={() => deleteClientHandler(clientDelete)}
					closeHandler={() => confirmationDialogBox(null)}
				/>
				<DataTableModal
					modal={{ modal, clientToUpdate }}
					toggle={() => toggle(null)}
				/>
				<div className="row m-0">
					<div className="col-12 col-xl-4">
						<div className="row">
							<div className="col-12">
								<div className="card shadow">
									<div className="card-body pb-0 px-3">
										{isLoading ? (
											<div className="p-5 d-flex  my-auto justify-content-center align-items-center">
												<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
											</div>
										) : selectedClient && activeTab === "1" ? (
											<>
												<div className="d-flex user-profile-card  align-items-center align-items-xl-start">
													<div className="mr-2 user-profile-icon">
														<img
															src={selectedClient.image ? selectedClient.image : Avatar}
															className="user-profile-icon"
															alt="user-profile-icon"
														/>
													</div>
													<div className="user-profile-details">
														<h2 className="user-profile-name">
															{selectedClient.name}
														</h2>
														<h3 className="user-profile-categories">
															{selectedClient.designation}
														</h3>
														<Badge className="user-badge mb-1">
															{selectedClient.role}
														</Badge>
														<h2 className="user-profile-email">
															{selectedClient.email}
														</h2>
														<h2 className="user-profile-phone">
															{selectedClient.phoneNumber}
														</h2>
													</div>
												</div>
												<hr />
												<div className="mb-2 user-profile-count d-block d-sm-none">
													<div className="d-flex justify-content-between justify-content-xl-around w-100">
														<div className="mb-2">
															{selectedClient && (
																<h2 className="user-profile-clients">
																	{selectedClient.projects}
																</h2>
															)}
															<p className="user-profile-clients-name">Projects</p>
														</div>
														<div>
															<Button
																type="button"
																color="primary"
																className="py-1 px-3 btn btn-success mb-0  w-15"
																onClick={addNewProjectHandler}>
																New Project
															</Button>
														</div>
													</div>
												</div>
												<div className=" mb-2 flex-row d-none d-sm-flex justify-content-between ">
													<div className="mb-2">
														{selectedClient && (
															<h2 className="user-profile-clients mb-1">
																{selectedClient.projects}
															</h2>
														)}
														<p className="user-profile-clients-name">Projects</p>
													</div>
													<div>
														{(isClientManager || isAdminOrTenantManager) && <Button
															type="button"
															color="primary"
															className="py-1 px-3 btn btn-success mb-0  w-15"
															onClick={addNewProjectHandler}>
															New Project
														</Button>}
													</div>
												</div>
											</>
										) : selectedProject && activeTab === "2" ? (
											<>
												<div className="d-flex user-profile-card  align-items-center align-items-xl-start">
													<div className="mr-2 user-profile-icon">
														<img
															src={
																selectedProject.projectDetail.image ? selectedProject.projectDetail.image : Avatar
															}
															className="user-profile-icon"
															alt="user-profile-icon"
														/>
													</div>
													<div className="user-profile-details">
														<h2 className="user-profile-name">
															{selectedProject.projectDetail.company}
														</h2>
														<h2 className="user-profile-email">
															{selectedProject.projectDetail.name}
														</h2>
														<h3 className="user-profile-categories">
															{selectedProject.projectDetail.designation}
														</h3>
														<Badge className="user-badge mb-1">
															{selectedProject.projectDetail.role}
														</Badge>
														<h2 className="user-profile-email">
															{selectedProject.projectDetail.email}
														</h2>
														<h2 className="user-profile-phone">
															{selectedProject.projectDetail.phoneNumber}
														</h2>
													</div>
												</div>
												<hr />
												<div className="mb-2 user-profile-count d-block d-sm-none">
													<div className="d-flex justify-content-between justify-content-xl-around w-100">
														<div className="mb-2">
															{selectedProject.projectDetail && (
																<h2 className="user-profile-clients">
																	{selectedProject.projectDetail.projects}
																</h2>
															)}
															<p className="user-profile-clients-name">Projects</p>
														</div>
														<div>
															{(isAdminOrTenantManager || isClientManager) && <Button
																type="button"
																color="primary"
																className="py-1 px-3 btn btn-success mb-0 account__btn--small w-15"
																onClick={addNewProjectHandler}>
																New Project
																</Button>}
														</div>
													</div>
												</div>
												<div className=" mb-2 flex-row d-none d-sm-flex justify-content-between ">
													<div className="mb-2">
														{selectedProject && (
															<h2 className="user-profile-clients mb-1">
																{selectedProject.projects}
															</h2>
														)}
														<p className="user-profile-clients-name">Projects</p>
													</div>
													<div>
														<Button
															type="button"
															color="primary"
															className="py-1 px-3 btn btn-success mb-0  w-15"
															onClick={addNewSelectedProjectHandler}>
															New Projects
															</Button>
													</div>
												</div>
											</>
										) :
											<div>
												<h2 className="user-profile-container-title">
													Select a User to View their Profile
												</h2>
											</div>}
									</div>
								</div>
							</div>
							<div className="col-12  mt-3">
								<div className="card shadow">
									<div className="card-body p-0">
										<Col md={12} lg={12}>
											<Card className="">
												<CardBody className="p-0">
													{activeTab === "1" && client && isAdminOrTenantManager &&
														<div className="workspace-n-btn">
															<Button
																type="button"
																color="primary"
																className="py-1 px-3 btn btn-success mb-0 w-15"
																onClick={toggle}>
																New Client
														</Button>
														</div>}
													<div className="tabs tabs--bordered-bottom">
														<div className="tabs__wrap">
															<Nav tabs>
																{(userRole === 0 || userRole === 1) && <NavItem>
																	<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {
																		toggleTab('1');
																	}}>
																		CLIENTS
																	</NavLink>
																</NavItem>}
																{(userRole === 0 || userRole === 3 || userRole === 1) && <NavItem>
																	<NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {
																		toggleTab('2');
																	}}>
																		PROJECTS
																	</NavLink>
																</NavItem>}
															</Nav>
															<TabContent activeTab={activeTab}>
																<TabPane tabId="1">
																	<Table
																		responsive
																		className="table--bordered table-hover"
																	>
																		<tbody>
																			{!isLoading &&
																				client &&
																				client.map((u, index) => (
																					<tr
																						className={classnames("pointer", { selected: u === selectedClient })}
																						onMouseEnter={() => onMouseEffect(index)}
																						onMouseLeave={() => setIndex(null)}
																						onClick={() => handleSetSelectedClint(u)}>
																						<td className="d-flex">
																							<img src={u.image ? u.image : Avatar} className="user-profile-icons"
																								alt="UserProfileIcon" />
																							<div className="d-flex flex-column">
																								<div className="col">
																									<h2 className="user-name-title">{u.name}</h2>
																									<h2 className="user-name-description">{u.company}</h2>
																								</div>
																								<h2 className="user-name-description">{u.profileCategory}</h2>
																							</div>
																						</td>
																						<td>{moment(u.createdAt).format("HH:mm")}</td>
																						{isAdminOrTenantManager && (
																							// <td>
																							// 	<span className="p-2  mr-1 " onClick={(e) => confirmationDialogBox(u._id)}>
																							// 		<Delete />
																							// 	</span>
																							// </td>
																							<td>
																								<div className="row m-0 flex-nowrap">
																									<span className="p-1 ml-1 mr-1"
																										onClick={(e) => updateClientHandler(e, u)}><Edit /></span>
																									<span className="p-1"
																										onClick={(e) => confirmationDialogBox(u._id)}><Delete /></span>
																								</div>
																							</td>
																						)}

																					</tr>
																				))}
																		</tbody>
																	</Table>
																	{client && !client.length && !isLoading && (
																		<div className="d-flex justify-content-center">
																			<h1 className="mt-4 noresult-found-title">
																				Record Not Found
																			</h1>
																		</div>
																	)}
																	{isError && (
																		<div className="d-flex justify-content-center">
																			<h1 className="mt-4 noresult-found-title">
																				Network error
																			</h1>
																		</div>
																	)}
																	{isLoading && (
																		<div className="p-5 d-flex justify-content-center align-items-center">
																			<ClipLoader
																				size={40}
																				color={"#4ce1b6"}
																				loading={true}
																			/>
																		</div>
																	)}
																</TabPane>
																<TabPane tabId="2">
																	<div>
																		<ConfirmDialog
																			title={`Delete ${projectData ? "Project" : "Scenario"} ?`}
																			subTitle={`Do you want to delete  ${projectData ? " Project" : "Scenario"} ?`}
																			isOpen={projectData || scenarioData}
																			isLoading={projectDeleteLoading}
																			deleteHandler={() => projectData ? deleteProjectHandler(projectData) : deleteScenarioHandler(scenarioData)}
																			closeHandler={confirmationDeleteProject}
																		/>
																		{isLoading ? <div className='d-flex justify-content-center align-items-center'>
																			<ClipLoader size={40} color='#4ce1b6' />
																		</div> :
																			<div>
																				<Table responsive hover className="mt-3">
																					<tbody>
																						{projects && projects?.map((c, index) => c.data.map(d => {
																							return (
																								<tr className={classnames("pointer", { selected: d === selectedProject?.selectedProject })}
																									// onMouseEnter={() => onMouseEffect(index)}
																									// onMouseLeave={() => setIndex(null)}
																									onClick={() => handleSetSelectedProject(c, d)}>
																									<td className="d-flex">
																										<img src={c.image ? c.image : Avatar}
																											className="user-profile-icons"
																											alt="UserProfileIcon" />
																										<div className="d-flex flex-column">
																											<div className="col">
																												<h2 className="user-name-title">{d.label}</h2>
																												<h2 className="user-name-description">{d.description}</h2>
																											</div>
																											{/*<h2 className="user-name-description">{c.profileCategory}</h2>*/}
																										</div>
																									</td>
																									<td className="">
																										<div className="d-flex flex-column">
																											<div className="col">
																												<h2 className="user-name-title">{c.company}</h2>
																												<h2 className="user-name-description">{c.phoneNumber}</h2>
																											</div>
																										</div>
																									</td>
																									<td>{moment(c.createdAt).format("HH:mm")}</td>
																									{/*<td>*/}
																									{/*			<span className="p-2  mr-1 "*/}
																									{/*			      onClick={(e) => confirmationDialogBox(c._id)}>*/}
																									{/*				<Delete/>*/}
																									{/*			</span>*/}
																									{/*</td>*/}
																								</tr>
																							)
																						}))}
																					</tbody>
																				</Table>
																			</div>
																		}
																	</div>
																</TabPane>
															</TabContent>
														</div>
													</div>
												</CardBody>
											</Card>
										</Col>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-8">
						{isLoading ? (
							<div className="p-5 d-flex  my-auto h-100 justify-content-center align-items-center">
								<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
							</div>
						) :
							<>
								{selectedClient && activeTab === "1" && <ClientData selectedClient={selectedClient} />}
								{selectedProject && activeTab === "2" && <ProjectData selectedProjectId={selectedProject.projectDetail} selectedProject={selectedProject?.selectedProject} />}
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}
