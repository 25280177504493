import React, {useEffect, useState} from 'react';
import ScenarioTab from './ScenarioTab';
import CodeVerifications from './CodeModal';
import logoImage from "../../../assets/logo.png";
import {useSelector} from "react-redux";
import {groupBy} from "../../../utils";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Topbar from "../../Layout/topbar/Topbar";

export default function Scenario() {
	const {scenarios, isLoadingScenario} = useSelector(state => state.scenarioLink);
	const [suggestionsData, setSuggestions] = useState({data: [], isLoading: false});
	const [formData, setFormData] = useState({label: "", description: ""});
	const processSuggestions = suggestions => {
		setFormData({_id: suggestions._id, label: suggestions.label, description: suggestions.description});
		const labels = [];
		const data = [];
		for (let products of Object.values(suggestions.products)) {
			const productsByFamily = groupBy(products, 'productFamily');
			Object.keys(productsByFamily).forEach(l => {
				const index = labels.findIndex(p => p === l);
				const selectedServer = productsByFamily[l].find(p => p.selected) || productsByFamily[l][0];
				const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
				if (index >= 0) {
					data[index] += cost?.totalCost1Year || 0;
				} else {
					labels.push(l);
					data.push(cost?.totalCost1Year || 0);
				}
			});
		}
		const productsLabels = [];
		const totalCost = Object.values(suggestions.products).reduce((p, c) => {
			const productsByFamily = groupBy(c, 'productFamily');
			let total = p;
			Object.keys(productsByFamily).forEach(l => {
				productsLabels.push(l)
				const index = productsLabels.findIndex(p => p === l);
				const selectedServer = productsByFamily[l].find(p => p.selected) || productsByFamily[l][0];
				const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
				if (!selectedServer) total += 0;
				if (!cost) total += 0;
				if (index >= 0) {
					total += cost?.totalCost1Year || 0;
				}
			})
			return total;
			// const selectedServer = c.find(p => p.selected) || c[0];
			// if (!selectedServer) return p + 0;
			// const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
			// if (!cost) return p + 0;
			// return cost.totalCost1Year + p;
		}, 0);
		setSuggestions({
			data: suggestions.products,
			totalCost: totalCost,
			isLoading: false,
			chartData: {labels, data}
		});
		// setAssumptions(suggestions.assumptions);
	}
	useEffect(() => {
		console.log(scenarios);
		if (scenarios)
			processSuggestions(scenarios);
	}, [scenarios]);


	return (
		<>
			<div className="">
				<Topbar/>
			</div>
			<div className="container__wrap" style={{paddingLeft: 0, paddingTop: 0}}>
				<Container>
					<Row>
						<Col md={12} lg={12}>
							{!scenarios ?
								<div className="account">
									<div className="account__wrapper">
										<div className="account__card shadow">
											<div className="account__head">
												<h3 className="account__title">
													<img className="topbar__logo__image" src={logoImage} alt=""/>
												</h3>
												<h4 className="account__subhead subhead">Code Verification</h4>
											</div>
											<CodeVerifications/>
										</div>
									</div>
								</div>
								:
								<Card>
									<CardBody className="wizard review-assumption-card">
										<ScenarioTab
											sharedScenario
											formData={formData}
											assumptions={scenarios.assumptions}
											suggestionsData={suggestionsData}/>
									</CardBody>
								</Card>}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}
