import React from "react";
import { Col, Modal, ModalFooter, ModalBody, Row, Button } from 'reactstrap';
import { ClipLoader } from "react-spinners";
import DeleteIcon from "mdi-react/DeleteIcon";

export default function ConfirmDialog({ isOpen, deleteHandler, isLoading, closeHandler, title = "Delete Client?", subTitle = " Do you want to delete client ?", ...props }) {
    return (
        <div>
            <div>
                <Row>
                    <Col>
                        <Modal isOpen={isOpen}>
                            <div className="row ">
                                <div className="col-12 d-flex justify-content-center">
                                    <div className="delete-square d-flex justify-content-center align-items-center">
                                        <DeleteIcon className="delete-icon" />
                                    </div>
                                </div>
                            </div>
                            <ModalBody style={{ textAlign: "center" }}>
                                <h3 className="confirm-dialog-title">{title}</h3>
                                <div className="confirm-dialog-sub-title">
                                    {subTitle}
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-content-center">
                                <Button color="danger" className="mb-0" onClick={deleteHandler} disabled={isLoading}>
                                    {isLoading ? <ClipLoader
                                        sizeUnit={"px"}
                                        size={20}
                                        color={"#70bbfd"}
                                        loading={true}
                                    /> : 'Yes'}</Button>
                                <Button color="secondary" className="mb-0" onClick={closeHandler}>No</Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </div>
    )
}