import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import classNames from "classnames";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import {Auth} from "aws-amplify";

class Layout extends Component {
	state = {
		sidebarShow: false,
		sidebarCollapse: false,
		user: null,
	};
	componentDidMount = () => {
		Auth.currentSession()
			.then(user => this.setState({user}))
			.catch((e) => this.props.history.push("/login"));
	};

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({sidebarShow: !prevState.sidebarShow}));
	};

	render() {
		const {sidebarShow, user, sidebarCollapse} = this.state;
		const layoutClass = classNames({
			layout: true,
			"layout--collapse": sidebarCollapse,
		});

		return (
			<div className={layoutClass}>
				<Topbar
					TopbarProps={this.props}
					changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					changeSidebarVisibility={this.changeSidebarVisibility}
				/>
				<Sidebar
					sidebarShow={sidebarShow}
					sidebarCollapse={sidebarCollapse}
					changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
				/>
			</div>
		);
	}
}

export default withRouter(Layout);
