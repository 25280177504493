import React, { useEffect, useState } from 'react'
import { groupBy } from '../../../../../utils';

import ServerSuggestionsCard from './ServerSuggestionsCard';

export default function ServerSuggestions(props) {
	const suggestion = props.suggestion;
	const shared = props.shared
	const [selectServer, setSelectServer] = useState();
	const productsByFamily = groupBy(Object.values(suggestion), 'productFamily');

	useEffect(() => {
		setSelectServer(productsByFamily['Compute Instance'] && (productsByFamily['Compute Instance']?.find(p => p.selected) || productsByFamily['Compute Instance'][0]))
	}, [suggestion])

	const serverSelectHandler = (e) => {
		console.log(e)
		e.selected = true;
		selectServer.selected = undefined;
		props.onSuggestionChange(e);
		setSelectServer(e)
	};

	return (
		<div>
			{productsByFamily && productsByFamily['Compute Instance'] && productsByFamily['Compute Instance'].map(p => (
				<ServerSuggestionsCard shared={shared} storageProducts={productsByFamily} key={p._id} server={p} serverUpdateHandler={() => props.onSuggestionChange(p)} serverSelectHandler={serverSelectHandler} selectServer={selectServer} />
			))}
			{ productsByFamily && productsByFamily['Unavailable']?.map(p => (
				<ServerSuggestionsCard shared={shared} storageProducts={productsByFamily} key={p._id} server={p} />
			))}
		</div>
	)
}
