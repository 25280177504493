import React from 'react'
import {Card, CardBody, Col, Container, Row,} from 'reactstrap';
import {Route, Switch} from 'react-router-dom';
import ClientData from "./ClientData/ClientData";
import AddProject from "./AddProject/AddProject";
import ReviewAssumptions from "./ReviewAssumptions/ReviewAssumptions";
import ReviewSuggestions from "./ReviewSuggesions/ReviewSuggestions";
import ClientDetails from './ReviewSuggesions/ServerDeatil/ServerDetail'

export default function Index(props) {
	const { pathname } = props.location;

	return (
		<div>
			<Container>
				<Row>
					<Col md={12} lg={12}>
						<Card>
							<CardBody className="wizard review-assumption-card">
								<div className="wizard__steps">
									{/* <div className={`wizard__step ${pathname.includes('/welcome') ? ' wizard__step--active' : ''}`}>
										<p>Welcome</p>
									</div> */}
									<div className={`wizard__step ${pathname.includes('/project/add') ? ' wizard__step--active' : ''}`}>
										<p>Upload Data</p>
									</div>
									<div className={`wizard__step ${pathname.includes('/review-assumptions') ? ' wizard__step--active' : ''}`}>
										<p>Review Assumptions</p></div>
									<div className={`wizard__step ${pathname.includes('/review-suggestions') ? ' wizard__step--active' : ''}`}>
										<p>Review Suggestions</p></div>
								</div>
								<Switch>{console.log(pathname)}
									<Route path="/homepage/client/:id/welcome" exact component={ClientData}/>
									<Route path="/homepage/client/:id/project/add" component={AddProject}/>
									<Route path="/homepage/client/:id/project/review-assumptions" component={ReviewAssumptions}/>
									<Route path="/homepage/client/:id/project/review-suggestions/:suggestionId" exact
									       component={ReviewSuggestions}/>
									<Route path="/homepage/client/:id/project/review-suggestions/:suggestionId/details/:serverId"
									       component={ClientDetails}/>
								</Switch>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
