import React from 'react'
import { Button, Card, CardBody, Col, Input, Table, } from "reactstrap";
import Edit from "mdi-react/EditIcon";
import Delete from "mdi-react/DeleteIcon";
import Dots from "mdi-react/DotsHorizontalCircleIcon";

export default function Scenario() {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <form className="form  ">
                <div className="form__form-group">
                  <div className="row m-0">
                    <div className=" m-0 pr-3">
                      <Input className="table__search table__search-input" placeholder="Search.." value="" />
                    </div>

                    <div>
                      <Button color="primary" className="py-1 px-3 btn btn-primary  account__btn--small" >
                        New Client
											</Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Table responsive hover className="mt-3">
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Email</th>
                <th>company</th>
                <th>Create Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10)].map(p => {
                return (
                  <tr key="1">
                    <th>1</th>
                    <th>test</th>
                    <th>test@gmail.co</th>
                    <th>test</th>
                    <th>test</th>
                    <td>
                      <div className="row m-0 flex-nowrap">
                        <span className="p-2 mr-1" ><Dots /></span>
                        <span className="p-2 mr-1" ><Edit /></span>
                        <span className="p-2 mr-1"><Delete /></span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}
