import React, { useState } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotPassword } from '../../../../api/admin';
import { useDispatch } from 'react-redux';
import { Creators as profileDuck } from '../../../../store/ducks/profile';
import { useHistory } from 'react-router';

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .required('Email is Required')
});

export default function ResetPasswordForm() {
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const submitHandler = (values) => {
    setIsLoadingForgotPassword(true);
    forgotPassword(values).then(res => {
      setIsLoadingForgotPassword(false);
      toast.success('Verification code sent successfully', { position: toast.POSITION.TOP_RIGHT, className: 'toast-success' });
      dispatch(profileDuck.isForgotPassword(true));
      history.push('/confirm_password', { email: values.email })
    }).catch(er => {
      toast.error(er.response.data.error||'Something went wrong', { position: toast.POSITION.TOP_RIGHT })
      dispatch(profileDuck.isForgotPassword(false));
      setIsLoadingForgotPassword(false);
    })
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ email: '' }}
        validationSchema={SignupSchema}
        onSubmit={(values) => { submitHandler(values) }
        }>
        {({ values, errors, touched, handleChange, handleSubmit, dirty, handleBlur, }) => (
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Email Address</span>
              <div className="form__form-group-field flex-wrap">
                <div className="form__form-group-icon">
                  <AlternateEmailIcon />
                </div>
                <Input type="email"
                  invalid={errors.email && touched.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  type="email"
                  style={{ width: 'auto' }}
                  placeholder="example@mail.com"
                  className="input-without-border-radius flex-grow-1" />
                <FormFeedback>
                  {errors.email && touched.email && errors.email}
                </FormFeedback>
              </div>
            </div>
            <Button disabled={isLoadingForgotPassword || !dirty} color="primary" className="btn btn-primary account__btn account__btn--small" onClick={handleSubmit}>
              {isLoadingForgotPassword ? <ClipLoader size={20} color='#4ce1b6' /> : 'Reset Password'}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  )
}
