import React, { useContext, useEffect, useState } from 'react'
import { Collapse, FormGroup, Input, Label } from 'reactstrap'
import Play from "mdi-react/PlayIcon";
import classNames from "classnames";
import { formatCurrency, formatNumber } from "../../../../../utils";
import { ContextProvider } from './ServerDetail';
// import { useSelector } from 'react-redux';

export default function ServerSuggestionsPricing({ subtitle, costs, reservationTerms, pricingModels, paymentOptions, setSelectedCost, selectedCost, pricingType, server, ...props }) {
	const selectedAssumption = useContext(ContextProvider);
	const shared = props.shared
	// console.log(selectedAssumption)
	const [selectedPricingModel, setSelectedPricingModel] = useState(selectedCost?.termAttributes.OfferingClass || selectedCost?.termAttributes);
	const [selectedPaymentOption, setSelectedPaymentOption] = useState(selectedCost?.termAttributes.PurchaseOption);
	const [selectedReservationTerm, setSelectedReservationTerm] = useState(selectedCost?.termAttributes.LeaseContractLength);
	const [filteredCosts, setFilteredCosts] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [checkPricingModal, setCheckPricingModal] = useState();

	// const { assumptions = {} } = useSelector(state => state.suggestions.suggestions)
	// const selectedAssumption = (assumptions[server?._id] || assumptions?.default)?.find(a => a.name === "storage")?.pricingAttributes?.find(p => p.name === "storageGB");
	// console.log(selectedAssumption,server)

	useEffect(() => {
		let filtered = [];
		if (selectedPricingModel === "OnDemand") {
			filtered = costs?.filter(p => p.termAttributes === selectedPricingModel);
		} else {
			filtered = costs?.filter(p => p.termAttributes.OfferingClass === selectedPricingModel)
				.filter(p => p.termAttributes.LeaseContractLength === selectedReservationTerm)
				.filter(p => p.termAttributes.PurchaseOption === selectedPaymentOption)
		}

		setFilteredCosts(filtered);
		if (filtered && filtered[0] && filtered[0] !== selectedCost)
			setSelectedCost(...filtered);
		setCheckPricingModal(selectedReservationTerm === '3yr' && selectedPricingModel !== "OnDemand")
	}, [selectedPricingModel, selectedPaymentOption, selectedReservationTerm, selectedCost]);

	const pricingModelsHandler = ({ target: { value } }) => {
		setSelectedPricingModel(value)
	}
	const reservationTermsHandler = ({ target: { value } }) => {
		setSelectedReservationTerm(value)
	}
	const paymentOptionsHandler = ({ target: { value } }) => {
		setSelectedPaymentOption(value)
	}

	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<hr />
			<span className="form__form-group-label d-flex align-items-center font-weight-bold show-price" onClick={toggle}>
				<Play className={classNames("mr-2 pricing-icon", { "expand": isOpen, })} />
				{subtitle || "Show Calculations"}
			</span>
			<Collapse isOpen={isOpen}>
				<div>
					<div>
						<div className="card pricing-modal  mb-4">
							{!costs ?
								<div className="card-body">
									<div className="row m-0">
										<div className="col-12 text-center">
											Product Pricing Details Not Available
										</div>
									</div>
								</div>
								: <div className="card-body">
									<div className="row m-0">
										<div className="col-12 col-sm-4 col-md-6 col-lg-4 col-4">
											<FormGroup tag="fieldset">
												<h5 className="form__form-group-label">Pricing model</h5>
												{pricingModels?.map(p => {
													return (
														p && <FormGroup check>
															<Label check>
																<Input type="radio" checked={selectedPricingModel === p} value={p}
																	onChange={pricingModelsHandler} disabled={shared} />
																{p}
															</Label>
														</FormGroup>
													)
												})}
												{
													costs?.filter(p => p.termAttributes === "OnDemand").map(p => (
														<FormGroup check>
															<Label check>
																<Input type="radio" value={p.termAttributes}
																	checked={selectedPricingModel === p.termAttributes}
																	onChange={pricingModelsHandler} disabled={shared}  />
																{p.termAttributes}
															</Label>
														</FormGroup>
													))
												}
											</FormGroup>
										</div>
										{selectedPricingModel !== "OnDemand" &&
											<>
												<div className="col-12 col-sm-4 col-md-6 col-lg-4 col-4">
													<FormGroup tag="fieldset">
														<h5 className="form__form-group-label">Reservation term</h5>
														{reservationTerms?.map(p => {
															return (
																p && <FormGroup check>
																	<Label check>
																		<Input type="radio" value={p} checked={selectedReservationTerm === p}
																			onChange={reservationTermsHandler} disabled={shared}  />
																		{p}
																	</Label>
																</FormGroup>
															)
														})}
													</FormGroup>
												</div>
												<div className="col-12 col-sm-4 col-md-6 col-lg-4 col-4">
													<FormGroup tag="fieldset">
														<h5 className="form__form-group-label">Payment options</h5>
														{paymentOptions?.map(p => {
															return (
																p && <FormGroup check>
																	<Label check>
																		<Input type="radio" value={p} checked={selectedPaymentOption === p}
																			onChange={paymentOptionsHandler} disabled={shared} />
																		{p}
																	</Label>
																</FormGroup>
															)
														})}
													</FormGroup>
												</div>
											</>
										}

									</div>

									{filteredCosts?.map(p => {
										return (
											<>
												{p.dimensions.map(x => {
													return (
														<>
															<div className="row m-0 border-bottom py-2 dimensions ">
																<div
																	className="my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 my-xxl-0 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
																	<ul>
																		<li>{x.description}</li>
																	</ul>
																</div>
																<div
																	className="my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 my-xxl-0 col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
																	<span className="pl-3 pl-md-0">
																		{formatCurrency(x.pricePerUnit, 3)}/{x.unit}
																	</span>
																</div>

																{pricingType === "snapshot" &&
																	<div
																		className="my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 my-xxl-0 col-12 col-sm-6 col-md-2 col-lg-3 col-xl-2 text-md-right">
																		<span className="pl-3 pl-md-0">
																			Initial Snapshots
																		<span className="ml-1">{server[selectedAssumption.value]}Gb</span>
																		</span>
																	</div>
																}

																{(pricingType === "storage" || pricingType === "snapshot") &&
																	<div
																		className="my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 my-xxl-0 col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 ">
																		<span className="pl-3 pl-md-0">
																			{pricingType === "storage" && `${server[selectedAssumption.value]}Gb`}
																			{pricingType === "snapshot" && `${formatCurrency(x.initialSnapshotCost)}`}
																		</span>
																	</div>
																}

																{!pricingType &&
																	<div className="offset-sm-3 offset-md-2 offset-lg-3 offset-xl-2"></div>}

																{pricingType !== "snapshot" && <div
																	className={`my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2`}>
																	<span className="pl-3 pl-md-0">
																		{pricingType === "storage" ? formatCurrency(x.monthlyCost) : checkPricingModal ? formatCurrency(x.year3) : formatCurrency(x.year1)}
																	</span>
																	<span className="ml-3">
																		{pricingType === "storage" ? "per month" : checkPricingModal ? 'For 3 Years ' : "For 1 Years "}
																	</span>
																</div>}

																{pricingType === "snapshot" &&
																	<div className="col-12 my-2 my-sm-2 my-md-0 my-lg-0 my-xl-0 mt-3 border-top">
																		<div className="row m-0">
																			<div className="offset-sm-3 offset-md-4 offset-lg-5 offset-xl-4"></div>
																			{pricingType === "snapshot" &&
																				<div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 text-md-right">
																					<span>Daily Change Snapshots
																					<span className="ml-1">
																							{formatNumber(x.dailyChange)}Gb
																					<br />average over 36 month
																					</span>
																					</span>
																				</div>
																			}

																			<div className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 ">
																				{formatCurrency(x.cumulativeCost?.filter((_, i) => i % 2 === 0).reduce((p, c) => p + c, 0) / 36)}
																			</div>
																			<div className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-3">
																				{formatCurrency(x.monthlyCost)} per month ({server.snapshotsPerMonth} snapshots
																			p/m
																			with {server.monthlyDiskGrowthRate * 100}% monthly growth)
																		</div>
																		</div>
																	</div>
																}
															</div>
														</>
													)
												})}

												<div className="row m-0">
													<div className="offset-sm-2 offset-md-4 offset-lg-5 offset-xl-5"></div>
													<div className="col-6 col-sm-4 col-md-4  col-lg-3  col-xl-2  d-flex justify-content-end">
														<span className="font-weight-bold">Total Cost</span>
													</div>
													<div className="col-6 col-sm-6 col-md-4  col-lg-3  col-xl-5 total-cost">
														<span className="font-weight-bold">
															{checkPricingModal ? formatCurrency(p.totalCost3Year) : formatCurrency(p.totalCost1Year)}
														</span>
														<span
															className="ml-0 ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 ">{checkPricingModal ? 'For 3 Years' : "For 1 Years"}</span>
													</div>
												</div>

											</>)
									})}
								</div>}
						</div>
					</div>
				</div>
			</Collapse>

		</>
	)
}
