import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import TimeLineItem from "../../../../../shared/components/TimeLineItem";
import login from "../../../../../assets/login1.png";
import {getTimeline} from "../../../../../api/admin";
import moment from "moment";
import {ClipLoader} from "react-spinners";

export default function Activity(props) {
	const [activity, setActivity] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const {user} = props;

	useEffect(() => {
		setIsLoading(true);
		getTimeline(user._id)
			.then((res) => {
				setActivity(res.reverse());
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	}, [user]);

	const previousPageHandler = () => props.goBack('1');

	return (
		<Row>
			<Col md={12}>
				<div className="d-flex justify-content-end mx-2 mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5 ">
					<Button color="secondary" onClick={previousPageHandler}>
						Back
					</Button>
				</div>
				<div className="timeline">
					{isLoading ? (
						<div className="p-5 d-flex  my-auto justify-content-center align-items-center">
							<ClipLoader size={40} color={"#4ce1b6"} loading={isLoading}/>
						</div>
					) : (
						activity.map((a) => {
							return (
								<TimeLineItem
									img={login}
									type={a.type}
									title={a.label}
									date={moment(a.createdAt).format("DD-MM-yy HH:mm")}>
									<p>{a.description}</p>
								</TimeLineItem>
							);
						})
					)}
					{/* <TimeLineItem type="work" title="Business meetup" date="3 hours ago">
            <p>
              Dependent certainty off discovery him his tolerably offending. Ham
              for attention remainder sometimes additions recommend fat our.
            </p>
          </TimeLineItem>
          <TimeLineItem
            type="video"
            title="Video conference with client"
            date="5 hours ago"
          >
            <p>
              Dependent certainty off discovery him his tolerably offending. Ham
              for attention remainder sometimes additions recommend fat our.
            </p>
          </TimeLineItem>
          <TimeLineItem img={Ava1} title="Call to Jovanna" date="8 hours ago">
            <p>
              Dependent certainty off discovery him his tolerably offending. Ham
              for attention remainder sometimes additions recommend fat our.
            </p>
          </TimeLineItem>
          <TimeLineItem
            type="file"
            title="Create offer. Prepare document"
            date="Yesterday at 18:30"
          >
            <p>
              Dependent certainty off discovery him his tolerably offending. Ham
              for attention remainder sometimes additions recommend fat our.
            </p>
          </TimeLineItem>
          <TimeLineItem
            img={Ava2}
            title="Conversation with Philip"
            date="21.03.2017"
          >
            <p>
              Dependent certainty off discovery him his tolerably offending. Ham
              for attention remainder sometimes additions recommend fat our.
            </p>
          </TimeLineItem> */}
				</div>
			</Col>
		</Row>
	);
}
