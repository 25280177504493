import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../../shared/components/form/text-editor/Panel';


const WeeklyStat = ({ value = 0, label }) => (
	<Panel className="pb-0">
		<div className="dashboard__weekly-stat">
			<div className="dashboard__weekly-stat-chart m-0">
				<div className="dashboard__weekly-stat-chart-item">
					<div className="dashboard__weekly-stat-chart-pie">
						<ResponsiveContainer>
							<PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
								<Pie
									data={[{ value, fill: '#ff4861' },
									{ value: 100 - value, fill: '#eeeeee' }]}
									dataKey="value"
									cx={50}
									cy={50}
									innerRadius={50}
									outerRadius={55}
								/>
							</PieChart>
						</ResponsiveContainer>
						<p className="dashboard__weekly-stat-label" style={{ color: '#ff4861' }}>{label ? <>{label}<sub>/y</sub></> : `${value}%`}</p>
					</div>
				</div>
			</div>
		</div>
	</Panel>
);

WeeklyStat.propTypes = {
	t: PropTypes.func.isRequired,
};

export default (WeeklyStat);
