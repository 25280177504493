import React, {useEffect, useMemo, useState} from "react";
import {Button} from "reactstrap";
import BaseReactTable from "../../../../../shared/components/table/BaseReactTable";
import {Creators as usersDuck} from "../../../../../store/ducks/users";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {ClipLoader} from "react-spinners";
import Avatar from "../../../../../assets/avatar.png";

export default function AssignClient(props) {
	const { users, clientData: clients, isLoadingAssignClient, isLoadingUnAssignClient, isLoading } =
		useSelector((state) => state.clientsData) || {};
	const clientManagers = users.filter((u) => u.role === "ClientManager");
	const assignees = clients?.assignees;
	const dispatch = useDispatch();
	const [clientIndex, setClientIndex] = useState(0);

	console.log(isLoadingAssignClient, isLoadingUnAssignClient);
	const removeClientHandler = (userId, index) => {
		setClientIndex(index);
		dispatch(usersDuck.unAssignClient(userId, { clientId: props.userId }));
	};

	const assignClientHandler = (userId, index) => {
		setClientIndex(index);
		dispatch(usersDuck.assignClient(userId, { clientId: props.userId }));
	};

	useEffect(() => {
		dispatch(usersDuck.getUsers());
	}, []);

	const columns = useMemo(
		() => [
			{ accessor: "id", Header: "ID", width: 80, disableGlobalFilter: true },
			{accessor: "totalProjects", Header: "Projects"},
			{ accessor: "project_managers", Header: "Project Managers" },
			{ accessor: "date_created", Header: "Date Created" },
			{ accessor: "status", Header: "Status" },
			{
				accessor: "manage",
				Header: "",
				disableGlobalFilter: true,
				disableSortBy: true,
				width: 110,
			},
		],
		[]
	);

	const data = clientManagers.map((c, index) => {
		console.log(c)
		return {
			users: [
				<td className="d-flex">
					<img
						src={c.image ? c.image : Avatar}
						className="user-profile-icons"
						alt="UserProfileIcon"
					/>
					<div className="d-flex flex-column">
						<h2 className="user-name-title">{c.name}</h2>
						<h2 className="user-name-description">{c.role}</h2>
					</div>
				</td>,
			],
			id: c._id,
			totalProjects: c.totalProjects || 0,
			project_managers: [
				<td className="overlapping-img">
					<ul>
						<li>
							<img src={c.image ? c.image : Avatar} alt="" title={c?.name} />
						</li>
					</ul>
				</td>,
			],
			date_created: moment(c.createdAt).format("DD-MMM-YYYY"),
			status: c.status,
			manage: [
				assignees.find((a) => a === c._id) ? (
					<Button
						color="primary"
						className="btn btn-primary btn-block px-0 mb-0 w-75"
						onClick={() => removeClientHandler(c._id, index)}
					>
						{isLoadingUnAssignClient && clientIndex === index ? (
							<ClipLoader size={20} color={"#fff"} loading={true} />
						) : (
							"Remove"
						)}
					</Button>
				) : (
					<Button
						color="success"
						className="btn btn-success btn-block px-0 mb-0 w-75"
						onClick={() => assignClientHandler(c._id, index)}
					>
						{isLoadingAssignClient && clientIndex === index ? (
							<ClipLoader size={20} color={"#fff"} loading={true} />
						) : (
							"Assign"
						)}
					</Button>
				),
			],
		};
	});
	const tableConfig = {
		isEditable: false,
		isSortable: true,
		isResizable: false,
		withPagination: true,
		withSearchEngine: false,
		manualPageSize: [10, 20, 30, 40],
		placeholder: "Search...",
	};
	return (
		<div>
			<div className="row m-0">
				<div>
					<h2 className="assign-project-title">
						Assign Client Managers to {clients?.name}
					</h2>
					<h4 className="assign-project-sub-title">
						Assign or remove project managers to the project.
					</h4>
				</div>
			</div>
			<div className="assign-project-table mt-4">
				{isLoading ? (
					<div className="p-5 d-flex  my-auto justify-content-center align-items-center">
						<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
					</div>
				) : (
					<BaseReactTable
						columns={columns}
						data={data}
						tableConfig={tableConfig}
						onRowClick={() => console.log("test")}
					/>
				)}
			</div>
		</div>
	);
}
