import React from 'react'
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'reactstrap';
import WeeklyState from '../WeeklyState';
import {formatCurrency} from "../../../../utils";

export default function StepFourDetail(props) {
	const suggestionsData = props?.suggestionsData;
	const assumptionsData = props?.assumptionsData;
	const clientId = props?.clientId;
	const clientData = props?.clientData;
	const shared = props?.sharedScenario;
	const data = Object.values(suggestionsData)
	const history = useHistory();
	const params = useParams();
	const { suggestionId, id } = params;
	console.log(props.sharedScenario)
	const viewDetailHandler = (suggestion) => {
		if (shared) {
			history.push(`/links/scenarios/${id}/viewDetails`, {
				assumptionsData: assumptionsData[suggestion[0].server._id] || assumptionsData.default,
				clientData,
				suggestion,
				shared
			})
		} else {
			history.push(`/homepage/client/${clientId}/project/review-suggestions/${suggestionId}/details/${suggestion[0].server._id}`, {
				assumptionsData: assumptionsData[suggestion[0].server._id] || assumptionsData.default,
				clientData,
				suggestion
			})
		}
		console.log(assumptionsData)
	};

	return (
		<>
			<div className="containers">
				<>
					{data && data.map(p => {

						const instanceProducts = p.filter(f => f.productFamily === "Compute Instance");
						const selected = instanceProducts.find(i => i.selected) || instanceProducts[0];
						const selectedCost = selected ? selected.costs.find(f => f.selected) || selected.costs[0] : null;
						const highestCost = selected ? selected.costs[selected.costs.length - 1] : null;

						return (
							<>
								{!selected ? '' :
									<>
										<div className="col-12 card shadow mb-3 step-four-card-size">
											<div className="card-body p-3 ">
												<div className="row m-0 d-flex justify-content-between align-items-center">
													<div>
														<h2 className="server-type-title font-weight-bold ">{selected.server.serverName}</h2>
													</div>
													<div>
														<h5 className="text-center">Client Server</h5>
													</div>
												</div>
												<hr className="my-0" />

												<div className="row m-0 mb-3">
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">OS/Version</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.operatingSystemVersion}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Memory</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.memory}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Cores</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.cores}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Storage</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.storageallocated}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">vCPU</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.vCPU}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">IOPS</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.IOPS}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">CPU ClockSpeed</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.cpuGHZ}</span>
													</div>

													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Average CPU</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.avgCPU}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Max CPU</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.peakCPU}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Average Memory</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.avgMemory}</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-label">Max Memory</span>
													</div>
													<div className="col-6 col-sm-3 col-lg-6 col-xl-6">
														<span className="server-instance-name">{selected.server.peakMemory}</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 card shadow mb-3  step-four-card-size">
											<div className="card-body p-3">
												<div className="row m-0 d-flex justify-content-between align-items-center">
													<div>
														<h2 className="server-type-title font-weight-bold">{selected.attributes.instanceType}</h2>
													</div>
													<div>
														<h5 className="text-center">Cloud Server</h5>
													</div>
												</div>
												<hr className="my-0" />
												<div className="row">
													<div className="col-12 col-sm-7 col-md-7 col-lg-12 col-xl-7">
														{selected.productFamily === "Unavailable" ?
															<span className="server-not-available">No server detail available  for this server</span>
															:
															<div className="row m-0 mb-3">
																<div className="col-6">
																	<span className="server-instance-label">OS/Version</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.operatingSystem}</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-label">physicalProcessor:</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.physicalProcessor}</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-label">Memory</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.memory}</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-label">clockSpeed</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.clockSpeed}</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-label">processorFeatures</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.processorFeatures}</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-label">vCPU</span>
																</div>
																<div className="col-6">
																	<span className="server-instance-name">{selected.attributes.vcpu}</span>
																</div>
															</div>


														}
													</div>
													<div className="col-12 col-sm-5 col-md-5 col-lg-12 col-xl-5 ">
														{selectedCost && highestCost && <div className="dashboard weekly-chart">
															<WeeklyState label={`${formatCurrency(selectedCost.totalCost1Year)}`}
																value={(selectedCost.totalCost1Year * 100) / highestCost.totalCost1Year} />
														</div>}
														<div className="d-flex justify-content-center mt-4">
															<Button color="primary" className="tn btn-primary  account__btn--small"
																onClick={() => viewDetailHandler(p)}>View Details</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								}
							</>

						)
					})}
				</>
			</div>
		</>
	)
}
