import React, {useEffect, useMemo, useState} from 'react'
import {Badge, Card, CardBody, Col,} from 'reactstrap';
import UserTabs from './UserTabs/UserTabs';
import TextField from '@material-ui/core/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {Creators as usersDuck} from '../../../../store/ducks/users';
import {ClipLoader} from 'react-spinners';
import {useHistory} from 'react-router';
import BaseReactTable from '../../../../shared/components/table/BaseReactTable';
import moment from "moment";
import {Roles} from '../../../../utils/constant';
import Avatar from '../../../../assets/avatar.png';

const RoleClasses = {
	ClientManager: 'user-personal-badge-green',
	ProjectManager: 'user-personal-badge',
	TenantManager: 'user-personal-badge-yellow'
}
export default function User(props) {
	const tableConfig = {
		isEditable: false,
		isSortable: true,
		isResizable: false,
		withPagination: false,
		withSearchEngine: false,
		manualPageSize: [10, 20, 30, 40],
		placeholder: 'Search...',
	};

	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const { users, } = useSelector(state => state.users) || [];
	const { isLoading, isError } = useSelector(state => state.users);
	const [userSearch, setUserSearch] = useState();
	const [userFilter, setUserFilter] = useState([]);

	const columns = useMemo(() => [
		{ accessor: 'user', Header: 'User', width: 80, disableGlobalFilter: true, },
		{ accessor: 'lastLogin', Header: 'Last Login', },
		{ accessor: 'role', Header: 'Role', disableSortBy: true, disableGlobalFilter: true, },
	], []);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(usersDuck.getUsers());
	}, []);

	useEffect(() => {
		if (!isLoading && !isError && users) {
			const userId = history.location.pathname.split('/').pop()
			const getUserById = users?.find(c => c._id === userId);
			setSelectedUser(getUserById || null);
		}
	}, [history.location.pathname, isLoading]);

	useEffect(() => {
		if (selectedUser && users && users.length) {
			const getUserById = users.find(c => c._id === selectedUser._id);
			setSelectedUser(getUserById);
		}
	}, [users]);

	const handleSetSelectedUser = (u) => {
		console.log(u);
		setSelectedUser(userFilter[u]);
		setSelectedIndex(u);
		history.push(`/users/${userFilter[u]._id}`)
	};

	const data = userFilter.map(u => ({
		user: <td className="d-flex">
			<img src={u.image ? u.image : Avatar} className="user-profile-icons" alt="UserProfileIcon" />
			<div className="d-flex flex-column">
				<h2 className="user-name-title">{u.name}</h2>
				<h2 className="user-name-description">{u.designation}</h2>
			</div>
		</td>,
		lastLogin: <td className="user-tab-date">{moment(u.updatedAt).format('DD-MM-yy HH:mm')}</td>,
		role: [(<td>
			<Badge
				className={RoleClasses[u.role]}>
				{Roles[u.role]}
			</Badge>
		</td>)],
	}));

	const handleSearchChange = (e) => {
		const user = users.filter(team => {
			return team.name.toLowerCase().includes(e.target.value.toLowerCase());
		});
		setUserFilter(user);
		setUserSearch(e.target.value);
	}

	useEffect(() => {
		setUserFilter(users);
	}, [users]);

	return (
		<div>
			<div className="row m-0">
				<div className="col-12 col-xl-4">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body pb-0">
									{isLoading ? <div className="p-5 d-flex justify-content-center align-items-center">
										<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
									</div> : selectedUser ?
										<div className="d-flex user-profile-card  align-items-center align-items-xl-start">
											<div className="mr-2 user-profile-icon">
												<img src={selectedUser.image ? selectedUser.image : Avatar} className="user-profile-icon" alt="user-profile-icon" />
											</div>
											<div className="user-profile-details">
												<h2 className="user-profile-name">{selectedUser.name}</h2>
												<h3 className="user-profile-categories">{selectedUser.designation}</h3>
												<Badge className={`mb-1 ${RoleClasses[selectedUser.role]}`}>{Roles[selectedUser.role]}</Badge>
												<h2 className="user-profile-email">{selectedUser.email}</h2>
												<h2 className="user-profile-phone">{selectedUser.phone}</h2>
											</div>
										</div> :
										<div>
											<h2 className='user-profile-container-title'>Select a User to View <br /> their Profile</h2>
										</div>
									}
									<hr />
									<div className="user-profile-count d-block d-sm-none mb-2">
										<div className="d-flex justify-content-center justify-content-xl-around w-100">
											<div className="mx-1">
												{selectedUser && <h2 className="user-profile-clients">{selectedUser.clients || 0}</h2>}
												<p className="user-profile-clients-name">Clients</p>
											</div>
											<div className="mx-1">
												{selectedUser && <h2 className="user-profile-clients">{selectedUser.totalProjects || 0}</h2>}
												<p className="user-profile-clients-name">Projects</p>
											</div>
										</div>
										<div className="mt-2">
											{selectedUser && <h2
												className="user-profile-clients date-prev">{moment(selectedUser.lastLogin).format('DD-MM-yy HH:mm')}</h2>}
											<p className="user-profile-clients-name">Last Activity</p>
										</div>
									</div>
									<div className="flex-row d-none d-sm-flex justify-content-sm-around mb-2">
										<div className="">
											{selectedUser && <h2 className="user-profile-clients">{selectedUser.clients || 0}</h2>}
											<p className="user-profile-clients-name">Clients</p>
										</div>
										<div className="">
											{selectedUser && <h2 className="user-profile-clients">{selectedUser.totalProjects || 0}</h2>}
											<p className="user-profile-clients-name">Projects</p>
										</div>
										{console.log(selectedUser)}
										<div className="">
											{selectedUser && <h2
												className="user-profile-clients date-prev">{moment(selectedUser.lastLogin).format('DD-MM-yy HH:mm')}</h2>}
											<p className="user-profile-clients-name">Last Activity</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 mt-3">
							<div className="card">
								<div className="card-body p-0">
									<Col md={12} lg={12}>
										<Card>
											<CardBody className="p-0">
												<div className="ml-auto w-25">
													<form className="material-form mb-0">
														<TextField
															className="material-form__field mb-0"
															placeholder="Search"
															type="text"
															label="Search"
															name="email"
															onChange={handleSearchChange}
														/>
													</form>
												</div>
												{!isLoading &&
													<BaseReactTable
														columns={columns}
														data={data}
														isSelected={selectedIndex}
														tableConfig={tableConfig}
														onRowClick={(e, i) => handleSetSelectedUser(i)}
													/>}
												{users && !users.length && !isLoading &&
													<div className="d-flex justify-content-center">
														<h1 className="mt-4 noresult-found-title">Record Not Found</h1>
													</div>
												}
												{isError &&
													<div className="d-flex justify-content-center">
														<h1 className="mt-4 noresult-found-title">Network error</h1>
													</div>
												}

												{isLoading && <div className="p-5 d-flex justify-content-center align-items-center">
													<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
												</div>}
											</CardBody>
										</Card>
									</Col>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-xl-8 mt-3 mt-xl-0">
					<div className="card">
						<div className="card-body p-0">
							{isLoading ? <div className="p-5 d-flex my-auto h-100 justify-content-center align-items-center">
								<ClipLoader size={40} color={"#4ce1b6"} loading={true} />
							</div> : selectedUser && <UserTabs user={selectedUser} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
