import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Creators as clientDuck } from "../../../../store/ducks/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { forgotPassword, getUploadUrl } from "../../../../api/admin";
import UploadIcon from "mdi-react/EditOutlineIcon";
import { getExtension } from "../../../../utils";
import Avatar from "../../../../assets/avatar.png";

function Setting(props) {
  let myref = useRef();
  const { client } = props;
  const [initialState, setInitialState] = useState({});
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const { isUpdateLoading, isUpdateError } = useSelector(
    (state) => state.clients
  );
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageLoading, setSelectedImageLoading] = useState(false);
  const dispatch = useDispatch();

  console.log(client)

  useEffect(() => {
    if (!isUpdateLoading && isUpdateError) {
      toast.error(isUpdateError.response.data.error || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(clientDuck.clearUpdateError());
    }
  }, [isUpdateError, isUpdateLoading]);

  useEffect(() => {
    setInitialState({
      name: client.name,
      company: client.company || "",
      email: client.email,
      clientContact: client.clientContact,
      phoneNumber: client.phoneNumber,
      image: client.image,
      _id: client._id,
    });
  }, [client]);

  const handleSubmitData = (values) => {
    if (selectedImage) {
      setSelectedImageLoading(true);
      getUploadUrl({
        name: `profiles/${selectedImage.file.name}`,
        ACL: 'public-read', type: selectedImage.file.type,
      })
        .then(async (res) => {
          await uploadUrl(res.uploadURL, selectedImage.file);
          dispatch(
            clientDuck.updateClient(client._id, {
              ...values,
              image: `https://nextgenoptima.s3-ap-southeast-2.amazonaws.com/profiles/${selectedImage.file.name}`,
            })
          );
          setSelectedImageLoading(false);
        })
        .catch((er) => {
          setSelectedImageLoading(false);
          console.log(er);
        });
    } else {
      dispatch(clientDuck.updateClient(client._id, values));
    }
  };

  const uploadUrl = (url, file) =>
    fetch(url, {
      method: "PUT",
      body: new Blob([file], { type: file.type }),
    });

  const forgotPasswordHandler = () => {
    setIsLoadingForgotPassword(true);
    forgotPassword({ email: client.email })
      .then((res) => {
        toast.success("Email sent successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-success",
        });
        setIsLoadingForgotPassword(false);
      })
      .catch((er) => {
        toast.error(er.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoadingForgotPassword(false);
      });
  };

  const handleBrowse = function (e) {
    e.preventDefault();
    myref.click();
  };

  const onFileSelected = (e, setFieldValue) => {
    const files = e.target.files;
    for (let file of files) {
      if (["jpeg", "jpg", "png"].includes(getExtension(file.name))) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setFieldValue("image", reader.result);
          setSelectedImage({ file });
        });
        reader.readAsDataURL(file);
      } else {
        toast.error("Please Choose Valid File Format", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    e.target.value = "";
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={SignUpSchema}
      onSubmit={(values) => {
        handleSubmitData(values);
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        dirty,
      }) => (
        <form className="material-form" onSubmit={handleSubmit}>
          <div className="my-1 mb-3 position-relative upload-profile-picture-container">
            <img
              className="user-profile-picture"
              src={values.image ? values.image : Avatar}
              alt="user-picture"
            />
            <input
              type="file"
              name="image"
              accept=".jpeg, .jpg, .png"
              hidden
              id="exampleFile"
              ref={(r) => {
                myref = r;
              }}
              onChange={(e) => onFileSelected(e, setFieldValue)}
            />
            <div class="upload-profile-picture-middle">
              <Button
                onClick={handleBrowse}
                type="button"
                className="upload-profile-picture-btn"
              >
                <UploadIcon className="p-0 m-0 upload-icon" />
              </Button>
            </div>
          </div>
          <div>
            <TextField
              className="material-form__field"
              type="text"
              label="Company"
              placeholder="Larry Boom"
              name="company"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company}
              invalid={touched.company && errors.company}
            />
            {touched.company && errors.company && (
              <span className="form__form-group-error mb-2">
                {touched.company && errors.company}
              </span>
            )}
          </div>
          <div>
            <TextField
              className="material-form__field"
              placeholder="example@mail.com"
              type="email"
              label="Email"
              name="email"
              value={values.email}
            />
          </div>
          <div>
            <TextField
              className="material-form__field"
              label="Client Contact"
              name="clientContact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.clientContact}
              invalid={touched.clientContact && errors.clientContact}
            />
            {touched.clientContact && errors.clientContact && (
              <span className="form__form-group-error mb-2">
                {touched.clientContact && errors.clientContact}
              </span>
            )}
          </div>
          <div>
            <TextField
              className="material-form__field"
              label="Mobile Number"
              name="phoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              invalid={touched.phoneNumber && errors.phoneNumber}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <span className="form__form-group-error mb-2">
                {touched.phoneNumber && errors.phoneNumber}
              </span>
            )}
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <div className="col-12 p-0">
              <Button
                color="primary"
                disabled={
                  isUpdateLoading ||
                  isLoadingForgotPassword ||
                  selectedImageLoading
                }
                onClick={forgotPasswordHandler}
              >
                {isLoadingForgotPassword ? (
                  <ClipLoader size={20} color={"#4ce1b6"} />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
            <Button
              disabled={
                !dirty ||
                isUpdateLoading ||
                isLoadingForgotPassword ||
                selectedImageLoading
              }
              color="primary"
              type="submit"
            >
              {isUpdateLoading || selectedImageLoading ? (
                <ClipLoader size={20} color={"#4ce1b6"} />
              ) : (
                "Update profile"
              )}
            </Button>
          </ButtonToolbar>
        </form>
      )}
    </Formik>
  );
}

export default Setting;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignUpSchema = Yup.object().shape({
  company: Yup.string().required("Requireds"),
  email: Yup.string().email("Invalid email").required("Required"),
  clientContact: Yup.string().required("Requireds"),
  phoneNumber: Yup.string()
    .required("required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
});
