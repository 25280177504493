import React from 'react';
import {NavLink} from 'react-router-dom';
import ResetPasswordForm from '../components/reset_password/ResetPasswordForm';
import logoImage from "../../../assets/logo.png";

const ResetPassword = (props) => {

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card shadow">
          <div className="account__head">
            <h3 className="account__title">
              <img className="topbar__logo__image" src={logoImage} alt="" />
            </h3>
            <h4 className="account__subhead subhead">Password Reset</h4>
          </div>
          <ResetPasswordForm />
          <div className="account__have-account">
            <p>Already have a code? <NavLink to="/confirm_password">Confirm Code</NavLink></p>
          </div>
          <div className="account__have-account">
            <p>Remember your password? <NavLink to="/login">Login</NavLink></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
