import React from 'react'
import logoImage from "../../../assets/logo.png";
import OtpVerifications from '../components/otp_verification/otp_verification'
export default function OtpVerification() {
    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card shadow">
                    <div className="account__head">
                        <h3 className="account__title">
                            <img className="topbar__logo__image" src={logoImage} alt="" />
                        </h3>
                        <h4 className="account__subhead subhead">OTP Verification</h4>
                    </div>
                    <OtpVerifications />
                </div>
            </div>
        </div>
    )
}
