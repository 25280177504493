import React, {useEffect, useState} from 'react'
import {
	Button,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	PopoverBody,
	UncontrolledPopover
} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {exportScenario, getScenario, saveScenario} from "../../../../api/admin";
import {ClipLoader} from "react-spinners";
import {groupBy} from "../../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {Creators as suggestionsDuck} from "../../../../store/ducks/suggestions";
import {Creators as enumsDuck} from "../../../../store/ducks/enums";
import {Creators as scenarioLinkDuck} from "../../../../store/ducks/ScenarioLink";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from 'yup';
import {Formik} from 'formik';
import TextField from '@material-ui/core/TextField';
import ScenarioTab from '../../ShareScenario/ScenarioTab';
import ShareScenario from './ShareScenario';

const SignUpSchema = Yup.object().shape({
	label: Yup.string().required("Label is required"),
	description: Yup.string().required("Description is required"),
});
export default function ReviewSuggestions(props) {
	const { suggestions, isLoading } = useSelector(state => state.suggestions);
	const { scenarioLink, isLoadingRefreshScenarioLink, isLoadingRefreshScenarioLinkCode } = useSelector(state => state.scenarioLink);
	const { enums, isLoading: enumsLoading } = useSelector(state => state.enums);
	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
	const clientData = props.location?.state.clientData;
	const { id: clientId, suggestionId } = props.match?.params;
	const [shareModal, setShareModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [suggestionsData, setSuggestions] = useState({ data: [], isLoading: false });
	const [assumptions, setAssumptions] = useState();
	const [formData, setFormData] = useState({ label: "", description: "" });
	const [formDetail, setFormDetail] = useState({ label: "", description: "" });
	const [popoverOpen, setPopoverOpen] = useState({ popoverLabel: false, popoverDescription: false });
	const [modal, setModal] = useState(false);
	const [saveScenarioModal, setSaveScenarioModal] = useState(false);
	const [scenarioExportType, setScenarioExportType] = useState(null);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const exportToggle = () => setDropdownOpen(prevState => !prevState);

	useEffect(() => {
		if (suggestions) {
			processSuggestions(suggestions);
		} else {
			setIsLoadingSuggestions(true);
			getScenario(suggestionId, clientData._id, clientId).then(({ data: { scenarios: suggestions } }) => {
				// processSuggestions(suggestions);
				setIsLoadingSuggestions(false);
				dispatch(suggestionsDuck.getSuggestionDataSuccess(suggestions));
			}).catch(er => {
				console.log(er);
				setIsLoadingSuggestions(false);
			});
		}
	}, [suggestions]);

	useEffect(() => {
		if (!enums) {
			dispatch(enumsDuck.getEnums({ names: [] }))
		}
	}, []);

	useEffect(() => {
		if (suggestions?._id) {
			dispatch(scenarioLinkDuck.getScenarioLink(suggestions?._id, clientData._id, clientId))
		}
	}, [suggestions?._id]);


	const toggles = (name) => {
		setPopoverOpen(prev => ({ ...prev, [name]: !popoverOpen[name] }));
		setFormDetail(formData)
	}


	const submitAssumptionHandler = (assumptions) => {
		const serverIds = clientData.rows.map(p => p._id);
		dispatch(suggestionsDuck.getSuggestions({ serverIds, assumptions: assumptions }, suggestions));
		setAssumptions(p => ({ ...p, default: assumptions }));
	};

	const processSuggestions = suggestions => {
		setFormData({ _id: suggestions._id, label: suggestions.label, description: suggestions.description });
		const labels = [];
		const data = [];
		for (let products of Object.values(suggestions.products)) {
			const productsByFamily = groupBy(products, 'productFamily');
			Object.keys(productsByFamily).forEach(l => {
				const index = labels.findIndex(p => p === l);
				const selectedServer = productsByFamily[l].find(p => p.selected) || productsByFamily[l][0];
				const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
				if (index >= 0) {
					data[index] += cost?.totalCost1Year || 0;
				} else {
					labels.push(l);
					data.push(cost?.totalCost1Year || 0);
				}
			});
		}
		const productsLabels = [];
		const totalCost = Object.values(suggestions.products).reduce((p, c) => {
			const productsByFamily = groupBy(c, 'productFamily');
			let total = p;
			Object.keys(productsByFamily).forEach(l => {
				productsLabels.push(l)
				const index = productsLabels.findIndex(p => p === l);
				const selectedServer = productsByFamily[l].find(p => p.selected) || productsByFamily[l][0];
				const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
				if (!selectedServer) total += 0;
				if (!cost) total += 0;
				if (index >= 0) {
					total += cost?.totalCost1Year || 0;
				}
			})
			return total;
			// const selectedServer = c.find(p => p.selected) || c[0];
			// if (!selectedServer) return p + 0;
			// const cost = selectedServer.costs.find(c => c.selected) || selectedServer.costs[0];
			// if (!cost) return p + 0;
			// return cost.totalCost1Year + p;
		}, 0);
		setSuggestions({
			data: suggestions.products,
			totalCost: totalCost,
			isLoading: false,
			chartData: { labels, data }
		});
		setAssumptions(suggestions.assumptions);
	}

	const previousPageHandler = () => history.goBack();

	const handleSaveScenario = (type, values) => {
		if (type || values) {
			if (suggestions?._id && (type === "xlsx" || type === "csv")) {
				handleExportScenario(type);
				setExportLoading(true);
				return;
			} else if (!scenarioExportType && (type === "xlsx" || type === "csv")) {
				setSaveScenarioModal(true);
				setScenarioExportType(type)
				return;
			}
			values.createdAt = new Date();
			setLoading(true);
			if (suggestions.history) suggestions.history = [values, ...suggestions.history];
			else suggestions.history = [values];

			saveScenario({
				scenario: {
					...formData,
					assumptions,
					history: suggestions.history,
					products: suggestionsData.data,
					totalCost: suggestionsData.totalCost,
					manualSearch: suggestions?.manualSearch,
				},
				id: clientId,
				dataId: clientData._id,
				changeLog: values,
			})
				.then((res) => {
					setLoading(false);
					toast.success("Scenario saved successfully !", {
						position: toast.POSITION.TOP_RIGHT,
						className: "toast-success",
					});
					dispatch(
						suggestionsDuck.getSuggestionDataSuccess({
							...suggestions,
							...formData,
							...res,
						})
					);
					if (type) handleExportScenario(type, res._id);
					setScenarioExportType(null)
				})
				.catch((err) => {
					setLoading(false);
					setExportLoading(false);
					setScenarioExportType(null)
					toast.error(err.message, { position: toast.POSITION.TOP_RIGHT });
				});
		} else {
			setSaveScenarioModal(true);
		}
	};

	const handleExportScenario = (type, sid) => {
		const id = suggestions._id || sid || suggestionId;
		setExportLoading(true);
		exportScenario(id, clientData._id, clientId, type)
			.then((res) => {
				console.log(res);
				setExportLoading(false);
				window.open(res, "_self");
			})
			.catch((er) => {
				setExportLoading(false);
				toast.error(er.message, { position: toast.POSITION.TOP_RIGHT });
			});
	};


	const formHandler = (e) => setFormDetail({ ...formDetail, [e.target.name]: e.target.value });

	const formDataHandler = (name) => {
		setFormData(formDetail);
		setPopoverOpen(prev => ({ ...prev, [name]: !popoverOpen[name] }));
	}

	const saveScenarioModalHandler = () => {
		setSaveScenarioModal(!saveScenarioModal)
		setScenarioExportType(null)
	}
	const saveScenarioHandler = (values) => {
		handleSaveScenario(scenarioExportType ? scenarioExportType : '', values)
		setSaveScenarioModal(false)
	}

	const historyHandler = () => {
		history.push('/history', suggestions.history)
	}

	const linkShareHandler = () => {
		setShareModal(!shareModal)
	}

	const exportModalToggle = () => setModal(!modal);

	return isLoadingSuggestions || enumsLoading ? (<div className="d-flex justify-content-center align-items-center mt-5">
		<ClipLoader size={50} color={"#4ce1b6"} loading={isLoadingSuggestions || enumsLoading} />
	</div>) :
		(<div className="mt-5">
			<div className="link-share-modal">
				<ShareScenario
					linkShareHandler={linkShareHandler}
					dataId={clientData?._id}
					shareModal={shareModal}
					clientId={clientId} scenarioId={suggestions?._id}
				/>
			</div>
			<Modal isOpen={saveScenarioModal} toggle={saveScenarioModalHandler}>
				<ModalHeader toggle={saveScenarioModalHandler}>SAVE SCENARIO EDITS
					<FormText color="muted">Before Saving describe the edits completed</FormText>
				</ModalHeader>
				<ModalBody>
					<Formik
						enableReinitialize
						initialValues={{ label: '', description: '' }}
						validationSchema={SignUpSchema}
						onSubmit={(values) => {
							saveScenarioHandler(values);
						}}
					>{({
						values,
						errors,
						setFieldValue,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
						dirty
					}) => (
						<form className="material-form" onSubmit={handleSubmit}>
							<div>
								<TextField
									className="material-form__field"
									type="text"
									label="Change Name"
									placeholder="Change Name"
									name="label" onChange={handleChange} onBlur={handleBlur} value={values.label}
									invalid={touched.label && errors.label} />
								{touched.label && errors.label &&
									<span className="form__form-group-error mb-2">{touched.label && errors.label}</span>}
							</div>
							<div>
								<TextField
									className="material-form__field"
									type="text"
									label="Add description of the change"
									placeholder="Add description of the change"
									name="description" onChange={handleChange} onBlur={handleBlur} value={values.description}
									invalid={touched.description && errors.description} />
								{touched.description && errors.description &&
									<span className="form__form-group-error mb-2">{touched.description && errors.description}</span>}
							</div>
							<ModalFooter className="d-flex justify-content-start">
								<Button color="primary" type="submit">Submit</Button>{' '}
								<Button color="secondary" onClick={saveScenarioModalHandler}>Cancel</Button>
							</ModalFooter>
						</form>
					)}
					</Formik>
				</ModalBody>

			</Modal>
			<Modal isOpen={modal} toggle={exportModalToggle}>
				<ModalHeader toggle={exportModalToggle}>Choose File Extension</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-6">
							<Button color="primary"
								className="btn btn-block btn-primary account__btn--small px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4">
								xls
							</Button>
						</div>
						<div className="col-6">
							<Button color="primary"
								className="btn btn-block btn-primary account__btn--small px-3 px-sm-3 px-md-4 px-lg-4 px-xl-4">
								csv
							</Button>
						</div>
					</div>
				</ModalBody>
			</Modal>
			<UncontrolledPopover placement="bottom" isOpen={popoverOpen.popoverLabel} target="Popover1"
				toggle={() => toggles('popoverLabel')}>
				<PopoverBody>
					<div className="row">
						<div className="col-12">
							<FormGroup>
								<Label for="exampleEmail">Label</Label>
								<Input type="text" name="label" value={formDetail.label} placeholder="Enter Label"
									onChange={formHandler} />
							</FormGroup>
						</div>
						<div className="col-12 d-flex justify-content-center">
							<Button color="secondary" onClick={() => toggles('popoverLabel')}>
								Cancel
							</Button>
							<Button color="primary" onClick={() => formDataHandler('popoverLabel')}>
								Save
							</Button>
						</div>
					</div>
				</PopoverBody>
			</UncontrolledPopover>
			<UncontrolledPopover placement="bottom" isOpen={popoverOpen.popoverDescription} target="Popover2"
				toggle={() => toggles('popoverDescription')}>
				<PopoverBody>
					<div className="row">
						<div className="col-12">
							<FormGroup>
								<Label for="exampleEmail">Description</Label>
								<Input type="textarea" name="description" value={formDetail.description} onChange={formHandler} />
							</FormGroup>
						</div>
						<div className="col-12 d-flex justify-content-center">
							<Button color="secondary" onClick={() => toggles('popoverDescription')}>
								Cancel
							</Button>
							<Button color="primary" onClick={() => formDataHandler('popoverDescription')}>
								Save
							</Button>
						</div>
					</div>
				</PopoverBody>
			</UncontrolledPopover>
			<ScenarioTab editable={true}
				linkShareHandler={linkShareHandler}
				historyHandler={historyHandler}
				handleSaveScenario={handleSaveScenario}
				submitAssumptionHandler={submitAssumptionHandler}
				assumptions={assumptions}
				toggles={toggles}
				formData={formData}
				suggestionsData={suggestionsData}
				clientId={clientId}
				loading={loading}
				exportLoading={exportLoading}
				dropdownOpen={dropdownOpen}
				exportToggle={exportToggle}
				clientData={clientData}
				previousPageHandler={previousPageHandler}
			/>
		</div>);
}
