import React, { useEffect, useState } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import VerifiedUserIcon from 'mdi-react/VerifiedUserIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmPassword } from '../../../../api/admin';
import logoImage from "../../../../assets/logo.png";
import { useHistory } from 'react-router';

const SignupSchema = Yup.object().shape({
	email: Yup.string()
		.email('Invalid Email')
		.required('Email is required'),
	code: Yup.string()
		.required('Verification code is required'),
	password: Yup.string()
		.test('password', 'Password must be 8 characters and it should contain at least one digit and one special symbol', (value = '') => value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/))
		.required('Password is required')

});

export default function PasswordConfirmation() {
	const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
	const history = useHistory();
	const [state, setState] = useState({ email: '', code: '', password: '', emailDisabled: false });
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const { email = '' } = history.location?.state || {};
		if (email) {
			setState(p => ({ ...p, email, emailDisabled: true }));
		}
	}, []);

	const submitHandler = (values) => {
		setIsLoadingForgotPassword(true)
		confirmPassword({ ...values, code: values.code.toString() }).then(res => {
			setIsLoadingForgotPassword(false)
			toast.success('Password Changed Successfully', { position: toast.POSITION.TOP_RIGHT, className: 'toast-success' });
			history.replace('login');
		}).catch(er => {
			setIsLoadingForgotPassword(false)
			toast.error(er.response.data.error || 'Something went wrong please try again', { position: toast.POSITION.TOP_RIGHT });
		})
	}

	const showPasswordHandler = (e) => {
		e.preventDefault();
		setShowPassword(prevState => !prevState);
	};

	return (
		<div className="account account--not-photo">
			<div className="account__wrapper">
				<div className="account__card shadow">
					<div className="account__head">
						<h3 className="account__title">
							<img className="topbar__logo__image" src={logoImage} alt="" />
						</h3>
						<h4 className="account__subhead subhead">Password Confirmation</h4>
					</div>
					<Formik
						enableReinitialize
						initialValues={state}
						validationSchema={SignupSchema}
						onSubmit={(values) => {
							submitHandler(values)
						}
						}>
						{({ values, errors, touched, handleChange, handleSubmit, dirty, handleBlur, }) => (
							<form className="form">
								<div className="form__form-group">
									<span className="form__form-group-label">Email Address</span>
									<div className="form__form-group-field flex-wrap">
										<div className="form__form-group-icon">
											<AlternateEmailIcon />
										</div>
										<Input
											readOnly={state.emailDisabled}
											invalid={errors.email && touched.email}
											onChange={handleChange('email')}
											onBlur={handleBlur('email')}
											value={values.email}
											style={{ width: 'auto' }}
											className="input-without-border-radius flex-grow-1" />
										<FormFeedback>
											{errors.email && touched.email && errors.email}
										</FormFeedback>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Verification Code</span>
									<div className="form__form-group-field flex-wrap">
										<div className="form__form-group-icon">
											<VerifiedUserIcon />
										</div>
										<Input type="code"
											invalid={errors.code && touched.code}
											onChange={handleChange('code')}
											onBlur={handleBlur('code')}
											value={values.code}
											type="number"
											style={{ width: 'auto' }}
											placeholder="1234"
											className="input-without-border-radius flex-grow-1" />
										<FormFeedback>
											{errors.code && touched.code && errors.code}
										</FormFeedback>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Password</span>
									<div className="form__form-group-field flex-wrap">
										<div className="form__form-group-icon">
											<KeyVariantIcon />
										</div>
										<Input
											invalid={errors.password && touched.password}
											onChange={handleChange('password')}
											onBlur={handleBlur('password')}
											value={values.password}
											type={showPassword ? 'text' : 'password'}
											style={{ width: 'auto' }}
											placeholder="********"
											className="input-without-border-radius flex-grow-1" />
										<button
											className={`form__form-group-button d-flex ${showPassword ? ' active' : ''}`}
											onClick={e => showPasswordHandler(e)}
											type="button"
										><EyeIcon />
										</button>
										<FormFeedback>
											{errors.password && touched.password && errors.password}
										</FormFeedback>
									</div>
								</div>
								<Button disabled={isLoadingForgotPassword || !dirty} color="primary"
									className="btn btn-primary account__btn account__btn--small" onClick={handleSubmit}>
									{isLoadingForgotPassword ? <ClipLoader size={20} color='#4ce1b6' /> : 'Reset Password'}
								</Button>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}
