import React from 'react'
import {Table,} from "reactstrap";
import Eye from "mdi-react/EyeIcon";
import Comment from "mdi-react/CommentProcessingIcon";
import {useHistory, useParams} from 'react-router-dom';
import {formatCurrency, groupBy} from "../../../../utils";

export default function StepFourSummery(props) {
	const shared = props.sharedScenario;
	const suggestionsData = props?.suggestionsData;
	const assumptionsData = props?.assumptionsData;
	const history = useHistory();
	const params = useParams();
	const clientId = history.location.state?.clientId || params.id;
	const { suggestionId } = params;
	const clientData = props?.clientData;

	const serverDetailHandler = (suggestion) => {
		history.push(`/homepage/client/${clientId}/project/review-suggestions/${suggestionId}/details/${suggestion[0].server._id}`, {
			assumptionsData: assumptionsData[suggestion[0].server._id] || assumptionsData.default,
			clientId,
			clientData,
			suggestion,
			shared
		});
	};

	let data = Object.values(suggestionsData);
	return (
		<div className="summery-tale">
			<Table responsive hover className="mt-3">
				<thead>
					<tr>
						<th>Client Server</th>
						<th className="pl-2">vCPU</th>
						<th>Memory</th>
						<th>CPU Ghz</th>
						<th className="pr-2">OS</th>
						<th className="pl-2">Instance Name</th>
						<th className="pl-2">vCPU</th>
						<th>Memory</th>
						<th>CPU Ghz</th>
						<th>OS</th>
						<th>Cost</th>
						<th className="px-2">SnapShot Cost</th>
						<th className="px-2">Storage Cost</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{data && data.map((p) => {
						const productsByFamily = groupBy(p, 'productFamily');
						const selectedInstance = productsByFamily['Compute Instance'] && (productsByFamily['Compute Instance']?.find(p => p.selected) || productsByFamily['Compute Instance'][0]);
						const selectedInstanceCost = selectedInstance?.costs.find(p => p.selected) || selectedInstance?.costs[0];
						const selectedStorage = productsByFamily['Storage'] && (productsByFamily['Storage'].find(p => p.selected) || productsByFamily['Storage'][0]);
						const selectedSnapshotStorage = productsByFamily['Storage Snapshot'] && (productsByFamily['Storage Snapshot'].find(p => p.selected) || productsByFamily['Storage Snapshot'][0]);
						const storageCost = selectedStorage?.costs.find(p => p.selected) || selectedStorage?.costs[0];
						const snapshotCost = selectedSnapshotStorage?.costs.find(p => p.selected) || selectedSnapshotStorage?.costs[0];;
						return (
							<>
								{
									<tr>
										<td>{selectedInstance?.server.serverName || '-'}</td>
										<td className="pl-2">{selectedInstance?.server.vCPU || '-'}</td>
										<td>{selectedInstance?.server.memory || '-'}</td>
										<td>{selectedInstance?.server.cpuGHZ || '-'}</td>
										<td className="pr-2">{selectedInstance?.server.operatingSystem || '-'}</td>
										<td className="pl-2">{selectedInstance?.attributes.instanceType || '-'}</td>
										<td >{selectedInstance?.attributes.vcpu || '-'}</td>
										<td>{selectedInstance?.attributes.memory || '-'}</td>
										<td>{selectedInstance?.attributes.clockSpeed || '-'}</td>
										<td>{selectedInstance?.attributes.operatingSystem || '-'}</td>
										<td> {selectedInstanceCost ? <span>{formatCurrency(selectedInstanceCost.totalCost1Year)}<sub>/y</sub></span> : '-'}</td>
										<td className="px-2"> {snapshotCost ? <span>{formatCurrency(snapshotCost.totalCost1Year)}<sub>/y</sub></span> : '-'}</td>
										<td className="px-2">{storageCost ? <span>{formatCurrency(storageCost.totalCost1Year)}<sub>/y</sub></span> : '-'}</td>
										<td>
											{props.editable && <span className="p-2 mr-1"><Comment /></span>}
											<span className="p-2 mr-1" onClick={() => serverDetailHandler(p)}><Eye /></span>
										</td>
									</tr>
								}
							</>
						)
					})}
				</tbody>
			</Table>
		</div >
	)
}
