import { createActions, createReducer } from "reduxsauce";
import { submitAssumptions, submitManualSearchQuery } from "../../api/admin";

const getSuggestions = (data, suggestions = {}) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingSuggestionData());
		submitAssumptions(data).then((products) => {
			dispatch(Creators.getSuggestionDataSuccess({ ...suggestions, products, assumptions: { default: data.assumptions } }));
		}).catch((error) => {
			dispatch(Creators.getSuggestionDataError(error));
		});
	};
};

const applyManualSearch = (data, tree) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingApplyManualSearch());
		submitManualSearchQuery(data).then((response) => {
			dispatch(Creators.applyManualSearchSuccess(response, { id: data.serverIds, tree }));
		}).catch((error) => {
			dispatch(Creators.applyManualSearchError(error, { id: data.serverIds, tree }));
		});
	};
};

export const { Types, Creators } = createActions({
	getSuggestions,
	applyManualSearch,
	getSuggestionDataSuccess: ['suggestion'],
	applyManualSearchSuccess: ['suggestion', 'tree'],
	getSuggestionDataError: ['error'],
	applyManualSearchError: ['error', 'tree'],
	isLoadingSuggestionData: [],
	isLoadingApplyManualSearch: [],
});

const initialState = {
	suggestions: null,
	isError: false,
	isLoading: false,
};

const getSuggestionDataSuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: action.suggestion,
		isLoading: false,
		isError: '',
	};
};

const getSuggestionDataError = (state = initialState, action) => {
	return {
		...state,
		suggestions: null,
		isLoading: false,
		isError: action.error,
	};
};

const isLoadingSuggestionData = (state = initialState, action) => {
	return {
		...state,
		isLoading: true,
		isError: '',
	};
};

const applyManualSearchSuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			...state.suggestions,
			manualSearch: {
				...state.suggestions?.manualSearch,
				[action.tree.id]: action.tree.tree
			},
			products: {
				...state.suggestions?.products,
				[action.tree.id]: action.suggestion[action.tree.id]
			}
		},
		isLoadingManualSearch: false,
		isErrorManualSearch: '',
	};
};

const applyManualSearchError = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			...state.suggestions,
			manualSearch: {
				...state.suggestions?.manualSearch,
				[action.tree.id]: action.tree.tree
			},
		},
		isLoadingManualSearch: false,
		isErrorManualSearch: action.error,
	};
};

const isLoadingApplyManualSearch = (state = initialState, action) => {
	return {
		...state,
		isLoadingManualSearch: true,
		isErrorManualSearch: '',
	};
};


export default createReducer(initialState, {
	[Types.GET_SUGGESTIONS]: getSuggestions,
	[Types.GET_SUGGESTION_DATA_SUCCESS]: getSuggestionDataSuccess,
	[Types.GET_SUGGESTION_DATA_ERROR]: getSuggestionDataError,
	[Types.IS_LOADING_SUGGESTION_DATA]: isLoadingSuggestionData,
	[Types.APPLY_MANUAL_SEARCH]: applyManualSearch,
	[Types.APPLY_MANUAL_SEARCH_SUCCESS]: applyManualSearchSuccess,
	[Types.APPLY_MANUAL_SEARCH_ERROR]: applyManualSearchError,
	[Types.IS_LOADING_APPLY_MANUAL_SEARCH]: isLoadingApplyManualSearch,
});
