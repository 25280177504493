import {createActions, createReducer} from "reduxsauce";
import {
	deleteProject as deleteProjectApi,
	deleteScenario as deleteScenarioApi,
	getClientData as clientDataApi,
	updateProject as updateProjectApi
} from "../../api/admin";
import {assignProjects, unAssignProjects} from '../../api/admin/index';

const getClientsData = (data, pm) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingClientData());
		clientDataApi(data, pm).then((response) => {
			dispatch(Creators.getClientsDataSuccess(response));
		}).catch((error) => {
			dispatch(Creators.getClientsDataError(error));
		});
	};
}

//Delete client project
const deleteProject = (data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingDeleteClient());
		deleteProjectApi(data).then((response) => {

			dispatch(Creators.deleteProjectSuccess(data));
		}).catch((error) => {
			dispatch(Creators.deleteProjectError(error));
		});
	};
}
const updateProject = (clientId, data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingUpdateProject());
		updateProjectApi(clientId, data).then((response) => {
			dispatch(Creators.updateProjectSuccess(data));
		}).catch((error) => {
			dispatch(Creators.updateProjectError(error));
		});
	};
}
//Delete client project scenarios
const deleteScenario = (data) => {
	console.log(data)
	return (dispatch) => {
		dispatch(Creators.isLoadingDeleteClient());
		deleteScenarioApi(data).then((response) => {
			console.log(response)
			dispatch(Creators.deleteScenarioSuccess(data.dataId, data.id));
		}).catch((error) => {
			console.log(data)
			console.log(error)
			dispatch(Creators.deleteProjectError(error));
		});
	};
}

const assignProject = (id, data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingAssignProject());
		assignProjects(id, data).then(res => {
			dispatch(Creators.assignProjectSuccess(id, data));
		}).catch((error) => {
			dispatch(Creators.assignProjectError(error));
		});
	};
};

const unAssignProject = (id, data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingUnAssignProject());
		unAssignProjects(id, data).then(res => {
			dispatch(Creators.unAssignProjectSuccess(id, data));
		}).catch((error) => {
			dispatch(Creators.unAssignProjectError(error));
		});
	};
};

export const {Types, Creators} = createActions({
	getClientsData,
	deleteProject,
	deleteScenario, updateProject,
	getClientsDataSuccess: ['clientData'],
	getClientsDataError: ['error'],
	isLoadingClientData: [],
	deleteProjectSuccess: ['data'],
	deleteProjectError: ['error'],
	isLoadingDeleteClient: [],
	updateProjectSuccess: ['data'],
	updateProjectError: ['error'],
	isLoadingUpdateProject: [],
	deleteScenarioSuccess: ['projectId', 'scenarioId'],
	assignClientSuccess: ['userId', 'clientId'],
	assignClientError: ['error'],
	unAssignClientSuccess: ['userId', 'clientId'],
	unAssignClientError: ['error'],
	assignProjectSuccess: ['userId', 'data'],
	assignProjectError: ['error'],
	// unAssignProject,
	unAssignProjectSuccess: ['userId', 'data'],
	unAssignProjectError: ['error'],
	isLoadingAssignProject: [],
	isLoadingUnAssignProject: [],
	isLoadingUnAssignClient: [],
	isLoadingAssignClient: [],
});

const initialState = {
	clientData: null,
	users: [],
	isError: false,
	isLoading: false,
	isDeleteLoading: false,
	isDeleteError: null,
	isLoadingAssignProject: false,
	isLoadingUnAssignProject: false,
};

const getClientsDataSuccess = (state = initialState, action) => {
	return {
		...state,
		clientData: action.clientData.client,
		users: action.clientData.users,
		isLoading: false,
		isError: '',
	};
};

const getClientsDataError = (state = initialState, action) => {
	return {
		...state,
		clientData: null,
		isLoading: false,
		isError: action.error,
	};
};

const isLoadingClientData = (state = initialState, action) => {
	return {
		...state,
		isLoading: true,
		isError: '',
	};
};

const deleteProjectSuccess = (state = initialState, action) => {
	if (!state.clientData) return state;
	return {
		...state,
		clientData: {
			...state.clientData,
			data: state.clientData.data.filter(p => p._id !== action.data._id)
		},
		isDeleteLoading: false,
		isDeleteError: '',
	};
};

const deleteScenarioSuccess = (state = initialState, action) => {
	const allData = {...state.clientData};
	const index = allData.data?.findIndex(p => p._id === action.projectId);
	const scenarios = allData.data[index]?.scenarios.filter(p => p._id !== action.scenarioId);
	allData.data[index].scenarios = scenarios;

	return {
		...state,
		clientData: allData,
		isDeleteLoading: false,
		isDeleteError: '',
	};
};

const deleteProjectError = (state = initialState, action) => {
	return {
		...state,
		isDeleteLoading: false,
		isDeleteError: action.error,
	};
};

const isLoadingDeleteClient = (state = initialState, action) => {
	return {
		...state,
		isDeleteLoading: true,
		isDeleteError: '',
	};
};

const updateProjectSuccess = (state = initialState, action) => {
	if (!state.clientData) return state;
	const project = state.clientData.data.find(p => p._id === action.data._id);
	project.status = action.data.status;
	return {
		...state,
		clientData: {
			...state.clientData,
		},
		isDeleteLoading: false,
		isDeleteError: '',
		isUpdateLoading: false,
		isUpdateError: '',
	};
};

const updateProjectError = (state = initialState, action) => {
	console.log(action)
	return {
		...state,
		isUpdateLoading: false,
		isUpdateError: action.error,
	};
};

const isLoadingUpdateProject = (state = initialState, action) => {
	return {
		...state,
		isUpdateLoading: true,
		isUpdateError: '',
	};
};

const assignProjectSuccess = (state = initialState, action) => {
	console.log(action)
	if (!state.clientData) {
		return state;
	}
	const projectIndex = state.clientData?.data.findIndex(a => a._id === action.data.projectId);
	if (projectIndex < 0) return state;
	const project = state.clientData.data[projectIndex];
	project.assignees = project.assignees.concat(action.userId);
	return {
		...state,
		clientData: {...state.clientData, data: Object.assign(state.clientData.data, {[projectIndex]: project})},
		isLoadingAssignProject: false,
		isError: '',
	};
};

const assignProjectError = (state = initialState, action) => {
	return {
		...state,
		// users: null,
		isLoadingAssignProject: false,
		isError: action.error,
	};
};

const unAssignProjectSuccess = (state = initialState, action) => {
	if (!state.clientData) {
		return state;
	}
	const projectIndex = state.clientData.data.findIndex(p => p._id === action.data.projectId);
	if (projectIndex < 0) {
		return {
			...state,
			isLoadingUnAssignClient: false,
			isError: '',
		};
	}
	const project = state.clientData.data[projectIndex];
	project.assignees = project.assignees.filter(p => p !== action.userId);
	return {
		...state,
		clientData: {...state.clientData, data: Object.assign(state.clientData.data, {[projectIndex]: project})},
		isLoadingUnAssignClient: false,
		isError: '',
	};
};

const unAssignProjectError = (state = initialState, action) => {
	return {
		...state,
		isLoadingUnAssignClient: false,
		isError: action.error,
	};
};

const assignClientSuccess = (state = initialState, action) => {
	if (!state.clientData) {
		return state;
	}
	return {
		...state,
		clientData: {...state.clientData, assignees: state.clientData.assignees.concat(action.userId)},
		isLoadingAssignClient: false,
		isError: '',
	};
};

const assignClientError = (state = initialState, action) => {
	return {
		...state,
		isLoadingAssignClient: false,
		isError: action.error,
	};
};
const unAssignClientSuccess = (state = initialState, action) => {
	if (!state.clientData) {
		return state;
	}
	return {
		...state,
		clientData: {...state.clientData, assignees: state.clientData.assignees.filter(p => p !== action.userId)},
		isLoadingUnAssignClient: false,
		isError: '',
	};
};

const unAssignClientError = (state = initialState, action) => {
	return {
		...state,
		isLoadingUnAssignClient: false,
		isError: action.error,
	};
};

const isLoadingAssignProject = (state = initialState, action) => {
	return {
		...state,
		isLoadingAssignProject: true,
		isError: '',
	};
};

const isLoadingUnAssignProject = (state = initialState, action) => {
	return {
		...state,
		isLoadingUnAssignProject: true,
		isError: '',
	};
};


const isLoadingUnAssignClient = (state = initialState, action) => {
	return {
		...state,
		isLoadingUnAssignClient: true,
		isError: '',
	};
};

const isLoadingAssignClient = (state = initialState, action) => {
	return {
		...state,
		isLoadingAssignClient: true,
		isError: '',
	};
};

export default createReducer(initialState, {
	[Types.GET_CLIENTS_DATA]: getClientsData,
	[Types.DELETE_PROJECT]: deleteProject,
	[Types.DELETE_PROJECT_ERROR]: deleteProjectError,
	[Types.IS_LOADING_DELETE_CLIENT]: isLoadingDeleteClient,
	[Types.UPDATE_PROJECT]: updateProject,
	[Types.UPDATE_PROJECT_SUCCESS]: updateProjectSuccess,
	[Types.UPDATE_PROJECT_ERROR]: updateProjectError,
	[Types.IS_LOADING_UPDATE_PROJECT]: isLoadingUpdateProject,
	[Types.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
	[Types.DELETE_SCENARIO]: deleteScenario,
	[Types.GET_CLIENTS_DATA_SUCCESS]: getClientsDataSuccess,
	[Types.GET_CLIENTS_DATA_ERROR]: getClientsDataError,
	[Types.DELETE_SCENARIO_SUCCESS]: deleteScenarioSuccess,
	[Types.IS_LOADING_CLIENT_DATA]: isLoadingClientData,
	[Types.ASSIGN_CLIENT_SUCCESS]: assignClientSuccess,
	[Types.ASSIGN_CLIENT_ERROR]: assignClientError,
	[Types.ASSIGN_PROJECT_SUCCESS]: assignProjectSuccess,
	[Types.ASSIGN_PROJECT_ERROR]: assignProjectError,
	[Types.UN_ASSIGN_CLIENT_SUCCESS]: unAssignClientSuccess,
	[Types.UN_ASSIGN_CLIENT_ERROR]: unAssignClientError,
	[Types.UN_ASSIGN_PROJECT_SUCCESS]: unAssignProjectSuccess,
	[Types.UN_ASSIGN_PROJECT_ERROR]: unAssignProjectError,
	[Types.IS_LOADING_ASSIGN_PROJECT]: isLoadingAssignProject,
	[Types.IS_LOADING_UN_ASSIGN_PROJECT]: isLoadingUnAssignProject,
	[Types.IS_LOADING_UN_ASSIGN_CLIENT]: isLoadingUnAssignClient,
	[Types.IS_LOADING_ASSIGN_CLIENT]: isLoadingAssignClient,
});
