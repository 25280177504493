import { createActions, createReducer } from "reduxsauce";


export const { Types, Creators } = createActions({
    setUser: ['profile'],
    getProfileError: ['error'],
    isLoadingProfile: [],
    isForgotPassword: ['type'],
});

const initialState = {
    user: null,
    isError: false,
    isLoading: false,
    isForgotPassword: false
};

const setUser = (state = initialState, action) => {
    return {
        ...state,
        isLoading: false,
        user: action.profile,
        isError: '',
    };
};

const getProfileError = (state = initialState, action) => {
    return {
        ...state,
        isLoading: false,
        isError: action.error,
    };
};

const isLoadingProfile = (state = initialState, action) => {
    return {
        ...state,
        isLoading: true,
        isError: '',
    };
};

const isForgotPassword = (state = initialState, action) => {
    return {
        ...state,
        isForgotPassword: action.type,
    };
}

export default createReducer(initialState, {
    [Types.SET_USER]: setUser,
    [Types.GET_PROFILE_ERROR]: getProfileError,
    [Types.IS_LOADING_PROFILE]: isLoadingProfile,
    [Types.IS_FORGOT_PASSWORD]: isForgotPassword,
});
