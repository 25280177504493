import React, {useEffect, useRef, useState} from 'react'
import {Button, ButtonToolbar, Form, FormFeedback, FormGroup, Input, Modal} from 'reactstrap';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {ClipLoader} from 'react-spinners';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import {Creators as clientDuck} from "../../../store/ducks/client";
import UploadIcon from 'mdi-react/EditOutlineIcon';
import Avatar from '../../../assets/avatar.png';
import {getExtension} from '../../../utils';
import {getUploadUrl} from "../../../api/admin";

export default function ClientAddModal(props) {
	let myref = useRef();
	const [data, setData] = useState({ _id: '', name: '', company: '', email: '', phoneNumber: '', loading: false });
	const dispatch = useDispatch();
	const client = useSelector(state => state.clients);
	const [selectedImage, setSelectedImage] = useState();

	useEffect(() => {
		setData({ ...props.modal.clientToUpdate });
	}, [props.modal.clientToUpdate]);

	useEffect(() => {
		if (!client.isUpdateLoading && data.loading && !client.isUpdateError) {
			props.toggle();
			setData({ loading: false });
		}
		if (!client.isUpdateLoading && data.loading && client.isUpdateError) {
			setData({ loading: false });
			toast.error(client.isUpdateError.message, { position: toast.POSITION.TOP_RIGHT })
		}
	}, [client.isUpdateLoading]);

	const clientAddHandler = async (values) => {
		setData({ loading: true });
		if (selectedImage) {
			await getUploadUrl({
				name: `profiles/${selectedImage.file.name}`,
				type: selectedImage.file.type,
				ACL: 'public-read'
			}).then(async (res) => {
				values.image = `https://nextgenoptima.s3-ap-southeast-2.amazonaws.com/profiles/${selectedImage.file.name}`;
				return uploadUrl(res.uploadURL, selectedImage.file);
			})
		}
		if (data._id) {
			dispatch(clientDuck.updateClient(data._id, values))
		} else {
			if (!values.image) {
				setData({ loading: false });
			} else {
				dispatch(clientDuck.addClient(values));

			}
		}
	};

	const handleBrowse = function (e) {
		e.preventDefault();
		myref.click();
	};
	const uploadUrl = (url, file) =>
		fetch(url, {
			method: "PUT",
			body: new Blob([file], { type: file.type }),
		});

	const onFileSelected = (e, setFieldValue) => {
		const files = e.target.files;
		for (let file of files) {
			if (["jpeg", "jpg", "png"].includes(getExtension(file.name))) {
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setFieldValue("image", reader.result);
					setSelectedImage({ file });
				});
				reader.readAsDataURL(file);
			} else {
				toast.error("Please Choose Valid File Format", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
		e.target.value = "";
	};


	return (
		<div>
			<Modal isOpen={props.modal.modal} toggle={props.toggle} modalClassName="ltr-support"
				className="modal-dialog modal-dialog--primary">
				<Formik
					enableReinitialize
					initialValues={{
						name: data.name,
						company: data.company,
						email: data.email,
						phoneNumber: data.phoneNumber,
						image: data.image
					}}
					validationSchema={SignupSchema}
					onSubmit={(values) => {
						clientAddHandler(values);
					}}>
					{({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur, }) => (
						<>
							<div className="modal__header">
								<button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.toggle} />
							</div>
							<div className="modal__body">
								<form className="form">
									<div className="form__form-group">
										<Form>
											<div>
												<FormGroup>
													<div className='my-1 mb-3 position-relative upload-profile-picture-container'>
														<img className="user-profile-picture" src={values.image ? values.image : Avatar}
															alt='user-picture' />
														<input type="file" name="image" accept=".jpeg, .jpg, .png" hidden id="exampleFile" ref={(r) => {
															myref = r;
														}} onChange={(e) => onFileSelected(e, setFieldValue)} />
														<div class="upload-profile-picture-middle">
															<Button onClick={handleBrowse} type='button' className='upload-profile-picture-btn'>
																<UploadIcon className='p-0 m-0 upload-icon' />
															</Button>
														</div>
													</div>
													<p className="image_error">{errors.image && touched.image && errors.image}</p>
												</FormGroup>
											</div>
											<FormGroup>
												<span className="form__form-group-label">Name</span>
												<Input type="text"
													invalid={errors.name && touched.name}
													onChange={handleChange('name')}
													onBlur={handleBlur('name')}
													value={values.name}
													placeholder="Enter name" />
												<FormFeedback>
													{errors.name && touched.name && errors.name}
												</FormFeedback>
											</FormGroup>
											<FormGroup>
												<span className="form__form-group-label">Company</span>
												<Input type="text"
													invalid={errors.company && touched.company}
													onChange={handleChange('company')}
													onBlur={handleBlur('company')}
													value={values.company}
													placeholder="Enter company name" />
												<FormFeedback>
													{errors.company && touched.company && errors.company}
												</FormFeedback>
											</FormGroup>
											<FormGroup>
												<span className="form__form-group-label">Email</span>
												<Input type="email"
													invalid={errors.email && touched.email}
													onChange={handleChange('email')}
													onBlur={handleBlur('email')}
													value={values.email}
													placeholder="Enter email" />
												<FormFeedback>
													{errors.email && touched.email && errors.email}
												</FormFeedback>
											</FormGroup>
											<FormGroup>
												<span className="form__form-group-label">Phone Number</span>
												<Input type="phoneNumber"
													invalid={errors.phoneNumber && touched.phoneNumber}
													onChange={handleChange('phoneNumber')}
													onBlur={handleBlur('phoneNumber')}
													value={values.phoneNumber}
													placeholder="Enter Phone Number" />
												<FormFeedback>
													{errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
												</FormFeedback>
											</FormGroup>
										</Form>
									</div>
								</form>
							</div>
							<ButtonToolbar className="modal__footer pb-3 m-0">
								<Button className="modal_cancel" onClick={props.toggle}>Cancel</Button>{' '}
								<Button className="modal_ok btn btn-primary" color="primary" onClick={handleSubmit}
									disabled={client.isUpdateLoading || data.loading}>
									{(client.isUpdateLoading || data.loading) ? <ClipLoader sizeUnit={"px"} size={20} color={"#70bbfd"}
										loading={true} /> : data._id ? 'Update' : 'Create'}
								</Button>
							</ButtonToolbar>
						</>
					)}
				</Formik>
			</Modal>
		</div>
	)
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignupSchema = Yup.object().shape({
	name: Yup.string()
		.required('Client name is required'),
	image: Yup.string()
		.required('Client image is required'),
	phoneNumber: Yup.string()
		.required("required")
		.matches(phoneRegExp, 'Phone number is not valid'),
	email: Yup.string()
		.email('Invalid email').required('Required'),
});
