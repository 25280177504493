import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";

export const canUseDOM = !!(
	typeof window !== "undefined" &&
	window.document &&
	window.document.createElement
);

/**
 * Check if email is valid.
 *
 * @param {string} email email string.
 *
 * @return {boolean} is valid.
 */
export function isValidEmail(email) {
	const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(email);
}

/**
 * Get unique ID.
 *
 * @return {string} uid.
 */
export function getUID() {
	return (
		Number(String(Math.random()).slice(2)) +
		Date.now() +
		Math.round(window.performance.now())
	).toString(36);
}


/**
 * Convert file size to human readable string.
 *
 * @param {number} size file size.
 *
 * @return {string} human readable size.
 */
export function fileSizeToHumanReadable(size) {
	const i = Math.floor(Math.log(size) / Math.log(1024));
	return (
		(size / Math.pow(1024, i)).toFixed(2) * 1 +
		" " +
		["B", "kB", "MB", "GB", "TB"][i]
	);
}

export function camelToTitle(text) {
	const result = text.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export function groupBy(xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
}

export function groupByServer(xs, key, id) {
	return xs.reduce(function (rv, x) {
		(rv[x[key][id]] = rv[x[key][id]] || []).push(x);
		return rv;
	}, {});
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function getExtension(name) {
	if (!name || typeof name !== "string") return "";
	return name.split(".").pop();
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes
	return ref.current;
}

export function formatCurrency(value, maximumFractionDigits = 2) {
	return !isNaN(+value)
		? (+value).toLocaleString("en-AU", {
			style: "currency",
			currency: "AUD",
			maximumFractionDigits,
		})
		: value;
}

export function formatNumber(value) {
	return !isNaN(+value)
		? (+value).toLocaleString("en-AU", {
			style: "decimal",
			maximumFractionDigits: 2,
		})
		: value;
}

export const useUserRole = () => {
	const { user } = useSelector((state) => state.user);
	const userData = user || JSON.parse(localStorage.getItem("user"));
	if (userData) {
		const userRole =
			userData.signInUserSession?.accessToken?.payload["cognito:groups"][0];
		const userTypes = {
			Admin: 0,
			ClientManager: 1,
			TenantManager: 2,
			ProjectManager: 3,
			Client: 4,
		};
		return userTypes[userRole];
	}
	return null;
};

export function encodeValidationObject(data) {
	data = Array.isArray(data) ? data : [data];
	const result = [];
	data.forEach((p) => {
		if (p.type === "Numeric") {
			result.push(encodeNumeric(p));
			if (p.warning)
				result.push(encodeNumeric(p.warning));
		} else if (p.type === "Category") {
			result.push(encodeCategory(p));
			if (p.warning)
				result.push(encodeCategory(p.warning));
		} else {
			result.push(encodeFreeText(p));
		}
	});
	return result;
}

export function decodeValidationObject(data, type = "multi") {
	data = Array.isArray(data) ? data : [data];
	const deData = Object.values(groupBy(data, 'name'))
		.map(validations => {
			return validations.map((p) => {
				if (p?.type === "Numeric") {
					return decodeNumeric(p);
				} else if (p?.type === "Category") {
					return decodeCategory(p);
				} else {
					return decodeFreeText(p);
				}
			});
		}).map(validations => {
			if (validations.length === 1) return validations[0];
			const error = validations.find(v => v.severity === 'error') || validations.find(v => v.severity === 'warning');
			const warning = validations.find(v => v?.type === error?.type && (v.severity === 'warning' || v !== error));
			console.log(error, warning);
			return { ...error, type: error?.type || warning?.type, warning };
		});
	return type === "single" ? deData[0] : deData;
}

const decodeCategory = data => {
	const { __v = "", _id = "" } = data;
	const [condition, errorText, successText] = data.validation.if;
	const validValues = condition.in ? condition.in[1] : [];
	return {
		severity: data.severity,
		inputName: data.name,
		type: data.type,
		validValues,
		errorText, successText, condition,
		__v,
		_id,
	};
};
const decodeNumeric = (data) => {
	const { __v = "", _id = "" } = data;
	const [condition, errorText, successText] = data.validation.if;
	const lessThan = condition.and.find(p => Object.keys(p).includes("<="));
	const greaterThan = condition.and.find(p => Object.keys(p).includes(">="));
	return {
		severity: data.severity,
		inputName: data.name,
		type: data.type,
		validValues: { lessThan, greaterThan },
		errorText, successText,
		__v,
		_id,
	};
};
const decodeFreeText = (data) => {
	const { __v = "", _id = "" } = data;
	const [condition, errorText, successText] = data.validation.if;
	return {
		severity: data.severity,
		inputName: data.name,
		type: data.type || "FreeText",
		errorText, successText, condition,
		__v,
		_id,
	};
};

const encodeCategory = data => {
	const { __v = "", _id = "" } = data;
	let validation;
	if (data.validValues.length) {
		validation = {
			if: [{ in: [{ 'var': data.inputName }, data.validValues] }, `${data.inputName} ok`, `${data.inputName} should be one of ${data.validValues?.join(',')}`]
		}
	} else {
		validation = {
			if: [{ missing: [data.inputName] },
			`Missing Column ${data.inputName}`,
				"OK",],
		}
	}
	return {
		severity: data.severity,
		name: data.inputName,
		type: data.type,
		validation, __v, _id,
	};
};
const encodeNumeric = (data) => {
	const { __v = "", _id = "" } = data;
	let validation;
	if (data.validValues) {
		validation = {
			if: [{ and: [data.validValues.lessThan, data.validValues.greaterThan] },
			`${data.inputName} ok`,
				`${data.inputName} should be between ${data.validValues.greaterThan['>='][1]} and ${data.validValues.lessThan['<='][1]}`,
			],
		}
	} else {
		validation = {
			if: [{ missing: [data.inputName] },
			`Missing Column ${data.inputName}`,
				"OK",],
		}
	}
	return {
		severity: data.severity,
		name: data.inputName,
		type: data.type,
		validation, __v, _id,
	};
};
const encodeFreeText = (data) => {
	const { __v = "", _id = "" } = data;
	return {
		severity: data.severity,
		name: data.inputName,
		type: data.type || "FreeText",
		validation: {
			if: [{ missing: [data.inputName] },
			`Missing Column ${data.inputName}`,
				"OK",],
		}, __v, _id,
	};
};
