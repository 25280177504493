import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTableBodyDnD from './ReactTableBodyDnD';
import { ThemeProps } from '../../../prop-types/ReducerProps';

const DraggableOffBodyReactTable = ({ page, getTableBodyProps, prepareRow, onRowClick, isSelected }) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row, index) => {
      prepareRow(row);
      return (
        <tr style={row.index === isSelected ? { backgroundColor: '#ccc' }:{}} {...row.getRowProps()} onClick={e => onRowClick(e, row.index)}>
          {row.cells.map(cell => (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ))}
        </tr>
      );
    })}
  </tbody >
);

DraggableOffBodyReactTable.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateData, theme, onRowClick, isSelected
}) => (
  <>
    {withDragAndDrop
      ? (
        <ReactTableBodyDnD
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateData={updateData}
          theme={theme}
          onRowClick={onRowClick}
        />
      ) : <DraggableOffBodyReactTable page={page} getTableBodyProps={getTableBodyProps} prepareRow={prepareRow} onRowClick={onRowClick} isSelected={isSelected} />
    }
  </>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect(state => ({
  theme: state.theme,
}))(ReactTableBody);
