import { createActions, createReducer } from "reduxsauce";
import { getAllClient, deleteClient as deleteClientApi, updateClient as updateClientApi, addClient as addApi } from "../../api/admin/index"

const getClients = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingClient());
        getAllClient(data).then((response) => {
            dispatch(Creators.getClientsSuccess(response));
        }).catch((error) => {
            dispatch(Creators.getClientsError(error));
        });
    };
}

const deleteClient = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingDeleteClient());
        deleteClientApi(data).then((response) => {
            dispatch(Creators.deleteClientsSuccess(data));
        }).catch((error) => {
            dispatch(Creators.deleteClientsError(error));
        });
    };
}

const updateClient = (_id, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUpdateClient());
        updateClientApi(_id, data).then((response) => {
            dispatch(Creators.updateClientsSuccess({ ...data, _id }));
        }).catch((error) => {
            dispatch(Creators.updateClientsError(error));
        });
    };
}

const addClient = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUpdateClient());
        addApi(data).then((response) => {
            dispatch(Creators.addClientsSuccess(response.data));
        }).catch((error) => {
            dispatch(Creators.addClientsError(error));
        });
    };
}


export const { Types, Creators } = createActions({
    getClients,
    deleteClient,
    updateClient,
    addClient,
    getClientsSuccess: ['client'],
    getClientsError: ['error'],
    deleteClientsSuccess: ['client'],
    deleteClientsError: ['error'],
    updateClientsSuccess: ['data'],
    updateClientsError: ['error'],
    addClientsSuccess: ['data'],
    addClientsError: ['error'],
    isLoadingClient: [],
    isLoadingDeleteClient: [],
    isLoadingUpdateClient: [],
    clearUpdateError: []
});

const initialState = {
    client: null,
    isError: false,
    isLoading: false,
    isDeleteLoading: false,
    isDeleteError: '',
    isUpdateError: '',
    isUpdateLoading: false,
    clientDataFile: null
};

const getClientsSuccess = (state = initialState, action) => {
    return {
        ...state,
        client: action.client,
        isLoading: false,
        isError: '',
    };
};

const getClientsError = (state = initialState, action) => {
    return {
        ...state,
        client: null,
        isLoading: false,
        isError: action.error,
    };
};

const deleteClientsSuccess = (state = initialState, action) => {
    return {
        ...state,
        client: state.client.filter(i => i._id !== action.client),
        isDeleteLoading: false,
        isDeleteError: '',
    };
};

const deleteClientsError = (state = initialState, action) => {
    return {
        ...state,
        isDeleteLoading: false,
        isDeleteError: action.error,
    };
};

const updateClientsSuccess = (state = initialState, action) => {
    const client = [...state.client];
    const updatedClient = action.data;
    const index = state.client.findIndex(c => c._id === action.data._id)
    client[index] = updatedClient;
    return {
        ...state,
        client: client,
        isUpdateLoading: false,
        isUpdateError: '',
    };
};

const updateClientsError = (state = initialState, action) => {
    return {
        ...state,
        isUpdateLoading: false,
        isUpdateError: action.error,
    };
};

const addClientsSuccess = (state = initialState, action) => {
    console.log([...state.client, action.data])
    return {
        ...state,
        client: [...state.client, action.data],
        isUpdateLoading: false,
        isUpdateError: '',
    };
};

const addClientsError = (state = initialState, action) => {
    return {
        ...state,
        isUpdateLoading: false,
        isUpdateError: action.error,
    };
};

const isLoadingClient = (state = initialState, action) => {
    return {
        ...state,
        isLoading: true,
        isError: '',
    };
};
const isLoadingDeleteClient = (state = initialState, action) => {
    return {
        ...state,
        isDeleteLoading: true,
        isDeleteError: '',
    };
};
const isLoadingUpdateClient = (state = initialState, action) => {
    return {
        ...state,
        isUpdateLoading: true,
        isUpdateError: '',
    };
};
const clearUpdateError = (state = initialState) => {
    return {
        ...state,
        isUpdateError: null,
    };
};


export default createReducer(initialState, {
    [Types.GET_CLIENTS]: getClients,
    [Types.DELETE_CLIENT]: deleteClient,
    [Types.UPDATE_CLIENT]: updateClient,
    [Types.ADD_CLIENT]: addClient,
    [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
    [Types.GET_CLIENTS_ERROR]: getClientsError,
    [Types.DELETE_CLIENTS_SUCCESS]: deleteClientsSuccess,
    [Types.DELETE_CLIENTS_ERROR]: deleteClientsError,
    [Types.UPDATE_CLIENTS_SUCCESS]: updateClientsSuccess,
    [Types.UPDATE_CLIENTS_ERROR]: updateClientsError,
    [Types.ADD_CLIENTS_SUCCESS]: addClientsSuccess,
    [Types.ADD_CLIENTS_ERROR]: addClientsError,
    [Types.IS_LOADING_CLIENT]: isLoadingClient,
    [Types.IS_LOADING_DELETE_CLIENT]: isLoadingDeleteClient,
    [Types.IS_LOADING_UPDATE_CLIENT]: isLoadingUpdateClient,
    [Types.CLEAR_UPDATE_ERROR]: clearUpdateError,
});
