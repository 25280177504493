import {createActions, createReducer} from "reduxsauce";
import {createValidation, deleteValidation, getValidations, updateValidation} from "../../api/admin";
import {decodeValidationObject, encodeValidationObject} from "../../utils";

const getValidationsData = () => {
	return (dispatch) => {
		dispatch(Creators.getValidationsLoading());
		getValidations().then(res => {
			dispatch(Creators.getValidationsSuccess(decodeValidationObject(res)));
		}).catch((error) => {
			console.log(error);
			dispatch(Creators.getValidationsError(error));
		});
	};
};

const deleteValidationsData = (id) => {
	return (dispatch) => {
		dispatch(Creators.isDeleteValidationsData());
		deleteValidation(id).then(res => {
			dispatch(Creators.deleteValidationsDataSuccess(id));
		}).catch((error) => {
			dispatch(Creators.deleteValidationsDataError(error));
		});
	};
}

const addValidationsData = (data) => {
	return (dispatch) => {
		dispatch(Creators.isAddValidationsDataLoading());
		Promise.all(encodeValidationObject(data).map(createValidation)).then(res => {
			dispatch(Creators.addValidationsDataSuccess(decodeValidationObject(res)));
		}).catch((error) => {
			console.log(error);
			dispatch(Creators.addValidationsDataError(error));
		});
	};
};

const updateValidationsData = (data) => {
	return (dispatch) => {
		dispatch(Creators.isUpdateValidationsDataLoading());
		Promise.all(encodeValidationObject(data).map(d => d._id ? updateValidation(d._id, d) : createValidation(d))).then(res => {
			dispatch(Creators.updateValidationsDataSuccess(decodeValidationObject(res)));
		}).catch((error) => {
			console.log(error);
			dispatch(Creators.addValidationsDataError(error));
		});
	};
}

export const {Types, Creators} = createActions({
	getValidationsData,
	getValidationsSuccess: ['validations'],
	getValidationsError: ['error'],
	getValidationsLoading: [],
	deleteValidationsData,
	deleteValidationsDataSuccess: ['id'],
	deleteValidationsDataError: ['error'],
	isDeleteValidationsData: [],
	addValidationsData,
	addValidationsDataSuccess: ['validation'],
	addValidationsDataError: ['error'],
	isAddValidationsDataLoading: [],
	updateValidationsData,
	updateValidationsDataSuccess: ['validation'],
	updateValidationsDataError: ['error'],
	isUpdateValidationsDataLoading: [],
});

const initialState = {
	validations: null,
	isError: false,
	isLoading: false,
	isDeleteLoading: false,
	isDeleteError: '',
	isAddLoading: false,
	isAddError: '',
	isUpdateLoading: false,
	isUpdateError: '',
};

const getValidationsSuccess = (state = initialState, action) => {
	return {
		...state,
		validations: action.validations,
		isLoading: false,
		isError: '',
	};
};

const deleteValidationsDataSuccess = (state = initialState, action) => {
	return {
		...state,
		validations: state.validations.filter(p => p._id !== action.id),
		isDeleteLoading: false,
		isDeleteError: '',
	};
};

const getValidationsError = (state = initialState, action) => {
	return {
		...state,
		validations: null,
		isLoading: false,
		isError: action.error,
	};
};

const deleteValidationsDataError = (state = initialState, action) => {
	return {
		...state,
		isDeleteLoading: false,
		isDeleteError: action.error,
	};
};

const getValidationsLoading = (state = initialState) => {
	return {
		...state,
		isLoading: true,
		isError: '',
	};
};
const isDeleteValidationsData = (state = initialState) => {
	return {
		...state,
		isDeleteLoading: true,
		isDeleteError: '',
	};
};

const isAddValidationsDataLoading = (state = initialState) => {
	return {
		...state,
		isAddLoading: true,
		isAddError: '',
	};
};

const addValidationsDataSuccess = (state = initialState, action) => {
	const validations = Array.isArray(action.validation) ? action.validation : [action.validation];
	return {
		...state,
		validations: [...state.validations, ...validations],
		isAddLoading: false,
		isAddError: '',
	};
};

const addValidationsDataError = (state = initialState, action) => {
	return {
		...state,
		isAddLoading: false,
		isAddError: action.error,
	};
};

const isUpdateValidationsDataLoading = (state = initialState) => {
	return {
		...state,
		isUpdateLoading: true,
		isUpdateError: '',
	};
};

const updateValidationsDataSuccess = (state = initialState, action) => {
	const data = Object.assign([], state.validations);
	const validations = Array.isArray(action.validation) ? action.validation : [action.validation];
	validations.forEach(v => {
		const index = data.findIndex(p => p._id === v._id);
		if (index >= 0) data[index] = {...v}; else data.push(v);
	});
	return {
		...state,
		validations: data,
		isUpdateLoading: false,
		isUpdateError: '',
	};
};

const updateValidationsDataError = (state = initialState, action) => {
	console.log(action);
	return {
		...state,
		isUpdateLoading: false,
		isUpdateError: action.error,
	};
};

export default createReducer(initialState, {
	[Types.GET_VALIDATIONS_DATA]: getValidationsData,
	[Types.ADD_VALIDATIONS_DATA]: addValidationsData,
	[Types.UPDATE_VALIDATIONS_DATA]: updateValidationsData,
	[Types.DELETE_VALIDATIONS_DATA]: deleteValidationsData,
	[Types.GET_VALIDATIONS_SUCCESS]: getValidationsSuccess,
	[Types.GET_VALIDATIONS_ERROR]: getValidationsError,
	[Types.GET_VALIDATIONS_LOADING]: getValidationsLoading,
	[Types.DELETE_VALIDATIONS_DATA_SUCCESS]: deleteValidationsDataSuccess,
	[Types.DELETE_VALIDATIONS_DATA_ERROR]: deleteValidationsDataError,
	[Types.IS_DELETE_VALIDATIONS_DATA]: isDeleteValidationsData,
	[Types.IS_ADD_VALIDATIONS_DATA_LOADING]: isAddValidationsDataLoading,
	[Types.ADD_VALIDATIONS_DATA_SUCCESS]: addValidationsDataSuccess,
	[Types.ADD_VALIDATIONS_DATA_ERROR]: addValidationsDataError,
	[Types.IS_UPDATE_VALIDATIONS_DATA_LOADING]: isUpdateValidationsDataLoading,
	[Types.UPDATE_VALIDATIONS_DATA_SUCCESS]: updateValidationsDataSuccess,
	[Types.UPDATE_VALIDATIONS_DATA_ERROR]: updateValidationsDataError,
});
