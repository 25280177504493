import React, {Component} from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import {withRouter} from "react-router";

class SidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
		userRole: PropTypes.number.isRequired,
	};

	hideSidebar = () => {
		const {onClick} = this.props;
		onClick();
	};

	logoutHandler = () => {
		localStorage.clear();
		this.props.history.push("/login");
	};

	renderMenuItems = () => {
		const {userRole} = this.props;
		if (userRole === 0 || userRole === 2)
			return (
				<>
					<SidebarLink
						title="Users"
						iconUrl="person"
						route="/users"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Assumptions"
						iconUrl="calculate"
						route="/assumptions"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Validations"
						iconUrl="library_add_check"
						route="/validations"
						onClick={this.hideSidebar}
					/>
				</>
			);
	};

	render() {
		const {userRole} = this.props;
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					{(userRole === 0 || userRole === 2) && <>
						<SidebarLink
							title="Workspace"
							iconUrl="history"
							route="/workspace"
							onClick={this.hideSidebar}
						/>
						<div className="border-bottom mt-1"/>
					</>}
					<SidebarLink
						title={
							userRole === 3
								? "Projects"
								: userRole === 4
								? "Scenarios"
								: "Clients"
						}
						iconUrl="business"
						route="/homepage"
						onClick={this.hideSidebar}
					/>
					{this.renderMenuItems()}
					{/* <SidebarLink title="Users" icon="user" route="/users" onClick={this.hideSidebar} />
          <SidebarLink title="Assumptions" icon="user" route="/assumptions" onClick={this.hideSidebar} />
          <SidebarLink title="Validations" icon="user" route="/validations" onClick={this.hideSidebar} />
          <SidebarLink title="New Clients" icon="user" route="/newclient" onClick={this.hideSidebar} /> */}
					{/* <SidebarLink title="project" icon="user" route="/project" onClick={this.hideSidebar} />
          <SidebarLink title="scenario" icon="user" route="/scenario" onClick={this.hideSidebar} /> */}
					{/* <SidebarLink title="History" icon="user" route="/history" onClick={this.hideSidebar} /> */}
					<ul className="sidebar__block mb-2"></ul>
					<SidebarLink
						title="Logout"
						iconUrl="logout"
						route="/login"
						onClick={this.logoutHandler}
					/>
				</ul>
			</div>
		);
	}
}

export default withRouter(SidebarContent);
