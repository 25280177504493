import {createActions, createReducer} from "reduxsauce";
import {
    getScenarioLink as scenarioLink,
    refreshScenarioLink as refreshScenarioLinks,
    refreshScenarioLinkByCode,
    refreshScenarioLinkCode as refreshScenarioLinksCode
} from "../../api/admin";

const getScenarioLink = (id, dataId, clientId) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingScenarioLink());
        scenarioLink(id, dataId, clientId).then(res => {
            dispatch(Creators.getScenarioLinkSuccess(res));
        }).catch((error) => {
            dispatch(Creators.getScenarioLinkError(error));
        });
    };
};

const refreshScenarioLink = (id, dataId, clientId) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingRefreshScenarioLink());
        refreshScenarioLinks(id, dataId, clientId).then(res => {
            dispatch(Creators.refreshScenarioLinkSuccess(res));
        }).catch((error) => {
            dispatch(Creators.refreshScenarioLinkError(error));
        });
    };
};

const refreshScenarioLinkCode = (id, dataId, clientId) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingRefreshScenarioLinkCode());
        refreshScenarioLinksCode(id, dataId, clientId).then(res => {
            dispatch(Creators.refreshScenarioLinkCodeSuccess(res));
        }).catch((error) => {
            dispatch(Creators.refreshScenarioLinkCodeError(error));
        });
    };
};

const getScenarios = (id, code) => {
    return (dispatch) => {
        dispatch(Creators.scenarioLoading());
        refreshScenarioLinkByCode(id, code).then(res => {
            dispatch(Creators.getScenarioSuccess(res.data.scenarios));
        }).catch((error) => {
            dispatch(Creators.getScenarioError(error.response.data.message));
        });
    };
};


export const { Types, Creators } = createActions({
    getScenarioLink,
    getScenarioLinkSuccess: ['scenario'],
    getScenarioLinkError: ['error'],
    isLoadingScenarioLink: [],
    refreshScenarioLink,
    refreshScenarioLinkSuccess: ['scenario'],
    refreshScenarioLinkError: ['error'],
    isLoadingRefreshScenarioLink: [],
    refreshScenarioLinkCode,
    refreshScenarioLinkCodeSuccess: ['scenario'],
    refreshScenarioLinkCodeError: ['error'],
    getScenarios,
    getScenarioSuccess: ['scenario'],
    getScenarioError: ['error'],
    scenarioLoading: [],
    isLoadingRefreshScenarioLinkCode: [],
});

const initialState = {
    scenarioLink: [],
    scenarios: null,
    isError: false,
    isLoadingScenarioLink: false,
    isLoadingRefreshScenarioLink: false,
    isLoadingRefreshScenarioLinkCode: false,
    isLoadingScenario: false,
    isErrorScenario: false,
};

const getScenarioLinkSuccess = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: action.scenario,
        isLoadingScenarioLink: false,
        isError: '',
    };
};

const getScenarioLinkError = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: null,
        isLoadingScenarioLink: false,
        isError: action.error,
    };
};

const isLoadingScenarioLink = (state = initialState, action) => {
    return {
        ...state,
        isLoadingScenarioLink: true,
        isError: '',
    };
};

const refreshScenarioLinkSuccess = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: action.scenario,
        isLoadingRefreshScenarioLink: false,
        isError: '',
    };
};

const refreshScenarioLinkError = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: null,
        isLoadingRefreshScenarioLink: false,
        isError: action.error,
    };
};

const isLoadingRefreshScenarioLink = (state = initialState, action) => {
    return {
        ...state,
        isLoadingRefreshScenarioLink: true,
        isError: '',
    };
};


const refreshScenarioLinkCodeSuccess = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: action.scenario,
        isLoadingRefreshScenarioLinkCode: false,
        isError: '',
    };
};

const refreshScenarioLinkCodeError = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: null,
        isLoadingRefreshScenarioLinkCode: false,
        isError: action.error,
    };
};

const getScenarioSuccess = (state = initialState, action) => {
    console.log(action)
    return {
        ...state,
        scenarios: action.scenario,
        isLoadingScenario: false,
        isErrorScenario: '',
    };
};

const getScenarioError = (state = initialState, action) => {
    return {
        ...state,
        scenarioLink: null,
        isLoadingScenario: false,
        isErrorScenario: action.error,
    };
};

const scenarioLoading = (state = initialState, action) => {
    return {
        ...state,
        isLoadingScenario: true,
        isErrorScenario: '',
    };
};

const isLoadingRefreshScenarioLinkCode = (state = initialState, action) => {
    return {
        ...state,
        isLoadingRefreshScenarioLinkCode: true,
        isError: '',
    };
};


export default createReducer(initialState, {
    [Types.GET_SCENARIO_LINK]: getScenarioLink,
    [Types.GET_SCENARIO_LINK_SUCCESS]: getScenarioLinkSuccess,
    [Types.GET_SCENARIO_LINK_ERROR]: getScenarioLinkError,
    [Types.IS_LOADING_SCENARIO_LINK]: isLoadingScenarioLink,
    [Types.REFRESH_SCENARIO_LINK]: refreshScenarioLink,
    [Types.REFRESH_SCENARIO_LINK_SUCCESS]: refreshScenarioLinkSuccess,
    [Types.REFRESH_SCENARIO_LINK_ERROR]: refreshScenarioLinkError,
    [Types.IS_LOADING_REFRESH_SCENARIO_LINK]: isLoadingRefreshScenarioLink,
    [Types.REFRESH_SCENARIO_LINK_CODE]: refreshScenarioLinkCode,
    [Types.REFRESH_SCENARIO_LINK_CODE_SUCCESS]: refreshScenarioLinkCodeSuccess,
    [Types.REFRESH_SCENARIO_LINK_CODE_ERROR]: refreshScenarioLinkCodeError,
    [Types.IS_LOADING_REFRESH_SCENARIO_LINK_CODE]: isLoadingRefreshScenarioLinkCode,
    [Types.GET_SCENARIOS]: getScenarios,
    [Types.GET_SCENARIO_SUCCESS]: getScenarioSuccess,
    [Types.GET_SCENARIO_ERROR]: getScenarioError,
    [Types.SCENARIO_LOADING]: scenarioLoading,
});
