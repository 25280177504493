import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import HomePage from '../../containers/components/HomePage/Index';
import Users from '../../containers/components/Users/User/User';
import Wizard from '../components/Form/Index';
import ClientDetails from '../components/Form/Index';
import Clients from '../components/Clients/Clients';
import ServerDetail from '../components/Form/ReviewSuggesions/ServerDeatil/ServerDetail';
import Validations from '../components/Validations/Validations';
import ResetPassword from '../LogIn/ResetPassword';
import PasswordConfirmation from '../LogIn/components/password_confirmation/PasswordConfirmation';
import Assumptions from '../components/Assumptions/Assumptions';
import History from '../components/History/History';
import Scenario from '../components/Scenario/Scenario';
import {useDispatch, useSelector} from 'react-redux';
import {Creators as profileDuck} from "../../store/ducks/profile";
import {useUserRole} from '../../utils';
import OtpVerification from '../LogIn/OtpVerification/OtpVerification';
import ShareScenario from '../components/ShareScenario/Scenario';

const Pages = (props) => {
	const userRole = useUserRole();
	const routes = () => {
		if (userRole === 1) {
			return (
				<>
					<Route exact path="/homepage" component={HomePage}/>
					<Route path="/homepage/client" component={ClientDetails}/>
					<Route path="/history" component={History} />
				</>
			);
		} else if (userRole === 2 || userRole === 0) {
			return (
				<>
					<Route path="/workspace" component={HomePage} />
					{/* <Route path="/workspace" component={Workspace}/> */}
					<Route exact path="/homepage" component={Clients}/>
					<Route path="/users" component={Users} />
					<Route path="/wizard" component={Wizard} />
					<Route path="/serverDetail" component={ServerDetail} />
					<Route path="/homepage/client" component={ClientDetails}/>
					<Route path="/homepage/newclient" component={Clients}/>
					<Route path="/validations" component={Validations} />
					<Route path="/assumptions" component={Assumptions} />
					<Route path="/history" component={History} />
				</>
			);
		} else if (userRole === 3)
			return (
				<>
					<Route exact path="/homepage" component={HomePage}/>
					<Route path="/homepage/client" component={ClientDetails}/>
				</>
			);
		else if (userRole === 4)
			return <Route path="/homepage" component={Scenario} />;
	};


	return (
		<Switch>
			{routes()}
			<Redirect to="/homepage" />
		</Switch>
	);
};
const WrappedRoutes = () => {
	return (
		<div>
			<Layout />
			<div className="container__wrap">
				<Route path="/" component={Pages} />
			</div>
		</div>
	)
};

const Router = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);

	useEffect(() => {
		const userData = user || JSON.parse(localStorage.getItem("user"));
		if (!user && userData) {
			dispatch(profileDuck.setUser(userData));
		}
	}, [user]);

	return (
		<MainWrapper>
			<main>
				<Switch>
					<Route exact path="/login" component={LogIn} />
					<Route exact path="/links/scenarios/:id" component={ShareScenario} />
					<Route exact path="/links/scenarios/:id/viewDetails" component={ServerDetail} />
					<Route path="/reset_password" component={ResetPassword} />
					<Route path="/otp_verification" component={OtpVerification} />
					<Route path="/confirm_password" component={PasswordConfirmation} />
					<Route path="/" component={WrappedRoutes} />
				</Switch>
			</main>
		</MainWrapper>
	);
};
export default Router;
