import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import LogInForm from './components/LogInForm';
import {Auth} from "aws-amplify";
import logoImage from "../../assets/logo.png";

const LogIn = () => {
  const history = useHistory();

  useEffect(() => {
    Auth.currentSession().then(p => {
      history.push('/homepage')
    }).catch(er => console.log(er));
  }, []);


  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card shadow">
          <div className="account__head">
            <h3 className="account__title">
              <img className="topbar__logo__image" src={logoImage} alt="" />
            </h3>
            <h4 className="account__subhead subhead">Migrate your Server to AWS easily</h4>
          </div>
          <LogInForm />
        </div>
      </div>
    </div>
  )
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
