import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap';
import DynamiclyRefreshedDoughnut from '../../DynamiclyRefreshedDoughnut';
import ServerSuggestionsPricing from './ServerSuggestionsPricing';

export default function ServerSuggestionsCard({ server, serverSelectHandler, serverUpdateHandler, selectServer, storageProducts, ...props }) {
	const { costs, _id, attributes, productFamily } = server;
	const reservationTerms = [...new Set(costs.map(p => p.termAttributes.LeaseContractLength))];
	const paymentOptions = [...new Set(costs.map(p => p.termAttributes.PurchaseOption))];
	const pricingModels = [...new Set(costs.map(p => p.termAttributes.OfferingClass))];
	const [selectedCost, setSelectedCost] = useState(costs.find(p => p.selected) || costs[0]);
	const [chartData, setChartData] = useState({ labels: [], data: [] })
	const shared = props.shared;
	//Storage Product Costs
	const selectedStorage = storageProducts["Storage"] && (storageProducts["Storage"]?.find(p => p.selected) || storageProducts["Storage"][0])
	const selectedStorageCosts = selectedStorage && (selectedStorage.costs.find(p => p.selected) || selectedStorage.costs[0]);

	//Snapshot Product Costs
	const selectedSnapshot = storageProducts['Storage Snapshot'] && (storageProducts['Storage Snapshot']?.find(p => p.selected) || storageProducts['Storage Snapshot'][0])
	const selectedSnapshotCosts = selectedSnapshot && (selectedSnapshot.costs.find(p => p.selected) || selectedSnapshot.costs[0]);

	const selectCostHandler = (costData) => {
		costData.selected = true;
		selectedCost.selected = undefined;
		setSelectedCost(costData);
		serverUpdateHandler(selectServer);
	};
	useEffect(() => {
		const labels = Object.keys(storageProducts);
		const data = [];
		for (const key of labels) {
			if (key === "Storage") data.push(selectedStorageCosts?.totalCost1Year)
			else if (key === "Storage Snapshot") data.push(selectedSnapshotCosts?.totalCost1Year)
			if (key === "Compute Instance") data.push(selectedCost?.totalCost1Year)
		}
		setChartData({ labels, data })
	}, [selectedCost, selectedStorageCosts, selectedSnapshotCosts])

	return (
		<div className="card shadow mt-3">
			<div className="card-body p-3">
				{productFamily === 'Unavailable' ?
					<span className="server-not-available">No server detail available  for this server</span>
					:
					<>
						<div className="row m-0 mb-3">
							<div className="col-12 col-xl-8 ">
								<div className="row">
									<div className="col-12">
										<span
											className="storage-title d-flex align-items-center mb-0 font-weight-bold">{attributes.instanceType}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">Current Generation</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.currentGeneration}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">Instance Family</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.instanceFamily}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">vcpu</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.vcpu}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">Physical Processor</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.physicalProcessor}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">Clock Speed</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.clockSpeed}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">memory</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.memory}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">storage</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.storage}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">networkPerformance</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.networkPerformance}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">processorArchitecture</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.processorArchitecture}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">tenancy</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.tenancy}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">operatingSystem</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.operatingSystem}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">licenseModel</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.licenseModel}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">usagetype</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.usagetype}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">operation</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.operation}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">capacitystatus</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.capacitystatus}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">dedicatedEbsThroughput</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.dedicatedEbsThroughput}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">ecu</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.ecu}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">enhancedNetworkingSupported</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.enhancedNetworkingSupported}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">intelAvxAvailable</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.intelAvxAvailable}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">intelAvx2Available</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.intelAvx2Available}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">intelTurboAvailable</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.intelTurboAvailable}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">normalizationSizeFactor</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.normalizationSizeFactor}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">preInstalledSw</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.preInstalledSw}</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-label">processorFeatures</span>
									</div>
									<div className="col-6  col-md-6 col-lg-3 col-xl-3">
										<span className="server-instance-name">{attributes.processorFeatures}</span>
									</div>

								</div>
							</div>
							<div className="col-12 col-xl-4 d-flex align-items-center justify-content-center ">
								<div>
									<DynamiclyRefreshedDoughnut chartData={chartData} title="Average" showText cutoutPercentage={80} />
									<div className="d-flex justify-content-center mt-4">
										<Button color={_id === selectServer?._id ? "success" : "primary"} className="tn btn-primary  account__btn--small"
											onClick={() => serverSelectHandler(server)} disabled={shared}>
											{_id === selectServer?._id ? "Current" : "Select"}
										</Button>
									</div>
								</div>
							</div>
						</div>
						<ServerSuggestionsPricing shared={shared} subtitle="Show Server Price List" selectedCost={selectedCost} setSelectedCost={selectCostHandler} costs={costs}
							reservationTerms={reservationTerms} pricingModels={pricingModels}
							paymentOptions={paymentOptions} />
						<ServerSuggestionsPricing shared={shared} subtitle="Show Storage Pricing" selectedCost={selectedStorageCosts} setSelectedCost={selectCostHandler} costs={selectedStorage?.costs}
							paymentOptions={paymentOptions} pricingType="storage" server={selectedStorage?.server} />
						<ServerSuggestionsPricing shared={shared} subtitle="Show Snapshots Pricing" selectedCost={selectedSnapshotCosts} setSelectedCost={selectCostHandler} costs={selectedSnapshot?.costs}
							paymentOptions={paymentOptions} pricingType="snapshot" server={selectedSnapshot?.server} />
					</>
				}
			</div>
		</div>
	)
}
