import React from 'react'
import { Container, Row } from 'reactstrap';
import DataTable from './ClientList';

export default function HomePage(props) {
  return (
    <div>
      <Container className="dashboard">
          <DataTable props={props} />
      </Container>
    </div>
  )
}
