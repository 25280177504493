import React, {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Table} from "reactstrap";
import ClientsTab from "./ClientsTab/ClientsTab";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Creators as clientDuck} from "../../../store/ducks/client";
import {Creators as clientDataDuck} from "../../../store/ducks/clientData";
import {ClipLoader} from "react-spinners";
import {useHistory} from "react-router";
import Avatar from "../../../assets/avatar.png";
import DataTableModal from "../HomePage/ClientAddModal";
import ConfirmationDialog from "../HomePage/ConfirmDialog";
import Delete from "mdi-react/DeleteIcon";
import {toast} from "react-toastify";
import {debounce} from "../../../utils/index";
import classnames from 'classnames';

export default function Clients(props) {
  const [selectedClient, setSelectedClient] = useState(null);
  const {
    client,
    isError,
    isLoading,
    isDeleteLoading: deleteLoading,
    isDeleteError,
  } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [clientToUpdate, setClientToUpdate] = useState({});
  const [clientDelete, setClientDelete] = useState("");
  const [indexRow, setIndex] = useState(null);

  const confirmationDialogBox = (p) => setClientDelete(p);
  const deleteClientHandler = (id) => dispatch(clientDuck.deleteClient(id));

  useEffect(() => {
    if (!deleteLoading && clientDelete && !isDeleteError) setClientDelete(null);
    if (!deleteLoading && clientDelete && isDeleteError)
      toast.error(isDeleteError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }, [deleteLoading]);

  useEffect(() => {
    dispatch(clientDuck.getClients());
  }, []);

  useEffect(() => {
    if (selectedClient && client && client.length) {
      const getClientById = client.find((c) => c._id === selectedClient._id);
      setSelectedClient(getClientById);
    }
  }, [client]);

  useEffect(() => {
    if (!isError && !isLoading && client) {
      let paths = history.location.pathname.split("/");
      const getClientById = client?.find(
        (c) =>
          c._id ===
          paths[
          paths[paths.length - 1].includes("-manage")
            ? paths.length - 2
            : paths.length - 1
          ]
      );
      getClientById &&
        dispatch(clientDataDuck.getClientsData(getClientById?._id, true));
      setSelectedClient(getClientById);
    }
  }, [history.location.pathname, isLoading]);

  const handleSetSelectedClint = (c) => {
    setSelectedClient(c);
	  history.push(`/homepage/newclient/${c._id}`);
  };

  const toggle = () => {
    setModal((prevState) => !prevState);
    setClientToUpdate(null);
  };

  const onMouseEffect = debounce((index) => {
    setIndex(index);
  }, 200);

  return (
    <div>
      <ConfirmationDialog
        isOpen={!!clientDelete}
        isLoading={deleteLoading}
        deleteHandler={() => deleteClientHandler(clientDelete)}
        closeHandler={() => confirmationDialogBox(null)}
      />
      <DataTableModal
        modal={{ modal, clientToUpdate }}
        toggle={() => toggle(null)}
      />
      <div className="row m-0">
        <div className="col-12 col-xl-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body pb-0">
                  {isLoading ? (
                    <div className="p-5 d-flex  my-auto justify-content-center align-items-center">
                      <ClipLoader size={40} color={"#4ce1b6"} loading={true} />
                    </div>
                  ) : selectedClient ? (
                    <div className="d-flex user-profile-card  align-items-center align-items-xl-start">
	                    <div className="mr-2 user-profile-icon">
                        <img
                          src={
                            selectedClient.image ? selectedClient.image : Avatar
                          }
                          className="user-profile-icon"
                          alt="user-profile-icon"
                        />
                      </div>
                      <div className="user-profile-details">
                        <h2 className="user-profile-name">
                          {selectedClient.name}
                        </h2>
                        <h3 className="user-profile-categories">
                          {selectedClient.designation}
                        </h3>
                        <Badge className="user-badge mb-1">
                          {selectedClient.role}
                        </Badge>
                        <h2 className="user-profile-email">
                          {selectedClient.email}
                        </h2>
                        <h2 className="user-profile-phone">
                          {selectedClient.phoneNumber}
                        </h2>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2 className="user-profile-container-title">
                        Select a User to View their Profile
                      </h2>
                    </div>
                  )}
                  <hr />
                  <div className="user-profile-count d-block d-sm-none">
                    <div className="d-flex justify-content-center justify-content-xl-around w-100">
                      <div className="mb-2">
                        {selectedClient && (
                          <h2 className="user-profile-clients">
	                          {selectedClient.totalProjects || 0}
                          </h2>
                        )}
                        <p className="user-profile-clients-name">Projects</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-row d-none d-sm-flex ">
                    <div className="mb-2">
                      {selectedClient && (
                        <h2 className="user-profile-clients">
	                        {selectedClient.projects || 0}
                        </h2>
                      )}
                      <p className="user-profile-clients-name">Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  mt-3">
              <div className="card">
                <div className="card-body py-2 px-0">
                  <Col md={12} lg={12}>
                    <Card>
                      <CardBody className="p-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="client-profile-title mb-0">CLIENTS</h2>
                          <Button
                            type="button"
                            color="primary"
                            className="py-1 px-3 btn btn-primary mb-0 account__btn--small w-15"
                            onClick={toggle}>Add</Button>
                          {/*<form className="material-form m-0">*/}
                          {/*  <TextField*/}
                          {/*    className="material-form__field"*/}
                          {/*    placeholder="Search"*/}
                          {/*    type="text"*/}
                          {/*    label="Search"*/}
                          {/*    name="text"*/}
                          {/*  />*/}
                          {/*</form>*/}
                        </div>
                        <Table
                          responsive
                          className="table--bordered table-hover"
                        >
                          <tbody>
                          {(!isLoading || selectedClient) &&
                              client &&
                              client.map((u, index) => (
                                <tr
                                  // className="pointer"
	                                className={classnames("table-client-row pointer", {selected: u === selectedClient})}
	                                // onMouseEnter={() => onMouseEffect(index)}
	                                // onMouseLeave={() => setIndex(null)}
	                                onClick={() => handleSetSelectedClint(u)}
                                >
	                                <td className="d-flex client-column align-items-center">
                                    <img
                                      src={u.image ? u.image : Avatar}
                                      className="user-profile-icons"
                                      alt="UserProfileIcon"
                                    />
                                    <div className="d-flex flex-column">
                                      <h2 className="user-name-title">
                                        {u.name}
                                      </h2>
	                                    {u.profileCategory && <h2 className="user-name-description">
                                        {u.profileCategory}
	                                    </h2>}
                                    </div>
		                                <div className='ml-auto table-client-column d-flex align-items-center'>
			                                {moment(u.createdAt).format("HH:mm")}
			                                <div className="client-delete-column">
                                        <span
	                                        className="p-2  mr-1 "
	                                        onClick={(e) => {
		                                        e.stopPropagation();
		                                        confirmationDialogBox(u._id)
	                                        }}
                                        >
                                          <Delete/>
                                        </span>
			                                </div>
		                                </div>
	                                </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        {client && !client.length && !isLoading && (
                          <div className="d-flex justify-content-center">
                            <h1 className="mt-4 noresult-found-title">
                              Record Not Found
                            </h1>
                          </div>
                        )}
                        {isError && (
                          <div className="d-flex justify-content-center">
                            <h1 className="mt-4 noresult-found-title">
                              Network error
                            </h1>
                          </div>
                        )}
	                      {(isLoading && !selectedClient) && (
                          <div className="p-5 d-flex justify-content-center align-items-center">
                            <ClipLoader
                              size={40}
                              color={"#4ce1b6"}
                              loading={true}
                            />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-8">
          <div className="card">
            <div className="card-body p-0">
              {isLoading ? (
                <div className="p-5 d-flex  my-auto h-100 justify-content-center align-items-center">
                  <ClipLoader size={40} color={"#4ce1b6"} loading={true} />
                </div>
              ) : (
                selectedClient && <ClientsTab client={selectedClient} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
